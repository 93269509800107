/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core"

export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
	[_ in K]?: never
}
export type Incremental<T> =
	| T
	| { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string }
	String: { input: string; output: string }
	Boolean: { input: boolean; output: boolean }
	Int: { input: number; output: number }
	Float: { input: number; output: number }
	Date: { input: Date; output: Date }
	File: { input: File; output: File }
}

export type ActivityLogForm = {
	__typename?: "ActivityLogForm"
	key: Scalars["String"]["output"]
	name: Scalars["String"]["output"]
}

export type ActivityLogUser = {
	__typename?: "ActivityLogUser"
	email: Scalars["String"]["output"]
	id: Scalars["ID"]["output"]
	name: Scalars["String"]["output"]
}

export type Agency = AgencyOrChapter &
	Document & {
		__typename?: "Agency"
		/** The chapters that belong to this agency. */
		chapters: Array<Chapter>
		/** The control number of this agency. _Unique_. */
		controlNumber: Scalars["String"]["output"]
		/** If this agency uses a non-standard section in the AAM, this is the heading that section should use (e.g., `"Rules Filed For Inclusion In The Alabama Administrative Code By Public Service Commission"`). */
		headingForNonstandardSectionInAam?: Maybe<Scalars["String"]["output"]>
		/** This agency’s ID in the database */
		id: Scalars["ID"]["output"]
		/** The calculated value based on this agency’s shown value. This is for consistency with the other models. */
		showToPublic: Scalars["Boolean"]["output"]
		/** Whether this agency should be shown on the public page. */
		shown: Scalars["Boolean"]["output"]
		/** The title that makes for more human readable sorting (where the prefix is put at the end, e.g., `"Conservation, Department of"`). */
		sortTitle?: Maybe<Scalars["String"]["output"]>
		/** The title of this agency. */
		title: Scalars["String"]["output"]
	}

export type AgencyChaptersArgs = {
	onlyShown?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type AgencyOrChapter = {
	id: Scalars["ID"]["output"]
	showToPublic: Scalars["Boolean"]["output"]
	shown: Scalars["Boolean"]["output"]
	title: Scalars["String"]["output"]
}

export type AgencyResultItem = SearchResultItem & {
	__typename?: "AgencyResultItem"
	controlNumber: Scalars["String"]["output"]
	id: Scalars["ID"]["output"]
	title: Scalars["String"]["output"]
}

export type Announcement = {
	__typename?: "Announcement"
	content: Scalars["String"]["output"]
	description: Scalars["String"]["output"]
	id: Scalars["ID"]["output"]
	title: Scalars["String"]["output"]
	updatedAt: Scalars["Date"]["output"]
}

export type Chapter = AgencyOrChapter &
	ChapterOrRule &
	ChapterOrRuleOrSnapshot &
	ChapterOrSnapshot &
	Document & {
		__typename?: "Chapter"
		/** Agency Folder name from table of Contents. */
		agencyFolder?: Maybe<Scalars["String"]["output"]>
		/**
		 * Title for the agency to use for this chapter. Can be multiple lines separated by `"
		 * "`. Everything before "Administrative Code". If left blank, this chapter’s agency’s title will be used.
		 */
		agencyTitle?: Maybe<Scalars["String"]["output"]>
		/** A special note from the editor concerning this chapter that appears at the end. */
		editorsEndnote?: Maybe<Scalars["String"]["output"]>
		/** A special note from the editor concerning this chapter. */
		editorsNote?: Maybe<Scalars["String"]["output"]>
		/** File name from table of contents (must preserve original file extension). */
		fileName?: Maybe<Scalars["String"]["output"]>
		/** The URL at which the PDF for this chapter can be accessed. */
		fileUrl: Scalars["String"]["output"]
		/** The filings that are released for this chapter */
		filings: Array<Filing>
		/** Shown on bottom left. */
		footer?: Maybe<Scalars["String"]["output"]>
		/** Shown on top left on odd pages, top right on even. */
		header?: Maybe<Scalars["String"]["output"]>
		/** This chapter’s ID in the database */
		id: Scalars["ID"]["output"]
		/** The chapter’s ID text. _Unique_. */
		idText: Scalars["String"]["output"]
		/** The agency that owns this chapter. */
		parent: Agency
		/** Whether this chapter is repealed. */
		repealed: Scalars["Boolean"]["output"]
		/** The rules associated with this chapter. */
		rules: Array<Rule>
		/** The calculated value based on this chapter’s shown value, taking into account whether its parent agency is shown. */
		showToPublic: Scalars["Boolean"]["output"]
		/** Whether this chapter specifically should be shown on the public page. It does not take into account whether its parent agency is shown. */
		shown: Scalars["Boolean"]["output"]
		/** The title to show under the chapter ID text. */
		title: Scalars["String"]["output"]
		/** The display title to display for this chapter in the administrative table of contents provided. Defaults to `"${idText} - ${title}"` */
		titleForChapterInTableOfContents?: Maybe<Scalars["String"]["output"]>
	}

export type ChapterRulesArgs = {
	onlyShown?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type ChapterDraft = ChapterDraftOrRuleDraft & {
	__typename?: "ChapterDraft"
	/** Title for the agency, including the division, to use for this chapter draft when generating a PDF. Can be multiple lines separated by `"\n"`. If left blank, this chapter draft’s agency’s title will be used on the PDF. */
	agencyTitle?: Maybe<Scalars["String"]["output"]>
	/** The ID of this chapter draft. */
	id: Scalars["ID"]["output"]
	/** This chapter draft’s ID text, like `"20-X-4"`. */
	idText?: Maybe<Scalars["String"]["output"]>
	/** Whether this chapter draft and all of its rule drafts are valid. This does **not** mean accurate, correct, or even sensical. */
	isValid: Scalars["Boolean"]["output"]
	/** Whether to override the placement in the chapter list of the agency that will own this chapter. */
	overridePlacement: Scalars["Boolean"]["output"]
	/** The existing chapter after which to place this chapter draft in the agency for which it’s being created. */
	placeAfter?: Maybe<Chapter>
	/** The ID of the existing chapter after which to place this chapter draft in the agency for which it’s being created. */
	placeAfterID?: Maybe<Scalars["ID"]["output"]>
	/** Where to place this chapter draft in the agency for which it's being created, in reference to the existing chapters. */
	placeAt?: Maybe<Placement>
	/** Whether this chapter draft is for a proposal certification. */
	proposalCertification: Scalars["Boolean"]["output"]
	/** The rule drafts associated with this chapter draft. */
	ruleDrafts: Array<RuleDraft>
	/** The submission to which this draft belongs. */
	submission: Submission
	/** This chapter draft’s title, like `"Sales by ABC Board Stores"`. */
	title?: Maybe<Scalars["String"]["output"]>
}

export type ChapterDraftOrRuleDraft = {
	id: Scalars["ID"]["output"]
	idText?: Maybe<Scalars["String"]["output"]>
	isValid: Scalars["Boolean"]["output"]
	proposalCertification: Scalars["Boolean"]["output"]
	title?: Maybe<Scalars["String"]["output"]>
}

export type ChapterDraftUpdatedLogEntry = FilingActivityLogEntry &
	WithDiffLogEntry & {
		__typename?: "ChapterDraftUpdatedLogEntry"
		addedRule?: Maybe<Scalars["String"]["output"]>
		chapterUpdated: Scalars["Boolean"]["output"]
		date: Scalars["Date"]["output"]
		deletedRule?: Maybe<Scalars["String"]["output"]>
		diff: Scalars["String"]["output"]
		filingID: Scalars["ID"]["output"]
		id: Scalars["ID"]["output"]
		reorderedRules: Array<Scalars["String"]["output"]>
		updatedRules: Array<Scalars["String"]["output"]>
		user: ActivityLogUser
		wasReset: Scalars["Boolean"]["output"]
	}

export type ChapterEditorRule = {
	author?: InputMaybe<Scalars["String"]["input"]>
	authority?: InputMaybe<Scalars["String"]["input"]>
	/** The new text content for the rule. */
	description?: InputMaybe<Scalars["String"]["input"]>
	editorsNote?: InputMaybe<Scalars["String"]["input"]>
	history?: InputMaybe<Scalars["String"]["input"]>
	/** The ID of the rule being edited in the multi-rule editor. */
	id: Scalars["ID"]["input"]
	/** The new ID text for the rule. */
	idText: Scalars["String"]["input"]
	/** Whether this rule should be displayed on a separate page. */
	pageBreakBefore?: InputMaybe<Scalars["Boolean"]["input"]>
	penalty?: InputMaybe<Scalars["String"]["input"]>
	/** Whether this rule should be shown to the public. If any of this rule’s parents are not shown to the public, this won’t actually do anything. */
	shown?: InputMaybe<Scalars["Boolean"]["input"]>
	/** The new title for the rule. If this is defined, we assume the rule is being edited. If not, this just corresponds to a rule in the rule list. */
	title?: InputMaybe<Scalars["String"]["input"]>
}

export type ChapterOrRule = {
	id: Scalars["ID"]["output"]
	idText: Scalars["String"]["output"]
	repealed: Scalars["Boolean"]["output"]
	showToPublic: Scalars["Boolean"]["output"]
	shown: Scalars["Boolean"]["output"]
	title: Scalars["String"]["output"]
}

/** Equivalent to `Chapter | Rule | ChapterSnapshot | RuleSnapshot` */
export type ChapterOrRuleOrSnapshot = {
	idText: Scalars["String"]["output"]
	repealed: Scalars["Boolean"]["output"]
	shown: Scalars["Boolean"]["output"]
	title: Scalars["String"]["output"]
}

/** Equivalent to `Chapter | ChapterSnapshot` */
export type ChapterOrSnapshot = {
	agencyTitle?: Maybe<Scalars["String"]["output"]>
	editorsEndnote?: Maybe<Scalars["String"]["output"]>
	editorsNote?: Maybe<Scalars["String"]["output"]>
	footer?: Maybe<Scalars["String"]["output"]>
	header?: Maybe<Scalars["String"]["output"]>
	idText: Scalars["String"]["output"]
	/** Whether this chapter was repealed. */
	repealed: Scalars["Boolean"]["output"]
	shown: Scalars["Boolean"]["output"]
	title: Scalars["String"]["output"]
}

export type ChapterResultItem = SearchResultItem & {
	__typename?: "ChapterResultItem"
	id: Scalars["ID"]["output"]
	idText: Scalars["String"]["output"]
	parentID: Scalars["ID"]["output"]
	title: Scalars["String"]["output"]
}

export type ChapterSnapshot = ChapterOrRuleOrSnapshot &
	ChapterOrSnapshot &
	NewSnapshotOrChapterSnapshot &
	Snapshot & {
		__typename?: "ChapterSnapshot"
		agencyTitle?: Maybe<Scalars["String"]["output"]>
		date: Scalars["Date"]["output"]
		editorsEndnote?: Maybe<Scalars["String"]["output"]>
		editorsNote?: Maybe<Scalars["String"]["output"]>
		footer?: Maybe<Scalars["String"]["output"]>
		header?: Maybe<Scalars["String"]["output"]>
		idText: Scalars["String"]["output"]
		number: Scalars["String"]["output"]
		/** Whether this chapter was repealed. */
		repealed: Scalars["Boolean"]["output"]
		rules: Array<RuleSnapshot>
		shown: Scalars["Boolean"]["output"]
		title: Scalars["String"]["output"]
	}

export type CommentData = {
	__typename?: "CommentData"
	comment: Scalars["String"]["output"]
	createdAt: Scalars["Date"]["output"]
}

export type DateReference = "now" | "publicationDate"

export type DateSpecs = {
	__typename?: "DateSpecs"
	default?: Maybe<Scalars["Int"]["output"]>
	from?: Maybe<DateReference>
	max?: Maybe<Scalars["Int"]["output"]>
	min?: Maybe<Scalars["Int"]["output"]>
}

/** The shared properties between Agency, Chapter, and Rule */
export type Document = {
	id: Scalars["ID"]["output"]
	showToPublic: Scalars["Boolean"]["output"]
	shown: Scalars["Boolean"]["output"]
	title: Scalars["String"]["output"]
}

export type Field = {
	__typename?: "Field"
	/** The condition under which this field should be displayed. A string, `"<field-key>/<answer>"`, where `<field-key>` is the key of the other field in this same form and `<answer>` is the answer for which this field should appear. */
	condition?: Maybe<Scalars["String"]["output"]>
	dateSpecs?: Maybe<DateSpecs>
	fileSpecs?: Maybe<FileSpecs>
	helperText?: Maybe<Scalars["String"]["output"]>
	key: Scalars["String"]["output"]
	/** Whether this field is an optional field. */
	optional?: Maybe<Scalars["Boolean"]["output"]>
	options?: Maybe<Array<FieldOption>>
	text: Scalars["String"]["output"]
	textSpecs?: Maybe<TextSpecs>
	type: FieldType
}

export type FieldOption = {
	__typename?: "FieldOption"
	text: Scalars["String"]["output"]
	value: Scalars["String"]["output"]
}

export type FieldType =
	| "date"
	| "datetime"
	| "dropdown"
	| "file"
	| "multiline"
	| "number"
	| "text"

export type FileMetadata = {
	__typename?: "FileMetadata"
	_id: Scalars["ID"]["output"]
	/** This file’s content type, like `"application/pdf"` */
	contentType: Scalars["String"]["output"]
	/** The filename for this file, as stored in the DB. */
	filename: Scalars["String"]["output"]
	/** This file’s md5 hash, for equality comparisons. */
	md5: Scalars["String"]["output"]
}

export type FileSpecs = {
	__typename?: "FileSpecs"
	type?: Maybe<Scalars["String"]["output"]>
}

export type Filing = {
	__typename?: "Filing"
	activityLog: Array<FilingActivityLogEntry>
	agency: Agency
	agencyID: Scalars["ID"]["output"]
	certification: Submission
	currentCode?: Maybe<ChapterOrRule>
	currentCodeNumber?: Maybe<Scalars["String"]["output"]>
	currentSubmission: Submission
	effectiveDate?: Maybe<Scalars["Date"]["output"]>
	filingType: FilingType
	filingTypeKey: Scalars["String"]["output"]
	id: Scalars["ID"]["output"]
	intendedAction?: Maybe<IntendedAction>
	isEffective: Scalars["Boolean"]["output"]
	latestCertification: Submission
	name?: Maybe<Scalars["String"]["output"]>
	proposal: Submission
	recertifications: Array<Submission>
	scope?: Maybe<FilingScope>
	status: FilingStatus
}

/** The interface for all activity log entries—essentially a union of them all. */
export type FilingActivityLogEntry = {
	date: Scalars["Date"]["output"]
	filingID: Scalars["ID"]["output"]
	id: Scalars["ID"]["output"]
	user: ActivityLogUser
}

export type FilingApprovedLogEntry = FilingActivityLogEntry &
	WithFileLogEntry & {
		__typename?: "FilingApprovedLogEntry"
		date: Scalars["Date"]["output"]
		fileID: Scalars["ID"]["output"]
		fileUrl: Scalars["String"]["output"]
		filingID: Scalars["ID"]["output"]
		id: Scalars["ID"]["output"]
		user: ActivityLogUser
	}

export type FilingCreatedLogEntry = FilingActivityLogEntry & {
	__typename?: "FilingCreatedLogEntry"
	date: Scalars["Date"]["output"]
	filingID: Scalars["ID"]["output"]
	id: Scalars["ID"]["output"]
	user: ActivityLogUser
}

export type FilingDisapprovedLogEntry = FilingActivityLogEntry &
	WithFileLogEntry & {
		__typename?: "FilingDisapprovedLogEntry"
		date: Scalars["Date"]["output"]
		fileID: Scalars["ID"]["output"]
		fileUrl: Scalars["String"]["output"]
		filingID: Scalars["ID"]["output"]
		id: Scalars["ID"]["output"]
		user: ActivityLogUser
	}

export type FilingDisapprovedWithSuggestionsLogEntry = FilingActivityLogEntry &
	WithFileLogEntry & {
		__typename?: "FilingDisapprovedWithSuggestionsLogEntry"
		date: Scalars["Date"]["output"]
		fileID: Scalars["ID"]["output"]
		fileUrl: Scalars["String"]["output"]
		filingID: Scalars["ID"]["output"]
		id: Scalars["ID"]["output"]
		user: ActivityLogUser
	}

export type FilingRecalledLogEntry = FilingActivityLogEntry &
	WithFileLogEntry & {
		__typename?: "FilingRecalledLogEntry"
		date: Scalars["Date"]["output"]
		fileID: Scalars["ID"]["output"]
		fileUrl: Scalars["String"]["output"]
		filingID: Scalars["ID"]["output"]
		id: Scalars["ID"]["output"]
		user: ActivityLogUser
	}

export type FilingRejectedLogEntry = FilingActivityLogEntry &
	WithFileLogEntry & {
		__typename?: "FilingRejectedLogEntry"
		comments: Scalars["String"]["output"]
		date: Scalars["Date"]["output"]
		fileID: Scalars["ID"]["output"]
		fileUrl: Scalars["String"]["output"]
		filingID: Scalars["ID"]["output"]
		id: Scalars["ID"]["output"]
		user: ActivityLogUser
	}

export type FilingResultItem = SearchFilingsResultItem & {
	__typename?: "FilingResultItem"
	content: Scalars["String"]["output"]
	/** If this was disapproved, the date it was disapproved. */
	disapproveDate?: Maybe<Scalars["Date"]["output"]>
	/** If this was disapproved with suggested amendments, the date it was disapproved with suggested amendments. */
	disapproveWithSuggestionsDate?: Maybe<Scalars["Date"]["output"]>
	fileUrl: Scalars["String"]["output"]
	/** The filing type key for the matched filing */
	filingType: Scalars["String"]["output"]
	id: Scalars["ID"]["output"]
	/** Whether the filing result item corresponds to the proposal of the filing. Basically, if this is false, it’s a certification. */
	isProposal: Scalars["Boolean"]["output"]
	publicationDate: Scalars["Date"]["output"]
	publicationID: Scalars["String"]["output"]
	title: Scalars["String"]["output"]
	/** If this was withdrawn, the date it was withdrawn. */
	withdrawDate?: Maybe<Scalars["Date"]["output"]>
}

export type FilingScope = "Chapter" | "Rule"

/** By published in the statuses, we are referring to published in the AAM, not published elsewhere. The filing may be published elsewhere—even in another view in AACMS. */
export type FilingStatus =
	| "certificationApproved"
	| "certificationEffectiveNotPublished"
	| "certificationExpired"
	| "certificationPendingApproval"
	| "certificationPublishedAndEffective"
	| "certificationPublishedNotEffective"
	| "draft"
	| "proposalApproved"
	| "proposalAwaitingComments"
	| "proposalCertificationDraft"
	| "proposalPendingApproval"

export type FilingSubmittedLogEntry = FilingActivityLogEntry &
	WithFileLogEntry & {
		__typename?: "FilingSubmittedLogEntry"
		date: Scalars["Date"]["output"]
		fileID: Scalars["ID"]["output"]
		fileUrl: Scalars["String"]["output"]
		filingID: Scalars["ID"]["output"]
		id: Scalars["ID"]["output"]
		user: ActivityLogUser
	}

export type FilingType = {
	__typename?: "FilingType"
	/** Whether this filing, after certification and before it becomes effective, can be withdrawn by the agency or disapproved by committee. */
	canBeWithdrawnOrDisapproved?: Maybe<Scalars["Boolean"]["output"]>
	/** If the date that this filing becomes effective is read from a form entry value, the form and question that contains that date. */
	effectiveBasedOnFormEntry?: Maybe<Scalars["String"]["output"]>
	/** If this filing becomes effective a certain number of days after publication, how many days after. */
	effectiveDaysAfterPublication?: Maybe<Scalars["Int"]["output"]>
	/** Whether this filing is effective immediately when approved */
	effectiveWhenApproved?: Maybe<Scalars["Boolean"]["output"]>
	/** Whether this filing becomes effective immediately when published */
	effectiveWhenPublished?: Maybe<Scalars["Boolean"]["output"]>
	/** Whether this filing goes through the proposal statuses after draft */
	hasProposal?: Maybe<Scalars["Boolean"]["output"]>
	id: Scalars["ID"]["output"]
	key: Scalars["String"]["output"]
	name: Scalars["String"]["output"]
	/** Whether this filing type requires a scope and intended action. */
	requiresScopeAndIntendedAction?: Maybe<Scalars["Boolean"]["output"]>
	/** Whether this filing merges into the current code when effective. */
	shouldMergeIntoCurrentCode?: Maybe<Scalars["Boolean"]["output"]>
}

export type FilingUserAction =
	| "approve"
	| "disapprove"
	| "disapproveWithSuggestedAmendments"
	| "recall"
	| "reject"
	| "submit"
	| "withdraw"

export type FilingWithdrawnLogEntry = FilingActivityLogEntry &
	WithFileLogEntry & {
		__typename?: "FilingWithdrawnLogEntry"
		date: Scalars["Date"]["output"]
		fileID: Scalars["ID"]["output"]
		fileUrl: Scalars["String"]["output"]
		filingID: Scalars["ID"]["output"]
		id: Scalars["ID"]["output"]
		user: ActivityLogUser
	}

export type Form = {
	__typename?: "Form"
	/** The condition under which this form will be required, in the form of `other-form-key/question-key/answer-value`. */
	condition?: Maybe<Scalars["String"]["output"]>
	/** Whether this form depends on the strikethrough (and should have the strikethrough amended to it). */
	dependsOnStrikethrough: Scalars["Boolean"]["output"]
	/** Whether this form conditionally depends on the strikethrough (and should have the strikethrough amended to it if the condition is met). The condition is in the form of `question-key/answer` */
	dependsOnStrikethroughCondition?: Maybe<Scalars["String"]["output"]>
	description?: Maybe<Scalars["String"]["output"]>
	effectiveDate: Scalars["Date"]["output"]
	fields: Array<Field>
	id: Scalars["ID"]["output"]
	key: Scalars["String"]["output"]
	name: Scalars["String"]["output"]
	/** Whether the signature should be removed when the draft (or by extension, the strikethrough) changes. This also includes if the current code changes, invalidating the approval. */
	removeSignatureOnDraftChange: Scalars["Boolean"]["output"]
	/** Whether this form requires a signature to be considered complete. If `true`, this will be shown in the `sign forms` page on the UI. */
	signatureRequired: Scalars["Boolean"]["output"]
}

export type FormChangedLogEntry = {
	date: Scalars["Date"]["output"]
	fileID: Scalars["ID"]["output"]
	fileUrl: Scalars["String"]["output"]
	filingID: Scalars["ID"]["output"]
	form: ActivityLogForm
	id: Scalars["ID"]["output"]
	user: ActivityLogUser
}

export type FormEntry = {
	__typename?: "FormEntry"
	fileUrl: Scalars["String"]["output"]
	filing: Filing
	filingID: Scalars["ID"]["output"]
	form: Form
	formKey: Scalars["String"]["output"]
	id: Scalars["ID"]["output"]
	isValid: Scalars["Boolean"]["output"]
	signature?: Maybe<Signature>
	signatureID?: Maybe<Scalars["ID"]["output"]>
	updatedAt: Scalars["Date"]["output"]
	values: Array<FormEntryValue>
}

export type FormEntryValue = {
	__typename?: "FormEntryValue"
	/** The value if it is a date. Other value properties will be null */
	date?: Maybe<Scalars["Date"]["output"]>
	/** The value if it is a file. Other value properties will be null */
	file?: Maybe<FileMetadata>
	/** The key of the question to which this value corresponds. */
	key: Scalars["String"]["output"]
	/** The value if it is a number. Other value properties will be null */
	num?: Maybe<Scalars["Float"]["output"]>
	/** The value if it is a string. Other value properties will be null */
	text?: Maybe<Scalars["String"]["output"]>
}

export type FormEntryValueInput = {
	date?: InputMaybe<Scalars["Date"]["input"]>
	file?: InputMaybe<Scalars["File"]["input"]>
	key: Scalars["String"]["input"]
	num?: InputMaybe<Scalars["Float"]["input"]>
	text?: InputMaybe<Scalars["String"]["input"]>
}

export type FormSignedLogEntry = FilingActivityLogEntry &
	FormChangedLogEntry &
	WithFileLogEntry & {
		__typename?: "FormSignedLogEntry"
		date: Scalars["Date"]["output"]
		fileID: Scalars["ID"]["output"]
		fileUrl: Scalars["String"]["output"]
		filingID: Scalars["ID"]["output"]
		form: ActivityLogForm
		id: Scalars["ID"]["output"]
		user: ActivityLogUser
	}

export type FormUpdatedLogEntry = FilingActivityLogEntry &
	FormChangedLogEntry &
	WithFileLogEntry & {
		__typename?: "FormUpdatedLogEntry"
		date: Scalars["Date"]["output"]
		fileID: Scalars["ID"]["output"]
		fileUrl: Scalars["String"]["output"]
		filingID: Scalars["ID"]["output"]
		form: ActivityLogForm
		id: Scalars["ID"]["output"]
		user: ActivityLogUser
		wasNew: Scalars["Boolean"]["output"]
	}

export type HistoricalFiling = {
	__typename?: "HistoricalFiling"
	content?: Maybe<Scalars["String"]["output"]>
	filepath: Scalars["String"]["output"]
	publicationDate: Scalars["Date"]["output"]
	publicationID: Scalars["String"]["output"]
	publicationType: PublicationType
	title: Scalars["String"]["output"]
}

export type HistoricalFilingResultItem = SearchFilingsResultItem & {
	__typename?: "HistoricalFilingResultItem"
	content: Scalars["String"]["output"]
	fileUrl: Scalars["String"]["output"]
	id: Scalars["ID"]["output"]
	publicationDate: Scalars["Date"]["output"]
	publicationID: Scalars["String"]["output"]
	publicationType: PublicationType
	title: Scalars["String"]["output"]
}

export type HistoricalPublication = PublicationOrHistoricalPublication & {
	__typename?: "HistoricalPublication"
	aam?: Maybe<HistoricalPublicationContent>
	contents?: Maybe<HistoricalPublicationContent>
	emergency?: Maybe<HistoricalPublicationContent>
	id: Scalars["String"]["output"]
	proposal?: Maybe<HistoricalPublicationContent>
	publicationDate: Scalars["Date"]["output"]
}

export type HistoricalPublicationContentsArgs = {
	type: PublicationType
}

export type HistoricalPublicationContent = {
	__typename?: "HistoricalPublicationContent"
	content: Scalars["String"]["output"]
	filepath: Scalars["String"]["output"]
}

export type IntendedAction = "amend" | "new" | "repeal" | "repealAndReplace"

export type Mutation = {
	__typename?: "Mutation"
	addAgency: Agency
	addAnnouncement: Announcement
	addChapter: Chapter
	addFiling: Filing
	addResource: Resource
	/** Create a new rule with the passed title, idText, and other data. */
	addRule: Rule
	/** Create a new rule draft with the passed title, idText, and other data. */
	addRuleDraft: RuleDraft
	approveFiling: Filing
	approveStrikethrough: Filing
	/** Approve a user for the specified agencies. Admin use only. */
	approveUser: User
	/** Cancel a request for an agency to be added to the currently logged-in user’s account. */
	cancelAgencyRequest: User
	/** Remove the passed agency ID from the passed user. Admin use only. */
	disapproveUser: User
	/** Login to your account, for users who have already been approved to manage one or more agencies. */
	login: User
	/** Logout the currently logged in user */
	logout: Scalars["Boolean"]["output"]
	overrideStrikethroughForFiling: Filing
	permanentlyDeleteAnnouncement: Announcement
	permanentlyDeleteChapter: Chapter
	permanentlyDeleteFiling: Filing
	permanentlyDeleteResource: Resource
	permanentlyDeleteRule: Rule
	/** Permanently delete a rule draft. This is used in chapter drafts when deleting a rule draft from the rules array. */
	permanentlyDeleteRuleDraft: RuleDraft
	/** Permanently delete the user account with the matching ID. Admin use only. */
	permanentlyDeleteUser: User
	recallFiling: Filing
	rejectFiling: Filing
	/** Request a user account. */
	requestAccount: User
	/** Request an agency to be added to the currently logged-in user’s account. */
	requestAgency: User
	/** Reset a chapter draft to the state of the currently published chapter. */
	resetChapterDraft: ChapterDraft
	/** Reset the password for the user with the corresponding token. */
	resetPassword: Scalars["Boolean"]["output"]
	/** Reset a rule draft to the state of the currently published rule. */
	resetRuleDraft: RuleDraft
	/** Send a password reset link for the user with the passed email, if such a user exists. */
	sendPasswordResetLink: Scalars["Boolean"]["output"]
	setPublicationApproved: Publication
	signFormEntry: FormEntry
	sortAnnouncements: Scalars["Boolean"]["output"]
	/** Sort the resources in the DB in the order they are found in the list of IDs. */
	sortResources: Scalars["Boolean"]["output"]
	submitFiling: Filing
	updateAgency: Agency
	updateAnnouncement: Announcement
	updateChapter: Chapter
	/** Update a chapter draft with the passed information. */
	updateChapterDraft: ChapterDraft
	updatePublicationEditorsNote: Publication
	updateResource: Resource
	updateRule: Rule
	/** Update a rule draft with the passed title, idText, and other data. */
	updateRuleDraft: RuleDraft
	/** Update props of the user with the passed ID. */
	updateUser: User
	upsertFormEntry: FormEntry
	withdrawOrDisapproveFiling: Filing
}

export type MutationAddAgencyArgs = {
	controlNumber: Scalars["String"]["input"]
	shown?: InputMaybe<Scalars["Boolean"]["input"]>
	sortTitle?: InputMaybe<Scalars["String"]["input"]>
	title: Scalars["String"]["input"]
}

export type MutationAddAnnouncementArgs = {
	content: Scalars["String"]["input"]
	description: Scalars["String"]["input"]
	title: Scalars["String"]["input"]
}

export type MutationAddChapterArgs = {
	agencyFolder?: InputMaybe<Scalars["String"]["input"]>
	agencyTitle?: InputMaybe<Scalars["String"]["input"]>
	editorsEndnote?: InputMaybe<Scalars["String"]["input"]>
	editorsNote?: InputMaybe<Scalars["String"]["input"]>
	fileName?: InputMaybe<Scalars["String"]["input"]>
	footer?: InputMaybe<Scalars["String"]["input"]>
	header?: InputMaybe<Scalars["String"]["input"]>
	idText: Scalars["String"]["input"]
	parentID: Scalars["ID"]["input"]
	shown?: InputMaybe<Scalars["Boolean"]["input"]>
	title: Scalars["String"]["input"]
	titleForChapterInTableOfContents?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationAddFilingArgs = {
	agencyID: Scalars["ID"]["input"]
	currentCodeNumber?: InputMaybe<Scalars["String"]["input"]>
	filingTypeKey: Scalars["String"]["input"]
	intendedAction?: InputMaybe<IntendedAction>
	name?: InputMaybe<Scalars["String"]["input"]>
	scope?: InputMaybe<FilingScope>
}

export type MutationAddResourceArgs = {
	file: Scalars["File"]["input"]
	title: Scalars["String"]["input"]
}

export type MutationAddRuleArgs = {
	author?: InputMaybe<Scalars["String"]["input"]>
	authority?: InputMaybe<Scalars["String"]["input"]>
	description?: InputMaybe<Scalars["String"]["input"]>
	editorsNote?: InputMaybe<Scalars["String"]["input"]>
	history?: InputMaybe<Scalars["String"]["input"]>
	idText: Scalars["String"]["input"]
	pageBreakBefore?: InputMaybe<Scalars["Boolean"]["input"]>
	parentID: Scalars["ID"]["input"]
	penalty?: InputMaybe<Scalars["String"]["input"]>
	shown?: InputMaybe<Scalars["Boolean"]["input"]>
	title: Scalars["String"]["input"]
}

export type MutationAddRuleDraftArgs = {
	idText?: InputMaybe<Scalars["String"]["input"]>
	parentID: Scalars["ID"]["input"]
	title?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationApproveFilingArgs = {
	id: Scalars["ID"]["input"]
}

export type MutationApproveStrikethroughArgs = {
	id: Scalars["ID"]["input"]
	viewed: Scalars["Boolean"]["input"]
}

export type MutationApproveUserArgs = {
	agencyIDs: Array<Scalars["ID"]["input"]>
	id: Scalars["ID"]["input"]
}

export type MutationCancelAgencyRequestArgs = {
	agency: Scalars["ID"]["input"]
}

export type MutationDisapproveUserArgs = {
	agencyIDs: Array<Scalars["ID"]["input"]>
	id: Scalars["ID"]["input"]
}

export type MutationLoginArgs = {
	email: Scalars["String"]["input"]
	password: Scalars["String"]["input"]
}

export type MutationOverrideStrikethroughForFilingArgs = {
	id: Scalars["ID"]["input"]
	override?: InputMaybe<Scalars["File"]["input"]>
}

export type MutationPermanentlyDeleteAnnouncementArgs = {
	id: Scalars["ID"]["input"]
}

export type MutationPermanentlyDeleteChapterArgs = {
	id: Scalars["ID"]["input"]
}

export type MutationPermanentlyDeleteFilingArgs = {
	id: Scalars["ID"]["input"]
}

export type MutationPermanentlyDeleteResourceArgs = {
	id: Scalars["ID"]["input"]
}

export type MutationPermanentlyDeleteRuleArgs = {
	id: Scalars["ID"]["input"]
}

export type MutationPermanentlyDeleteRuleDraftArgs = {
	id: Scalars["ID"]["input"]
}

export type MutationPermanentlyDeleteUserArgs = {
	id: Scalars["ID"]["input"]
}

export type MutationRecallFilingArgs = {
	id: Scalars["ID"]["input"]
}

export type MutationRejectFilingArgs = {
	comment: Scalars["String"]["input"]
	id: Scalars["ID"]["input"]
}

export type MutationRequestAccountArgs = {
	agencyControlNumbers: Array<Scalars["String"]["input"]>
	email: Scalars["String"]["input"]
	name: Scalars["String"]["input"]
	password: Scalars["String"]["input"]
}

export type MutationRequestAgencyArgs = {
	agency: Scalars["ID"]["input"]
}

export type MutationResetChapterDraftArgs = {
	id: Scalars["ID"]["input"]
}

export type MutationResetPasswordArgs = {
	password: Scalars["String"]["input"]
	token: Scalars["String"]["input"]
}

export type MutationResetRuleDraftArgs = {
	id: Scalars["ID"]["input"]
}

export type MutationSendPasswordResetLinkArgs = {
	email: Scalars["String"]["input"]
}

export type MutationSetPublicationApprovedArgs = {
	approved: Scalars["Boolean"]["input"]
	id: Scalars["String"]["input"]
}

export type MutationSignFormEntryArgs = {
	filingID: Scalars["ID"]["input"]
	formKey: Scalars["String"]["input"]
	screenshot: Scalars["File"]["input"]
	value: Scalars["String"]["input"]
}

export type MutationSortAnnouncementsArgs = {
	announcements: Array<Scalars["ID"]["input"]>
}

export type MutationSortResourcesArgs = {
	resources: Array<Scalars["ID"]["input"]>
}

export type MutationSubmitFilingArgs = {
	id: Scalars["ID"]["input"]
}

export type MutationUpdateAgencyArgs = {
	chapters?: InputMaybe<Array<Scalars["ID"]["input"]>>
	id: Scalars["ID"]["input"]
	shown?: InputMaybe<Scalars["Boolean"]["input"]>
	sortTitle?: InputMaybe<Scalars["String"]["input"]>
	title?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationUpdateAnnouncementArgs = {
	content?: InputMaybe<Scalars["String"]["input"]>
	description?: InputMaybe<Scalars["String"]["input"]>
	id: Scalars["ID"]["input"]
	title?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationUpdateChapterArgs = {
	agencyTitle?: InputMaybe<Scalars["String"]["input"]>
	editorsEndnote?: InputMaybe<Scalars["String"]["input"]>
	editorsNote?: InputMaybe<Scalars["String"]["input"]>
	footer?: InputMaybe<Scalars["String"]["input"]>
	header?: InputMaybe<Scalars["String"]["input"]>
	id: Scalars["ID"]["input"]
	idText?: InputMaybe<Scalars["String"]["input"]>
	rules?: InputMaybe<Array<ChapterEditorRule>>
	shown?: InputMaybe<Scalars["Boolean"]["input"]>
	title?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationUpdateChapterDraftArgs = {
	agencyTitle?: InputMaybe<Scalars["String"]["input"]>
	id: Scalars["ID"]["input"]
	idText?: InputMaybe<Scalars["String"]["input"]>
	overridePlacement?: InputMaybe<Scalars["Boolean"]["input"]>
	placeAfterID?: InputMaybe<Scalars["ID"]["input"]>
	placeAt?: InputMaybe<Placement>
	ruleDrafts?: InputMaybe<Array<Scalars["ID"]["input"]>>
	ruleDraftsToSave?: InputMaybe<Array<RuleDraftToSave>>
	title?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationUpdatePublicationEditorsNoteArgs = {
	editorsNote: Scalars["String"]["input"]
	id: Scalars["String"]["input"]
}

export type MutationUpdateResourceArgs = {
	file?: InputMaybe<Scalars["File"]["input"]>
	id: Scalars["ID"]["input"]
	title?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationUpdateRuleArgs = {
	author?: InputMaybe<Scalars["String"]["input"]>
	authority?: InputMaybe<Scalars["String"]["input"]>
	description?: InputMaybe<Scalars["String"]["input"]>
	editorsNote?: InputMaybe<Scalars["String"]["input"]>
	history?: InputMaybe<Scalars["String"]["input"]>
	id: Scalars["ID"]["input"]
	idText?: InputMaybe<Scalars["String"]["input"]>
	pageBreakBefore?: InputMaybe<Scalars["Boolean"]["input"]>
	penalty?: InputMaybe<Scalars["String"]["input"]>
	shown?: InputMaybe<Scalars["Boolean"]["input"]>
	title?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationUpdateRuleDraftArgs = {
	author?: InputMaybe<Scalars["String"]["input"]>
	authority?: InputMaybe<Scalars["String"]["input"]>
	description?: InputMaybe<Scalars["String"]["input"]>
	id: Scalars["ID"]["input"]
	idText?: InputMaybe<Scalars["String"]["input"]>
	manualHistoryUpdate?: InputMaybe<Scalars["String"]["input"]>
	pageBreakBefore?: InputMaybe<Scalars["Boolean"]["input"]>
	penalty?: InputMaybe<Scalars["String"]["input"]>
	title?: InputMaybe<Scalars["String"]["input"]>
	useManualHistoryUpdate?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationUpdateUserArgs = {
	agencyIDs?: InputMaybe<Array<Scalars["String"]["input"]>>
	email?: InputMaybe<Scalars["String"]["input"]>
	id: Scalars["ID"]["input"]
	isActive?: InputMaybe<Scalars["Boolean"]["input"]>
	isAdmin?: InputMaybe<Scalars["Boolean"]["input"]>
	name?: InputMaybe<Scalars["String"]["input"]>
	pendingAgencyIDs?: InputMaybe<Array<Scalars["String"]["input"]>>
	subscribedToEmails?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type MutationUpsertFormEntryArgs = {
	filingID: Scalars["ID"]["input"]
	formKey: Scalars["String"]["input"]
	values: Array<FormEntryValueInput>
}

export type MutationWithdrawOrDisapproveFilingArgs = {
	action?: InputMaybe<FilingUserAction>
	id: Scalars["ID"]["input"]
}

export type NewSnapshot = NewSnapshotOrChapterSnapshot &
	NewSnapshotOrRuleSnapshot &
	Snapshot & {
		__typename?: "NewSnapshot"
		date: Scalars["Date"]["output"]
		number: Scalars["String"]["output"]
	}

export type NewSnapshotOrChapterSnapshot = {
	date: Scalars["Date"]["output"]
	number: Scalars["String"]["output"]
}

export type NewSnapshotOrRuleSnapshot = {
	date: Scalars["Date"]["output"]
	number: Scalars["String"]["output"]
}

export type PipelineStage = "certification" | "proposal"

export type Placement = "beginning" | "end"

export type Publication = PublicationOrHistoricalPublication & {
	__typename?: "Publication"
	approved: Scalars["Boolean"]["output"]
	editorsNote?: Maybe<Scalars["String"]["output"]>
	filings: Array<Filing>
	id: Scalars["String"]["output"]
	publicationDate: Scalars["Date"]["output"]
	resubmissionDeadline: Scalars["Date"]["output"]
	submissionDeadline: Scalars["Date"]["output"]
	title: Scalars["String"]["output"]
}

export type PublicationFilingsArgs = {
	type: PublicationType
}

/** The shared props in publications and historical publications so we can query them directly */
export type PublicationOrHistoricalPublication = {
	id: Scalars["String"]["output"]
	publicationDate: Scalars["Date"]["output"]
}

export type PublicationType = "aam" | "emergency" | "proposal"

export type Query = {
	__typename?: "Query"
	agencies: Array<Agency>
	agency: Agency
	announcement: Announcement
	announcements: Array<Announcement>
	document: Document
	filing: Filing
	filingActivityLogEntry: FilingActivityLogEntry
	filingTypes: Array<FilingType>
	filings: Array<Filing>
	historicalFiling: HistoricalFiling
	historicalFilings: Array<HistoricalFiling>
	historicalPublication: HistoricalPublication
	historicalPublications: Array<HistoricalPublication>
	publication: Publication
	publicationArchives: Array<PublicationOrHistoricalPublication>
	publicationOrHistoricalPublication?: Maybe<PublicationOrHistoricalPublication>
	publications: Array<Publication>
	resources: Array<Resource>
	search: SearchResults
	searchFilings: SearchFilingsResults
	/** Get the currently logged in user if no options are passed, or get a user account by ID or email. Only an admin can get the account for a different user. */
	user: User
	/** Get the login email for the user with the passed token, if the token is valid. */
	userEmailForToken?: Maybe<Scalars["String"]["output"]>
	/** Get all users. Admin use only. */
	users: Array<User>
}

export type QueryAgenciesArgs = {
	onlyShown?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type QueryAgencyArgs = {
	controlNumber?: InputMaybe<Scalars["String"]["input"]>
	id?: InputMaybe<Scalars["ID"]["input"]>
	onlyShown?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type QueryAnnouncementArgs = {
	id?: InputMaybe<Scalars["ID"]["input"]>
	title?: InputMaybe<Scalars["String"]["input"]>
}

export type QueryDocumentArgs = {
	controlNumberOrIdText: Scalars["String"]["input"]
	onlyShown?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type QueryFilingArgs = {
	id: Scalars["ID"]["input"]
}

export type QueryFilingActivityLogEntryArgs = {
	id: Scalars["ID"]["input"]
}

export type QueryFilingsArgs = {
	agencyID?: InputMaybe<Scalars["ID"]["input"]>
	filingTypeKey?: InputMaybe<Scalars["String"]["input"]>
	status?: InputMaybe<Array<FilingStatus>>
}

export type QueryHistoricalFilingArgs = {
	id: Scalars["ID"]["input"]
}

export type QueryHistoricalPublicationArgs = {
	id: Scalars["String"]["input"]
}

export type QueryPublicationArgs = {
	currentPublication?: InputMaybe<Scalars["Boolean"]["input"]>
	id?: InputMaybe<Scalars["ID"]["input"]>
	upcomingPublication?: InputMaybe<Scalars["Boolean"]["input"]>
	upcomingSubmission?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type QueryPublicationArchivesArgs = {
	type: PublicationType
}

export type QueryPublicationOrHistoricalPublicationArgs = {
	id: Scalars["ID"]["input"]
	type: PublicationType
}

export type QueryPublicationsArgs = {
	year?: InputMaybe<Scalars["Int"]["input"]>
}

export type QuerySearchArgs = {
	agencyControlNumbers?: InputMaybe<Array<Scalars["String"]["input"]>>
	chapterIdTexts?: InputMaybe<Array<Scalars["String"]["input"]>>
	limit?: InputMaybe<Scalars["Int"]["input"]>
	query: Scalars["String"]["input"]
	skip?: InputMaybe<Scalars["Int"]["input"]>
}

export type QuerySearchFilingsArgs = {
	controlNumbers?: InputMaybe<Array<Scalars["String"]["input"]>>
	limit?: InputMaybe<Scalars["Int"]["input"]>
	query: Scalars["String"]["input"]
	skip?: InputMaybe<Scalars["Int"]["input"]>
	years?: InputMaybe<Array<Scalars["Int"]["input"]>>
}

export type QueryUserArgs = {
	email?: InputMaybe<Scalars["String"]["input"]>
	id?: InputMaybe<Scalars["ID"]["input"]>
}

export type QueryUserEmailForTokenArgs = {
	token: Scalars["String"]["input"]
}

export type RequiredFormsList = {
	__typename?: "RequiredFormsList"
	/** The date that this list became effective. The legislature can change the requirements at any time, and it shouldn't affect submitted filings. */
	effectiveDate?: Maybe<Scalars["Date"]["output"]>
	/** The filing type for which this list of required forms applies. */
	filingTypeKey?: Maybe<Scalars["String"]["output"]>
	/** The form keys that this list contains. */
	formKeys?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>
	/** The stage of the pipeline for which this list is, proposal or certification. */
	pipelineStage?: Maybe<PipelineStage>
}

export type Resource = {
	__typename?: "Resource"
	file?: Maybe<FileMetadata>
	fileID: Scalars["String"]["output"]
	fileUrl: Scalars["String"]["output"]
	id: Scalars["ID"]["output"]
	title: Scalars["String"]["output"]
}

export type Rule = ChapterOrRule &
	ChapterOrRuleOrSnapshot &
	Document & {
		__typename?: "Rule"
		/** The author or authors of this rule. */
		author?: Maybe<Scalars["String"]["output"]>
		/** The authority of this rule, like the section number. */
		authority?: Maybe<Scalars["String"]["output"]>
		/** This rule’s text content. */
		description?: Maybe<Scalars["String"]["output"]>
		/** The editor’s note for this rule, if the admin team has added any. */
		editorsNote?: Maybe<Scalars["String"]["output"]>
		/** The URL at which the PDF for this rule can be accessed. */
		fileUrl: Scalars["String"]["output"]
		/** The released filings associated with this rule */
		filings: Array<Filing>
		/** The history notes about this rule. Bolding happens automatically. */
		history?: Maybe<Scalars["String"]["output"]>
		/** This rule’s ID in the database */
		id: Scalars["ID"]["output"]
		/** This rule’s ID text (like `"20-X-4-.01"`). _Unique_. */
		idText: Scalars["String"]["output"]
		/** Whether this rule specifically should be shown on separate pages from other rules. */
		pageBreakBefore: Scalars["Boolean"]["output"]
		/** The chapter that owns this rule. */
		parent: Chapter
		/** The penalty note for this rule, if any. */
		penalty?: Maybe<Scalars["String"]["output"]>
		/** Whether this rule is repealed. */
		repealed: Scalars["Boolean"]["output"]
		/** The value based on this rules’s shown value, taking into account whether its parent chapter and agency are shown. */
		showToPublic: Scalars["Boolean"]["output"]
		/** Whether this rule specifically should be shown on the public page. It does not take into account whether its parent chapter or agency is shown. */
		shown: Scalars["Boolean"]["output"]
		/** This rule’s title, like `"Definitions"`. */
		title: Scalars["String"]["output"]
	}

export type RuleDraft = ChapterDraftOrRuleDraft & {
	__typename?: "RuleDraft"
	/** The author or authors of this rule draft. */
	author?: Maybe<Scalars["String"]["output"]>
	/** The authority of this rule draft, like the section number. */
	authority?: Maybe<Scalars["String"]["output"]>
	/** This rule draft’s text content. */
	description?: Maybe<Scalars["String"]["output"]>
	/** The history note of the rule this draft replaces/amends, if any. */
	existingHistory?: Maybe<Scalars["String"]["output"]>
	/** What the generated history update would be for this rule draft. */
	generatedHistoryUpdate: Scalars["String"]["output"]
	/** The generated history note for this rule draft */
	history: Scalars["String"]["output"]
	/** The history update for this rule draft. If `useManualHistoryUpdate` is `true`, this is the `manualHistoryUpdate` property, otherwise it is the `generatedHistoryUpdate` property. */
	historyUpdate?: Maybe<Scalars["String"]["output"]>
	/** The ID of this rule draft. */
	id: Scalars["ID"]["output"]
	/** This rule draft’s ID text, like `"20-X-4-.01"`. */
	idText?: Maybe<Scalars["String"]["output"]>
	/** Whether this rule draft is valid. This does **not** mean accurate, correct, or even sensical. */
	isValid: Scalars["Boolean"]["output"]
	/** The manual addition to the history notes for the rule this draft creates/amends/repeals. Bolding happens automatically. */
	manualHistoryUpdate?: Maybe<Scalars["String"]["output"]>
	/** Whether this rule is a new rule, or corresponds to a rule that already exists. */
	newRule: Scalars["Boolean"]["output"]
	/** Whether the rule appears on a new page. */
	pageBreakBefore: Scalars["Boolean"]["output"]
	/** The parent of this rule draft, if it is part of a chapter filing. */
	parent?: Maybe<ChapterDraft>
	/** The penalty note for this rule draft, if any. */
	penalty?: Maybe<Scalars["String"]["output"]>
	/** Whether this rule draft is for a proposal certification. */
	proposalCertification: Scalars["Boolean"]["output"]
	/** The submission that this filing belongs to */
	submission: Submission
	/** This rule draft’s title, like `"Operation Of ABC Board State Liquor Stores"`. */
	title?: Maybe<Scalars["String"]["output"]>
	/** Whether this rule draft should use a manual history update, as opposed to the autogenerated one based on the filing information. */
	useManualHistoryUpdate: Scalars["Boolean"]["output"]
}

export type RuleDraftToSave = {
	/** The author or authors of this rule draft. */
	author?: InputMaybe<Scalars["String"]["input"]>
	/** The authority of this rule draft, like the section number. */
	authority?: InputMaybe<Scalars["String"]["input"]>
	/** This rule draft’s text content. */
	description?: InputMaybe<Scalars["String"]["input"]>
	/** This rule draft’s ID in the database */
	id: Scalars["ID"]["input"]
	/** This rule draft’s ID text (like `"20-X-4-.01"`). */
	idText?: InputMaybe<Scalars["String"]["input"]>
	/** The history notes about this rule draft. Bolding happens automatically. */
	manualHistoryUpdate?: InputMaybe<Scalars["String"]["input"]>
	/** Whether the rule draft appears on a new page. */
	pageBreakBefore?: InputMaybe<Scalars["Boolean"]["input"]>
	/** The penalty note for this rule draft, if any. */
	penalty?: InputMaybe<Scalars["String"]["input"]>
	/** This rule draft’s title, like `"Definitions"`. */
	title?: InputMaybe<Scalars["String"]["input"]>
	/** Whether to use the manual history update for this rule draft. */
	useManualHistoryUpdate?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type RuleDraftUpdatedLogEntry = FilingActivityLogEntry &
	WithDiffLogEntry & {
		__typename?: "RuleDraftUpdatedLogEntry"
		date: Scalars["Date"]["output"]
		diff: Scalars["String"]["output"]
		filingID: Scalars["ID"]["output"]
		id: Scalars["ID"]["output"]
		user: ActivityLogUser
		wasReset: Scalars["Boolean"]["output"]
	}

export type RuleResultItem = SearchResultItem & {
	__typename?: "RuleResultItem"
	author?: Maybe<Scalars["String"]["output"]>
	description?: Maybe<Scalars["String"]["output"]>
	editorsNote?: Maybe<Scalars["String"]["output"]>
	id: Scalars["ID"]["output"]
	idText: Scalars["String"]["output"]
	parentID: Scalars["ID"]["output"]
	title: Scalars["String"]["output"]
}

export type RuleSnapshot = ChapterOrRuleOrSnapshot &
	NewSnapshotOrRuleSnapshot &
	Snapshot & {
		__typename?: "RuleSnapshot"
		author?: Maybe<Scalars["String"]["output"]>
		authority?: Maybe<Scalars["String"]["output"]>
		date: Scalars["Date"]["output"]
		description?: Maybe<Scalars["String"]["output"]>
		editorsNote?: Maybe<Scalars["String"]["output"]>
		history?: Maybe<Scalars["String"]["output"]>
		idText: Scalars["String"]["output"]
		number: Scalars["String"]["output"]
		pageBreakBefore: Scalars["Boolean"]["output"]
		penalty?: Maybe<Scalars["String"]["output"]>
		/** Whether this rule was repealed. */
		repealed: Scalars["Boolean"]["output"]
		shown: Scalars["Boolean"]["output"]
		title: Scalars["String"]["output"]
	}

export type SearchFilingsResultItem = {
	content: Scalars["String"]["output"]
	fileUrl: Scalars["String"]["output"]
	id: Scalars["ID"]["output"]
	publicationDate: Scalars["Date"]["output"]
	publicationID: Scalars["String"]["output"]
	title: Scalars["String"]["output"]
}

export type SearchFilingsResults = {
	__typename?: "SearchFilingsResults"
	items: Array<SearchFilingsResultItem>
	totalCount: Scalars["Int"]["output"]
}

export type SearchResultItem = {
	id: Scalars["ID"]["output"]
	title: Scalars["String"]["output"]
}

export type SearchResults = {
	__typename?: "SearchResults"
	items: Array<SearchResultItem>
	totalCount: Scalars["Int"]["output"]
}

export type Signature = {
	__typename?: "Signature"
	date: Scalars["Date"]["output"]
	id: Scalars["ID"]["output"]
	value: Scalars["String"]["output"]
}

/** Equivalent to `NewSnapshot | AgencySnapshot | ChapterSnapshot | RuleSnapshot` */
export type Snapshot = {
	date: Scalars["Date"]["output"]
	number: Scalars["String"]["output"]
}

export type StrikethroughApprovedLogEntry = FilingActivityLogEntry &
	WithFileLogEntry & {
		__typename?: "StrikethroughApprovedLogEntry"
		date: Scalars["Date"]["output"]
		fileID: Scalars["ID"]["output"]
		fileUrl: Scalars["String"]["output"]
		filingID: Scalars["ID"]["output"]
		id: Scalars["ID"]["output"]
		user: ActivityLogUser
	}

export type Submission = {
	__typename?: "Submission"
	/** The date that this proposal was approved. If this is defined, the file for it should have the "stamp" on it with this date */
	approvedDate?: Maybe<Scalars["Date"]["output"]>
	currentCode?: Maybe<ChapterOrRuleOrSnapshot>
	/** This is only used if `isProposal` is false and the filing type can be withdrawn or disapproved. The date the filing was disapproved by committee. */
	disapproveDate?: Maybe<Scalars["Date"]["output"]>
	/** This is only used if `isProposal` is false and the filing type can be withdrawn or disapproved. The date the filing was disapproved with suggested amendments by committee. */
	disapproveWithSuggestionsDate?: Maybe<Scalars["Date"]["output"]>
	/** The draft for the current filing, if it requires one. Can be a `ChapterDraft` or a `RuleDraft`. */
	draft?: Maybe<ChapterDraftOrRuleDraft>
	draftID?: Maybe<Scalars["ID"]["output"]>
	/** The file in GridFS of this filing's proposal forms. It's constructed from the forms required by this filing's filing type. */
	file?: Maybe<FileMetadata>
	/** The URL to serve this filing's proposal forms. It's constructed from the forms required by this filing's filing type. */
	fileUrl?: Maybe<Scalars["String"]["output"]>
	/** The Filing that owns this certification. */
	filing: Filing
	formAnswer?: Maybe<FormEntryValue>
	/** This submission's form entries. */
	formEntries: Array<FormEntry>
	/** The forms that are due with this submission. */
	forms: Array<Form>
	id: Scalars["ID"]["output"]
	/** Whether this submission is the certification of its filing */
	isCertification: Scalars["Boolean"]["output"]
	/** Whether this submission is the proposal of its filing */
	isProposal: Scalars["Boolean"]["output"]
	/** Whether this submission is a recertification of its filing */
	isRecertification: Scalars["Boolean"]["output"]
	/** The next soonest publication for which this submission could be eligible. */
	nextPublication: Publication
	/** The user uploaded override strikethrough file. */
	overrideStrikethrough?: Maybe<FileMetadata>
	/** The ID of the user uploaded override strikethrough file. */
	overrideStrikethroughID?: Maybe<Scalars["ID"]["output"]>
	/** The publication that this proposal is slated for or was published with. */
	publication?: Maybe<Publication>
	/** The publication object that this filing for its proposal is associated with. */
	publicationID?: Maybe<Scalars["String"]["output"]>
	published: Scalars["Boolean"]["output"]
	/** The comments with which this filing was rejected, if it was rejected with comments. */
	rejectionComments: Array<CommentData>
	/** The date this filing was resubmitted from proposal after being rejected by LSA. */
	resubmissionDate?: Maybe<Scalars["Date"]["output"]>
	/** The generated strikethrough file between the current code and the draft, if this filing needs one. */
	strikethrough?: Maybe<FileMetadata>
	/** The ID of the generated strikethrough file between the current code and the draft, if this filing needs one. */
	strikethroughID?: Maybe<Scalars["ID"]["output"]>
	/** The URL to the strikethrough PDF file (the override one, if it exists, or the generated one). You can see the autogenerated version instead of any existing override by passing the `?auto` query param. */
	strikethroughUrl?: Maybe<Scalars["String"]["output"]>
	/** Whether the user's viewed the currently generated strikethrough */
	strikethroughViewed: Scalars["Boolean"]["output"]
	/** The original submission date of this filing from proposal by the agency team. Recalling resets this date, but LSA rejecting it does not. */
	submissionDate?: Maybe<Scalars["Date"]["output"]>
	/** This is only used if `isProposal` is false and the filing type can be withdrawn or disapproved. The date the agency withdrew the filing. */
	withdrawDate?: Maybe<Scalars["Date"]["output"]>
	/** If this filing was withdrawn or disapproved, the publication wherein that should be published. */
	withdrawOrDisapprovePublicationID?: Maybe<Scalars["String"]["output"]>
}

export type SubmissionFormAnswerArgs = {
	formKey: Scalars["String"]["input"]
	question: Scalars["String"]["input"]
}

export type TextSpecs = {
	__typename?: "TextSpecs"
	default?: Maybe<Scalars["String"]["output"]>
}

export type User = {
	__typename?: "User"
	/** Agencies that this user has been approved to manage. */
	agencies: Array<Agency>
	/** The IDs of the agencies in the agencies array. */
	agencyIDs: Array<Scalars["ID"]["output"]>
	/** This user’s email. Used for login. */
	email: Scalars["String"]["output"]
	/** This user’s ID in the database */
	id: Scalars["ID"]["output"]
	/** Whether this user is active and should be able to access the portal. */
	isActive: Scalars["Boolean"]["output"]
	/** Whether this user is an admin. */
	isAdmin: Scalars["Boolean"]["output"]
	/** This user’s name. */
	name: Scalars["String"]["output"]
	/** Agencies that this user has requested access to manage. */
	pendingAgencies: Array<Agency>
	/** The IDs of the agencies in the pending agencies array. */
	pendingAgencyIDS: Array<Scalars["ID"]["output"]>
	/** Whether this user is subscribed to receive status updates by email. */
	subscribedToEmails: Scalars["Boolean"]["output"]
}

export type WithDiffLogEntry = {
	date: Scalars["Date"]["output"]
	diff: Scalars["String"]["output"]
	filingID: Scalars["ID"]["output"]
	id: Scalars["ID"]["output"]
	user: ActivityLogUser
	wasReset: Scalars["Boolean"]["output"]
}

export type WithFileLogEntry = {
	date: Scalars["Date"]["output"]
	fileID: Scalars["ID"]["output"]
	fileUrl: Scalars["String"]["output"]
	filingID: Scalars["ID"]["output"]
	id: Scalars["ID"]["output"]
	user: ActivityLogUser
}

export type SearchQueryVariables = Exact<{
	query: Scalars["String"]["input"]
	agencyControlNumbers?: InputMaybe<
		Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
	>
	chapterIdTexts?: InputMaybe<
		Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
	>
	skip?: InputMaybe<Scalars["Int"]["input"]>
	limit?: InputMaybe<Scalars["Int"]["input"]>
}>

export type SearchQuery = {
	__typename: "Query"
	search: {
		__typename: "SearchResults"
		totalCount: number
		items: Array<
			| {
					__typename: "AgencyResultItem"
					controlNumber: string
					id: string
					title: string
			  }
			| {
					__typename: "ChapterResultItem"
					idText: string
					parentID: string
					id: string
					title: string
			  }
			| {
					__typename: "RuleResultItem"
					idText: string
					parentID: string
					description?: string | null
					author?: string | null
					editorsNote?: string | null
					id: string
					title: string
			  }
		>
	}
}

export type AdminCodeSearch_QueryFragmentFragment = {
	__typename: "Query"
	agencies: Array<{
		__typename: "Agency"
		id: string
		title: string
		sortTitle?: string | null
		controlNumber: string
		chapters: Array<{ __typename: "Chapter"; id: string; idText: string; title: string }>
	}>
} & { " $fragmentName"?: "AdminCodeSearch_QueryFragmentFragment" }

export type AnnouncementList_QueryFragmentFragment = {
	__typename: "Query"
	announcements: Array<{
		__typename: "Announcement"
		title: string
		updatedAt: Date
		description: string
	}>
} & { " $fragmentName"?: "AnnouncementList_QueryFragmentFragment" }

export type FilingHistory_FilingsFragmentFragment = {
	__typename: "Filing"
	name?: string | null
	scope?: FilingScope | null
	filingTypeKey: string
	intendedAction?: IntendedAction | null
	currentCodeNumber?: string | null
	effectiveDate?: Date | null
	proposal: { __typename: "Submission"; fileUrl?: string | null }
	latestCertification: {
		__typename: "Submission"
		id: string
		fileUrl?: string | null
		draft?:
			| { __typename: "ChapterDraft"; idText?: string | null }
			| { __typename: "RuleDraft"; idText?: string | null }
			| null
	}
} & { " $fragmentName"?: "FilingHistory_FilingsFragmentFragment" }

export type FilingRow_FilingFragment = ({
	__typename: "Filing"
	id: string
	name?: string | null
	intendedAction?: IntendedAction | null
	scope?: FilingScope | null
	filingTypeKey: string
	status: FilingStatus
	effectiveDate?: Date | null
	currentSubmission: {
		__typename: "Submission"
		fileUrl?: string | null
		submissionDate?: Date | null
		resubmissionDate?: Date | null
		isProposal: boolean
		strikethroughUrl?: string | null
		currentCode?:
			| { __typename: "Chapter"; idText: string }
			| { __typename: "ChapterSnapshot"; idText: string }
			| { __typename: "Rule"; idText: string }
			| { __typename: "RuleSnapshot"; idText: string }
			| null
		rejectionComments: Array<{ __typename: "CommentData" }>
		nextPublication: { __typename: "Publication"; submissionDeadline: Date }
		formEntries: Array<{
			__typename: "FormEntry"
			values: Array<{ __typename: "FormEntryValue"; date?: Date | null }>
		}>
		draft?:
			| {
					__typename: "ChapterDraft"
					ruleDrafts: Array<{ __typename: "RuleDraft"; useManualHistoryUpdate: boolean }>
			  }
			| { __typename: "RuleDraft"; useManualHistoryUpdate: boolean }
			| null
	}
	agency: { __typename: "Agency"; title: string }
	filingType: {
		__typename: "FilingType"
		name: string
		hasProposal?: boolean | null
		canBeWithdrawnOrDisapproved?: boolean | null
		requiresScopeAndIntendedAction?: boolean | null
	}
	proposal: { __typename: "Submission"; fileUrl?: string | null }
	latestCertification: { __typename: "Submission"; fileUrl?: string | null }
	activityLog: Array<
		| { __typename: "ChapterDraftUpdatedLogEntry" }
		| { __typename: "FilingApprovedLogEntry" }
		| { __typename: "FilingCreatedLogEntry" }
		| { __typename: "FilingDisapprovedLogEntry" }
		| { __typename: "FilingDisapprovedWithSuggestionsLogEntry" }
		| { __typename: "FilingRecalledLogEntry" }
		| { __typename: "FilingRejectedLogEntry" }
		| { __typename: "FilingSubmittedLogEntry" }
		| { __typename: "FilingWithdrawnLogEntry" }
		| { __typename: "FormSignedLogEntry" }
		| { __typename: "FormUpdatedLogEntry" }
		| { __typename: "RuleDraftUpdatedLogEntry" }
		| { __typename: "StrikethroughApprovedLogEntry" }
	>
} & {
	" $fragmentRefs"?: {
		ProcessForms_FilingFragmentFragment: ProcessForms_FilingFragmentFragment
	}
}) & { " $fragmentName"?: "FilingRow_FilingFragment" }

export type DeleteFilingMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type DeleteFilingMutation = {
	__typename: "Mutation"
	permanentlyDeleteFiling: { __typename: "Filing" }
}

export type SubmitFilingMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type SubmitFilingMutation = {
	__typename: "Mutation"
	submitFiling: { __typename: "Filing"; status: FilingStatus }
}

export type RecallFilingMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type RecallFilingMutation = {
	__typename: "Mutation"
	recallFiling: { __typename: "Filing"; status: FilingStatus }
}

export type ApproveFilingMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type ApproveFilingMutation = {
	__typename: "Mutation"
	approveFiling: { __typename: "Filing" }
}

export type WithdrawOrDisapproveFilingMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
	action: FilingUserAction
}>

export type WithdrawOrDisapproveFilingMutation = {
	__typename: "Mutation"
	withdrawOrDisapproveFiling: { __typename: "Filing"; status: FilingStatus }
}

export type RejectFilingMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
	comment: Scalars["String"]["input"]
}>

export type RejectFilingMutation = {
	__typename: "Mutation"
	rejectFiling: { __typename: "Filing" }
}

export type LogoutMutationVariables = Exact<{ [key: string]: never }>

export type LogoutMutation = { __typename: "Mutation"; logout: boolean }

export type AddAgencyMutationVariables = Exact<{
	controlNumber: Scalars["String"]["input"]
	title: Scalars["String"]["input"]
}>

export type AddAgencyMutation = {
	__typename: "Mutation"
	addAgency: { __typename: "Agency" }
}

export type AddAnnouncementMutationVariables = Exact<{
	title: Scalars["String"]["input"]
	description: Scalars["String"]["input"]
	content: Scalars["String"]["input"]
}>

export type AddAnnouncementMutation = {
	__typename: "Mutation"
	addAnnouncement: { __typename: "Announcement"; id: string }
}

export type AddChapterMutationVariables = Exact<{
	parentID: Scalars["ID"]["input"]
	idText: Scalars["String"]["input"]
	title: Scalars["String"]["input"]
}>

export type AddChapterMutation = {
	__typename: "Mutation"
	addChapter: { __typename: "Chapter" }
}

export type AddDraftFilingMutationVariables = Exact<{
	agencyID: Scalars["ID"]["input"]
	filingTypeKey: Scalars["String"]["input"]
	name?: InputMaybe<Scalars["String"]["input"]>
	scope?: InputMaybe<FilingScope>
	intendedAction?: InputMaybe<IntendedAction>
	currentCodeNumber?: InputMaybe<Scalars["String"]["input"]>
}>

export type AddDraftFilingMutation = {
	__typename: "Mutation"
	addFiling: { __typename: "Filing"; id: string }
}

export type NewDraftButton_QueryFragmentFragment = {
	__typename: "Query"
	filingTypes: Array<{
		__typename: "FilingType"
		key: string
		name: string
		requiresScopeAndIntendedAction?: boolean | null
	}>
} & { " $fragmentName"?: "NewDraftButton_QueryFragmentFragment" }

export type NewDraftButtonQueryVariables = Exact<{
	agencyID: Scalars["ID"]["input"]
	needsChapter: Scalars["Boolean"]["input"]
	needsRules: Scalars["Boolean"]["input"]
}>

export type NewDraftButtonQuery = {
	__typename: "Query"
	agency: {
		__typename: "Agency"
		chapters: Array<{
			__typename: "Chapter"
			id: string
			idText: string
			title: string
			rules?: Array<{ __typename: "Rule"; id: string; idText: string; title: string }>
		}>
	}
}

export type AddResourceMutationVariables = Exact<{
	title: Scalars["String"]["input"]
	file: Scalars["File"]["input"]
}>

export type AddResourceMutation = {
	__typename: "Mutation"
	addResource: { __typename: "Resource" }
}

export type AddRuleMutationVariables = Exact<{
	parentID: Scalars["ID"]["input"]
	idText: Scalars["String"]["input"]
	title: Scalars["String"]["input"]
}>

export type AddRuleMutation = {
	__typename: "Mutation"
	addRule: { __typename: "Rule"; id: string }
}

export type AddRuleDraftMutationVariables = Exact<{
	parentID: Scalars["ID"]["input"]
	idText?: InputMaybe<Scalars["String"]["input"]>
	title?: InputMaybe<Scalars["String"]["input"]>
}>

export type AddRuleDraftMutation = {
	__typename: "Mutation"
	addRuleDraft: { __typename: "RuleDraft"; id: string }
}

export type PublicationArchives_QueryFragmentFragment = {
	__typename: "Query"
	publicationArchives: Array<
		| { __typename: "HistoricalPublication"; id: string; publicationDate: Date }
		| { __typename: "Publication"; id: string; publicationDate: Date }
	>
} & { " $fragmentName"?: "PublicationArchives_QueryFragmentFragment" }

export type SearchFilingsQueryVariables = Exact<{
	query: Scalars["String"]["input"]
	controlNumbers?: InputMaybe<
		Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
	>
	years?: InputMaybe<Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"]>
	skip?: InputMaybe<Scalars["Int"]["input"]>
	limit?: InputMaybe<Scalars["Int"]["input"]>
}>

export type SearchFilingsQuery = {
	__typename: "Query"
	searchFilings: {
		__typename: "SearchFilingsResults"
		totalCount: number
		items: Array<
			| {
					__typename: "FilingResultItem"
					isProposal: boolean
					filingType: string
					withdrawDate?: Date | null
					disapproveDate?: Date | null
					disapproveWithSuggestionsDate?: Date | null
					id: string
					title: string
					content: string
					publicationDate: Date
					fileUrl: string
			  }
			| {
					__typename: "HistoricalFilingResultItem"
					publicationType: PublicationType
					id: string
					title: string
					content: string
					publicationDate: Date
					fileUrl: string
			  }
		>
	}
}

export type PublicationArchiveSearch_QueryFragmentFragment = {
	__typename: "Query"
	agencies: Array<{
		__typename: "Agency"
		id: string
		title: string
		sortTitle?: string | null
		controlNumber: string
	}>
} & { " $fragmentName"?: "PublicationArchiveSearch_QueryFragmentFragment" }

export type RepealRuleDraftMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
	title: Scalars["String"]["input"]
	description: Scalars["String"]["input"]
}>

export type RepealRuleDraftMutation = {
	__typename: "Mutation"
	updateRuleDraft: { __typename: "RuleDraft" }
}

export type RepealRuleDraftButton_RuleDraftFragmentFragment = {
	__typename: "RuleDraft"
	id: string
	title?: string | null
} & { " $fragmentName"?: "RepealRuleDraftButton_RuleDraftFragmentFragment" }

export type ResourceList_QueryFragmentFragment = {
	__typename: "Query"
	resources: Array<{ __typename: "Resource"; title: string; fileUrl: string }>
} & { " $fragmentName"?: "ResourceList_QueryFragmentFragment" }

export type SignFormEntryMutationVariables = Exact<{
	filingID: Scalars["ID"]["input"]
	formKey: Scalars["String"]["input"]
	value: Scalars["String"]["input"]
	screenshot: Scalars["File"]["input"]
}>

export type SignFormEntryMutation = {
	__typename: "Mutation"
	signFormEntry: { __typename: "FormEntry" }
}

export type UpdateAnnouncementMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
	title?: InputMaybe<Scalars["String"]["input"]>
	description?: InputMaybe<Scalars["String"]["input"]>
	content?: InputMaybe<Scalars["String"]["input"]>
}>

export type UpdateAnnouncementMutation = {
	__typename: "Mutation"
	updateAnnouncement: { __typename: "Announcement" }
}

export type UpdateResourceMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
	title: Scalars["String"]["input"]
	file?: InputMaybe<Scalars["File"]["input"]>
}>

export type UpdateResourceMutation = {
	__typename: "Mutation"
	updateResource: { __typename: "Resource" }
}

export type UsersQueryVariables = Exact<{ [key: string]: never }>

export type UsersQuery = {
	__typename: "Query"
	users: Array<{
		__typename: "User"
		id: string
		name: string
		email: string
		isAdmin: boolean
		pendingAgencies: Array<{ __typename: "Agency"; id: string }>
	}>
}

export type ViewChapterButton_ChapterFragmentFragment = {
	__typename: "Chapter"
	idText: string
	fileUrl: string
	showToPublic: boolean
} & { " $fragmentName"?: "ViewChapterButton_ChapterFragmentFragment" }

export type ViewCurrentFilings_PublicationFragmentFragment = {
	__typename: "Publication"
	id: string
	title: string
	publicationDate: Date
	editorsNote?: string | null
} & { " $fragmentName"?: "ViewCurrentFilings_PublicationFragmentFragment" }

export type ViewCurrentFilings_FilingFragmentFragment = {
	__typename: "Filing"
	scope?: FilingScope | null
	intendedAction?: IntendedAction | null
	effectiveDate?: Date | null
	isEffective: boolean
	filingType: {
		__typename: "FilingType"
		key: string
		name: string
		hasProposal?: boolean | null
		shouldMergeIntoCurrentCode?: boolean | null
	}
	agency: {
		__typename: "Agency"
		title: string
		sortTitle?: string | null
		headingForNonstandardSectionInAam?: string | null
	}
	proposal: {
		__typename: "Submission"
		fileUrl?: string | null
		isProposal: boolean
		publication?: {
			__typename: "Publication"
			id: string
			title: string
			publicationDate: Date
		} | null
		draft?:
			| { __typename: "ChapterDraft"; idText?: string | null; title?: string | null }
			| { __typename: "RuleDraft"; idText?: string | null; title?: string | null }
			| null
		currentCode?:
			| { __typename: "Chapter"; idText: string; title: string }
			| { __typename: "ChapterSnapshot"; idText: string; title: string }
			| { __typename: "Rule"; idText: string; title: string }
			| { __typename: "RuleSnapshot"; idText: string; title: string }
			| null
	}
	certification: {
		__typename: "Submission"
		submissionDate?: Date | null
		withdrawOrDisapprovePublicationID?: string | null
		withdrawDate?: Date | null
		disapproveDate?: Date | null
		disapproveWithSuggestionsDate?: Date | null
		fileUrl?: string | null
		isProposal: boolean
		draft?:
			| { __typename: "ChapterDraft"; idText?: string | null; title?: string | null }
			| { __typename: "RuleDraft"; idText?: string | null; title?: string | null }
			| null
		currentCode?:
			| { __typename: "Chapter"; idText: string; title: string }
			| { __typename: "ChapterSnapshot"; idText: string; title: string }
			| { __typename: "Rule"; idText: string; title: string }
			| { __typename: "RuleSnapshot"; idText: string; title: string }
			| null
		publication?: {
			__typename: "Publication"
			id: string
			title: string
			publicationDate: Date
		} | null
		certifyingWithChanges?: { __typename: "FormEntryValue"; text?: string | null } | null
		titleFromFormEntry?: { __typename: "FormEntryValue"; text?: string | null } | null
	}
	recertifications: Array<{
		__typename: "Submission"
		submissionDate?: Date | null
		withdrawOrDisapprovePublicationID?: string | null
		withdrawDate?: Date | null
		disapproveDate?: Date | null
		disapproveWithSuggestionsDate?: Date | null
		fileUrl?: string | null
		isProposal: boolean
		draft?:
			| { __typename: "ChapterDraft"; idText?: string | null; title?: string | null }
			| { __typename: "RuleDraft"; idText?: string | null; title?: string | null }
			| null
		currentCode?:
			| { __typename: "Chapter"; idText: string; title: string }
			| { __typename: "ChapterSnapshot"; idText: string; title: string }
			| { __typename: "Rule"; idText: string; title: string }
			| { __typename: "RuleSnapshot"; idText: string; title: string }
			| null
		publication?: {
			__typename: "Publication"
			id: string
			title: string
			publicationDate: Date
		} | null
		certifyingWithChanges?: { __typename: "FormEntryValue"; text?: string | null } | null
	}>
} & { " $fragmentName"?: "ViewCurrentFilings_FilingFragmentFragment" }

export type ViewRuleButton_RuleFragmentFragment = ({
	__typename: "Rule"
	idText: string
	fileUrl: string
} & {
	" $fragmentRefs"?: {
		ViewRuleButton_DeferredRuleFragmentFragment: ViewRuleButton_DeferredRuleFragmentFragment
	}
}) & { " $fragmentName"?: "ViewRuleButton_RuleFragmentFragment" }

export type ViewRuleButton_DeferredRuleFragmentFragment = {
	__typename: "Rule"
	title: string
	description?: string | null
	author?: string | null
	authority?: string | null
	history?: string | null
	penalty?: string | null
	editorsNote?: string | null
} & { " $fragmentName"?: "ViewRuleButton_DeferredRuleFragmentFragment" }

export type ViewRuleDraftButton_RuleDraftFragmentFragment = {
	__typename: "RuleDraft"
	id: string
	title?: string | null
	idText?: string | null
	description?: string | null
	author?: string | null
	authority?: string | null
	history: string
	penalty?: string | null
} & { " $fragmentName"?: "ViewRuleDraftButton_RuleDraftFragmentFragment" }

export type UserQueryVariables = Exact<{ [key: string]: never }>

export type UserQuery = {
	__typename: "Query"
	user: {
		__typename: "User"
		id: string
		name: string
		email: string
		isAdmin: boolean
		isActive: boolean
		agencies: Array<{
			__typename: "Agency"
			id: string
			title: string
			controlNumber: string
		}>
	}
}

export type AgencySortTitlesQueryVariables = Exact<{ [key: string]: never }>

export type AgencySortTitlesQuery = {
	__typename: "Query"
	agencies: Array<{
		__typename: "Agency"
		sortTitle?: string | null
		title: string
		controlNumber: string
		shown: boolean
	}>
}

export type AnnouncementByTitleQueryVariables = Exact<{
	title: Scalars["String"]["input"]
}>

export type AnnouncementByTitleQuery = {
	__typename: "Query"
	announcement: {
		__typename: "Announcement"
		description: string
		content: string
		updatedAt: Date
	}
}

export type ChapterDraftEditor_FilingFragmentFragment = ({
	__typename: "Filing"
	id: string
	status: FilingStatus
	intendedAction?: IntendedAction | null
	agency: {
		__typename: "Agency"
		controlNumber: string
		chapters: Array<{ __typename: "Chapter"; id: string; idText: string; title: string }>
	}
	currentSubmission: {
		__typename: "Submission"
		draft?:
			| {
					__typename: "ChapterDraft"
					id: string
					agencyTitle?: string | null
					idText?: string | null
					title?: string | null
					overridePlacement: boolean
					placeAt?: Placement | null
					placeAfterID?: string | null
					proposalCertification: boolean
					ruleDrafts: Array<
						{
							__typename: "RuleDraft"
							id: string
							idText?: string | null
							title?: string | null
							isValid: boolean
							proposalCertification: boolean
						} & {
							" $fragmentRefs"?: {
								ViewRuleDraftButton_RuleDraftFragmentFragment: ViewRuleDraftButton_RuleDraftFragmentFragment
								RuleDraftEditor_RuleDraftFragmentFragment: RuleDraftEditor_RuleDraftFragmentFragment
								RepealRuleDraftButton_RuleDraftFragmentFragment: RepealRuleDraftButton_RuleDraftFragmentFragment
							}
						}
					>
			  }
			| { __typename: "RuleDraft" }
			| null
		currentCode?:
			| { __typename: "Chapter"; id: string; idText: string }
			| { __typename: "ChapterSnapshot" }
			| { __typename: "Rule" }
			| { __typename: "RuleSnapshot" }
			| null
		certifyingWithChanges?: { __typename: "FormEntryValue"; text?: string | null } | null
	}
} & {
	" $fragmentRefs"?: {
		RuleDraftEditor_FilingFragmentFragment: RuleDraftEditor_FilingFragmentFragment
		StrikethroughPreview_FilingFragmentFragment: StrikethroughPreview_FilingFragmentFragment
	}
}) & { " $fragmentName"?: "ChapterDraftEditor_FilingFragmentFragment" }

export type UpdateChapterDraftMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
	agencyTitle?: InputMaybe<Scalars["String"]["input"]>
	idText?: InputMaybe<Scalars["String"]["input"]>
	title?: InputMaybe<Scalars["String"]["input"]>
	ruleDrafts?: InputMaybe<Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]>
	ruleDraftsToSave?: InputMaybe<Array<RuleDraftToSave> | RuleDraftToSave>
	overridePlacement?: InputMaybe<Scalars["Boolean"]["input"]>
	placeAt?: InputMaybe<Placement>
	placeAfterID?: InputMaybe<Scalars["ID"]["input"]>
	filingID: Scalars["ID"]["input"]
}>

export type UpdateChapterDraftMutation = {
	__typename: "Mutation"
	updateChapterDraft: { __typename: "ChapterDraft" }
	approveStrikethrough: { __typename: "Filing" }
}

export type DeleteRuleDraftMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type DeleteRuleDraftMutation = {
	__typename: "Mutation"
	permanentlyDeleteRuleDraft: { __typename: "RuleDraft" }
}

export type ResetChapterDraftMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type ResetChapterDraftMutation = {
	__typename: "Mutation"
	resetChapterDraft: { __typename: "ChapterDraft" }
}

export type DraftForEditorQueryVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type DraftForEditorQuery = {
	__typename: "Query"
	filing: {
		__typename: "Filing"
		id: string
		name?: string | null
		intendedAction?: IntendedAction | null
		scope?: FilingScope | null
		agencyID: string
		currentSubmission: {
			__typename: "Submission"
			draft?: { __typename: "ChapterDraft" } | { __typename: "RuleDraft" } | null
			currentCode?:
				| { __typename: "Chapter"; idText: string }
				| { __typename: "ChapterSnapshot"; idText: string }
				| { __typename: "Rule"; idText: string }
				| { __typename: "RuleSnapshot"; idText: string }
				| null
		}
	} & {
		" $fragmentRefs"?: {
			ChapterDraftEditor_FilingFragmentFragment: ChapterDraftEditor_FilingFragmentFragment
			StandaloneRuleDraftEditor_FilingFragmentFragment: StandaloneRuleDraftEditor_FilingFragmentFragment
		}
	}
}

export type RuleDraftEditor_FilingFragmentFragment = {
	__typename: "Filing"
	id: string
	status: FilingStatus
	scope?: FilingScope | null
	intendedAction?: IntendedAction | null
	currentSubmission: {
		__typename: "Submission"
		currentCode?:
			| { __typename: "Chapter"; idText: string }
			| { __typename: "ChapterSnapshot"; idText: string }
			| { __typename: "Rule" }
			| { __typename: "RuleSnapshot" }
			| null
		certifyingWithChanges?: { __typename: "FormEntryValue"; text?: string | null } | null
	}
} & { " $fragmentName"?: "RuleDraftEditor_FilingFragmentFragment" }

export type RuleDraftEditor_RuleDraftFragmentFragment = ({
	__typename: "RuleDraft"
	id: string
	idText?: string | null
	title?: string | null
	description?: string | null
	author?: string | null
	authority?: string | null
	penalty?: string | null
	manualHistoryUpdate?: string | null
	useManualHistoryUpdate: boolean
	generatedHistoryUpdate: string
	pageBreakBefore: boolean
	proposalCertification: boolean
	newRule: boolean
	existingHistory?: string | null
	parent?: { __typename: "ChapterDraft"; idText?: string | null } | null
} & {
	" $fragmentRefs"?: {
		RepealRuleDraftButton_RuleDraftFragmentFragment: RepealRuleDraftButton_RuleDraftFragmentFragment
	}
}) & { " $fragmentName"?: "RuleDraftEditor_RuleDraftFragmentFragment" }

export type StandaloneRuleDraftEditor_FilingFragmentFragment = ({
	__typename: "Filing"
	id: string
	status: FilingStatus
	scope?: FilingScope | null
	intendedAction?: IntendedAction | null
	currentSubmission: {
		__typename: "Submission"
		draft?:
			| { __typename: "ChapterDraft" }
			| ({ __typename: "RuleDraft" } & {
					" $fragmentRefs"?: {
						RuleDraftEditor_RuleDraftFragmentFragment: RuleDraftEditor_RuleDraftFragmentFragment
					}
			  })
			| null
		currentCode?:
			| { __typename: "Chapter"; idText: string }
			| { __typename: "ChapterSnapshot"; idText: string }
			| { __typename: "Rule" }
			| { __typename: "RuleSnapshot" }
			| null
		certifyingWithChanges?: { __typename: "FormEntryValue"; text?: string | null } | null
	}
} & {
	" $fragmentRefs"?: {
		StrikethroughPreview_FilingFragmentFragment: StrikethroughPreview_FilingFragmentFragment
	}
}) & { " $fragmentName"?: "StandaloneRuleDraftEditor_FilingFragmentFragment" }

export type UpdateRuleDraftMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
	idText?: InputMaybe<Scalars["String"]["input"]>
	title?: InputMaybe<Scalars["String"]["input"]>
	description?: InputMaybe<Scalars["String"]["input"]>
	author?: InputMaybe<Scalars["String"]["input"]>
	authority?: InputMaybe<Scalars["String"]["input"]>
	manualHistoryUpdate?: InputMaybe<Scalars["String"]["input"]>
	useManualHistoryUpdate?: InputMaybe<Scalars["Boolean"]["input"]>
	penalty?: InputMaybe<Scalars["String"]["input"]>
	pageBreakBefore?: InputMaybe<Scalars["Boolean"]["input"]>
	filingID: Scalars["ID"]["input"]
}>

export type UpdateRuleDraftMutation = {
	__typename: "Mutation"
	updateRuleDraft: { __typename: "RuleDraft" }
	approveStrikethrough: { __typename: "Filing" }
}

export type ResetRuleDraftMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type ResetRuleDraftMutation = {
	__typename: "Mutation"
	resetRuleDraft: { __typename: "RuleDraft" }
}

export type StrikethroughPreview_FilingFragmentFragment = {
	__typename: "Filing"
	currentSubmission: { __typename: "Submission"; strikethroughUrl?: string | null }
} & { " $fragmentName"?: "StrikethroughPreview_FilingFragmentFragment" }

export type ESignerQueryVariables = Exact<{
	filingID: Scalars["ID"]["input"]
}>

export type ESignerQuery = {
	__typename: "Query"
	filing: {
		__typename: "Filing"
		name?: string | null
		scope?: FilingScope | null
		intendedAction?: IntendedAction | null
		agency: { __typename: "Agency"; id: string; title: string }
		currentSubmission: {
			__typename: "Submission"
			currentCode?:
				| { __typename: "Chapter"; idText: string }
				| { __typename: "ChapterSnapshot"; idText: string }
				| { __typename: "Rule"; idText: string }
				| { __typename: "RuleSnapshot"; idText: string }
				| null
			forms: Array<{
				__typename: "Form"
				key: string
				name: string
				signatureRequired: boolean
			}>
			formEntries: Array<{
				__typename: "FormEntry"
				formKey: string
				signatureID?: string | null
				fileUrl: string
			}>
		}
	} & {
		" $fragmentRefs"?: {
			ProcessForms_FilingFragmentFragment: ProcessForms_FilingFragmentFragment
		}
	}
}

export type UpdateAgencyMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
	title?: InputMaybe<Scalars["String"]["input"]>
	shown?: InputMaybe<Scalars["Boolean"]["input"]>
	chapters?: InputMaybe<Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]>
	sortTitle?: InputMaybe<Scalars["String"]["input"]>
}>

export type UpdateAgencyMutation = {
	__typename: "Mutation"
	updateAgency: { __typename: "Agency" }
}

export type AgencyEditor_AgencyFragmentFragment = {
	__typename: "Agency"
	id: string
	controlNumber: string
	title: string
	sortTitle?: string | null
	shown: boolean
	showToPublic: boolean
	chapters: Array<
		{
			__typename: "Chapter"
			id: string
			idText: string
			title: string
			fileUrl: string
			shown: boolean
		} & {
			" $fragmentRefs"?: {
				ViewChapterButton_ChapterFragmentFragment: ViewChapterButton_ChapterFragmentFragment
			}
		}
	>
} & { " $fragmentName"?: "AgencyEditor_AgencyFragmentFragment" }

export type AgencyEditor_QueryFragmentFragment = {
	__typename: "Query"
	agencies: Array<{
		__typename: "Agency"
		id: string
		controlNumber: string
		title: string
	}>
} & { " $fragmentName"?: "AgencyEditor_QueryFragmentFragment" }

export type DeleteChapterMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type DeleteChapterMutation = {
	__typename: "Mutation"
	permanentlyDeleteChapter: { __typename: "Chapter"; id: string }
}

export type ChapterEditor_ChapterFragmentFragment = ({
	__typename: "Chapter"
	id: string
	title: string
	fileUrl: string
	agencyTitle?: string | null
	editorsNote?: string | null
	editorsEndnote?: string | null
	idText: string
	header?: string | null
	footer?: string | null
	shown: boolean
	parent: { __typename: "Agency"; id: string; controlNumber: string; title: string }
	rules: Array<
		{ __typename: "Rule"; id: string; idText: string; title: string } & {
			" $fragmentRefs"?: {
				ViewRuleButton_RuleFragmentFragment: ViewRuleButton_RuleFragmentFragment
				RuleEditor_RuleFragmentFragment: RuleEditor_RuleFragmentFragment
			}
		}
	>
} & {
	" $fragmentRefs"?: {
		ViewChapterButton_ChapterFragmentFragment: ViewChapterButton_ChapterFragmentFragment
	}
}) & { " $fragmentName"?: "ChapterEditor_ChapterFragmentFragment" }

export type UpdateChapterMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
	agencyTitle?: InputMaybe<Scalars["String"]["input"]>
	editorsNote?: InputMaybe<Scalars["String"]["input"]>
	editorsEndnote?: InputMaybe<Scalars["String"]["input"]>
	idText?: InputMaybe<Scalars["String"]["input"]>
	title?: InputMaybe<Scalars["String"]["input"]>
	header?: InputMaybe<Scalars["String"]["input"]>
	footer?: InputMaybe<Scalars["String"]["input"]>
	shown?: InputMaybe<Scalars["Boolean"]["input"]>
	rules?: InputMaybe<Array<ChapterEditorRule> | ChapterEditorRule>
}>

export type UpdateChapterMutation = {
	__typename: "Mutation"
	updateChapter: { __typename: "Chapter" }
}

export type DeleteRuleMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type DeleteRuleMutation = {
	__typename: "Mutation"
	permanentlyDeleteRule: { __typename: "Rule"; id: string }
}

export type EditorSelector_QueryQueryVariables = Exact<{
	controlNumberOrIdText: Scalars["String"]["input"]
	skipDocument: Scalars["Boolean"]["input"]
}>

export type EditorSelector_QueryQuery = {
	__typename: "Query"
	document?:
		| ({ __typename: "Agency" } & {
				" $fragmentRefs"?: {
					AgencyEditor_AgencyFragmentFragment: AgencyEditor_AgencyFragmentFragment
				}
		  })
		| ({ __typename: "Chapter" } & {
				" $fragmentRefs"?: {
					ChapterEditor_ChapterFragmentFragment: ChapterEditor_ChapterFragmentFragment
				}
		  })
		| ({ __typename: "Rule" } & {
				" $fragmentRefs"?: {
					RuleEditor_RuleFragmentFragment: RuleEditor_RuleFragmentFragment
				}
		  })
} & {
	" $fragmentRefs"?: {
		AgencyEditor_QueryFragmentFragment: AgencyEditor_QueryFragmentFragment
	}
}

export type RuleEditor_RuleFragmentFragment = ({
	__typename: "Rule"
	id: string
	idText: string
	description?: string | null
	title: string
	author?: string | null
	authority?: string | null
	history?: string | null
	penalty?: string | null
	editorsNote?: string | null
	shown: boolean
	pageBreakBefore: boolean
	parent: {
		__typename: "Chapter"
		idText: string
		title: string
		parent: { __typename: "Agency"; controlNumber: string; title: string }
	}
} & {
	" $fragmentRefs"?: {
		ViewRuleButton_RuleFragmentFragment: ViewRuleButton_RuleFragmentFragment
	}
}) & { " $fragmentName"?: "RuleEditor_RuleFragmentFragment" }

export type UpdateRuleMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
	idText?: InputMaybe<Scalars["String"]["input"]>
	title?: InputMaybe<Scalars["String"]["input"]>
	description?: InputMaybe<Scalars["String"]["input"]>
	author?: InputMaybe<Scalars["String"]["input"]>
	authority?: InputMaybe<Scalars["String"]["input"]>
	history?: InputMaybe<Scalars["String"]["input"]>
	penalty?: InputMaybe<Scalars["String"]["input"]>
	editorsNote?: InputMaybe<Scalars["String"]["input"]>
	shown?: InputMaybe<Scalars["Boolean"]["input"]>
	pageBreakBefore?: InputMaybe<Scalars["Boolean"]["input"]>
}>

export type UpdateRuleMutation = {
	__typename: "Mutation"
	updateRule: { __typename: "Rule" }
}

export type ActivityLogQueryVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type ActivityLogQuery = {
	__typename: "Query"
	filing: {
		__typename: "Filing"
		name?: string | null
		status: FilingStatus
		intendedAction?: IntendedAction | null
		scope?: FilingScope | null
		agencyID: string
		activityLog: Array<
			| {
					__typename: "ChapterDraftUpdatedLogEntry"
					wasReset: boolean
					chapterUpdated: boolean
					updatedRules: Array<string>
					reorderedRules: Array<string>
					addedRule?: string | null
					deletedRule?: string | null
					id: string
					date: Date
					user: { __typename: "ActivityLogUser"; name: string; email: string }
			  }
			| {
					__typename: "FilingApprovedLogEntry"
					fileUrl: string
					id: string
					date: Date
					user: { __typename: "ActivityLogUser"; name: string; email: string }
			  }
			| {
					__typename: "FilingCreatedLogEntry"
					id: string
					date: Date
					user: { __typename: "ActivityLogUser"; name: string; email: string }
			  }
			| {
					__typename: "FilingDisapprovedLogEntry"
					fileUrl: string
					id: string
					date: Date
					user: { __typename: "ActivityLogUser"; name: string; email: string }
			  }
			| {
					__typename: "FilingDisapprovedWithSuggestionsLogEntry"
					fileUrl: string
					id: string
					date: Date
					user: { __typename: "ActivityLogUser"; name: string; email: string }
			  }
			| {
					__typename: "FilingRecalledLogEntry"
					fileUrl: string
					id: string
					date: Date
					user: { __typename: "ActivityLogUser"; name: string; email: string }
			  }
			| {
					__typename: "FilingRejectedLogEntry"
					fileUrl: string
					id: string
					date: Date
					user: { __typename: "ActivityLogUser"; name: string; email: string }
			  }
			| {
					__typename: "FilingSubmittedLogEntry"
					fileUrl: string
					id: string
					date: Date
					user: { __typename: "ActivityLogUser"; name: string; email: string }
			  }
			| {
					__typename: "FilingWithdrawnLogEntry"
					fileUrl: string
					id: string
					date: Date
					user: { __typename: "ActivityLogUser"; name: string; email: string }
			  }
			| {
					__typename: "FormSignedLogEntry"
					fileUrl: string
					id: string
					date: Date
					form: { __typename: "ActivityLogForm"; name: string }
					user: { __typename: "ActivityLogUser"; name: string; email: string }
			  }
			| {
					__typename: "FormUpdatedLogEntry"
					fileUrl: string
					wasNew: boolean
					id: string
					date: Date
					form: { __typename: "ActivityLogForm"; name: string }
					user: { __typename: "ActivityLogUser"; name: string; email: string }
			  }
			| {
					__typename: "RuleDraftUpdatedLogEntry"
					wasReset: boolean
					id: string
					date: Date
					user: { __typename: "ActivityLogUser"; name: string; email: string }
			  }
			| {
					__typename: "StrikethroughApprovedLogEntry"
					fileUrl: string
					id: string
					date: Date
					user: { __typename: "ActivityLogUser"; name: string; email: string }
			  }
		>
		currentSubmission: {
			__typename: "Submission"
			currentCode?:
				| { __typename: "Chapter"; idText: string }
				| { __typename: "ChapterSnapshot"; idText: string }
				| { __typename: "Rule"; idText: string }
				| { __typename: "RuleSnapshot"; idText: string }
				| null
		}
	}
}

export type FilingActivityLogDetailsModalQueryVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type FilingActivityLogDetailsModalQuery = {
	__typename: "Query"
	filingActivityLogEntry:
		| { __typename: "ChapterDraftUpdatedLogEntry"; diff: string }
		| { __typename: "FilingApprovedLogEntry"; fileUrl: string }
		| { __typename: "FilingCreatedLogEntry" }
		| { __typename: "FilingDisapprovedLogEntry"; fileUrl: string }
		| { __typename: "FilingDisapprovedWithSuggestionsLogEntry"; fileUrl: string }
		| { __typename: "FilingRecalledLogEntry"; fileUrl: string }
		| { __typename: "FilingRejectedLogEntry"; fileUrl: string; comments: string }
		| { __typename: "FilingSubmittedLogEntry"; fileUrl: string }
		| { __typename: "FilingWithdrawnLogEntry"; fileUrl: string }
		| {
				__typename: "FormSignedLogEntry"
				fileUrl: string
				form: { __typename: "ActivityLogForm"; name: string }
		  }
		| {
				__typename: "FormUpdatedLogEntry"
				fileUrl: string
				form: { __typename: "ActivityLogForm"; name: string }
		  }
		| { __typename: "RuleDraftUpdatedLogEntry"; diff: string }
		| { __typename: "StrikethroughApprovedLogEntry"; fileUrl: string }
}

export type DraftRow_FilingFragment = {
	__typename: "Filing"
	id: string
	scope?: FilingScope | null
	intendedAction?: IntendedAction | null
	status: FilingStatus
	currentSubmission: {
		__typename: "Submission"
		strikethroughViewed: boolean
		draft?:
			| { __typename: "ChapterDraft"; isValid: boolean }
			| { __typename: "RuleDraft"; isValid: boolean }
			| null
		certifyingWithChanges?: { __typename: "FormEntryValue"; text?: string | null } | null
	}
} & { " $fragmentName"?: "DraftRow_FilingFragment" }

export type FilingChecklistQueryVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type FilingChecklistQuery = {
	__typename: "Query"
	filing: {
		__typename: "Filing"
		status: FilingStatus
		agencyID: string
		name?: string | null
		intendedAction?: IntendedAction | null
		scope?: FilingScope | null
		filingType: { __typename: "FilingType"; hasProposal?: boolean | null }
		currentSubmission: {
			__typename: "Submission"
			isProposal: boolean
			strikethroughUrl?: string | null
			fileUrl?: string | null
			currentCode?:
				| { __typename: "Chapter"; idText: string }
				| { __typename: "ChapterSnapshot"; idText: string }
				| { __typename: "Rule"; idText: string }
				| { __typename: "RuleSnapshot"; idText: string }
				| null
			forms: Array<
				{
					__typename: "Form"
					key: string
					condition?: string | null
					signatureRequired: boolean
				} & { " $fragmentRefs"?: { FormRow_FormFragment: FormRow_FormFragment } }
			>
			formEntries: Array<
				{
					__typename: "FormEntry"
					formKey: string
					signatureID?: string | null
					values: Array<{
						__typename: "FormEntryValue"
						key: string
						text?: string | null
					}>
				} & {
					" $fragmentRefs"?: { FormRow_FormEntryFragment: FormRow_FormEntryFragment }
				}
			>
			rejectionComments: Array<{
				__typename: "CommentData"
				comment: string
				createdAt: Date
			}>
		}
	} & {
		" $fragmentRefs"?: {
			FormRow_FilingFragment: FormRow_FilingFragment
			DraftRow_FilingFragment: DraftRow_FilingFragment
			ProcessForms_FilingFragmentFragment: ProcessForms_FilingFragmentFragment
		}
	}
}

export type FormRow_FilingFragment = ({
	__typename: "Filing"
	currentSubmission: {
		__typename: "Submission"
		nextPublication: { __typename: "Publication"; publicationDate: Date }
	}
} & {
	" $fragmentRefs"?: {
		ProcessForms_FilingFragmentFragment: ProcessForms_FilingFragmentFragment
	}
}) & { " $fragmentName"?: "FormRow_FilingFragment" }

export type FormRow_FormFragment = {
	__typename: "Form"
	key: string
	name: string
	description?: string | null
	fields: Array<{
		__typename: "Field"
		key: string
		text: string
		helperText?: string | null
		type: FieldType
		condition?: string | null
		options?: Array<{ __typename: "FieldOption"; text: string; value: string }> | null
		dateSpecs?: {
			__typename: "DateSpecs"
			default?: number | null
			from?: DateReference | null
		} | null
		textSpecs?: { __typename: "TextSpecs"; default?: string | null } | null
	}>
} & { " $fragmentName"?: "FormRow_FormFragment" }

export type FormRow_FormEntryFragment = {
	__typename: "FormEntry"
	values: Array<{
		__typename: "FormEntryValue"
		key: string
		num?: number | null
		text?: string | null
		date?: Date | null
		file?: { __typename: "FileMetadata"; filename: string } | null
	}>
} & { " $fragmentName"?: "FormRow_FormEntryFragment" }

export type UpsertFormEntryMutationVariables = Exact<{
	formKey: Scalars["String"]["input"]
	filingID: Scalars["ID"]["input"]
	values: Array<FormEntryValueInput> | FormEntryValueInput
}>

export type UpsertFormEntryMutation = {
	__typename: "Mutation"
	upsertFormEntry: { __typename: "FormEntry" }
}

export type FilingsViewerQueryVariables = Exact<{
	type: PublicationType
	filingTypeKey?: InputMaybe<Scalars["String"]["input"]>
	fromPublication: Scalars["Boolean"]["input"]
}>

export type FilingsViewerQuery = {
	__typename: "Query"
	publication?: {
		__typename: "Publication"
		filings: Array<
			{ __typename: "Filing" } & {
				" $fragmentRefs"?: {
					ViewCurrentFilings_FilingFragmentFragment: ViewCurrentFilings_FilingFragmentFragment
				}
			}
		>
	} & {
		" $fragmentRefs"?: {
			ViewCurrentFilings_PublicationFragmentFragment: ViewCurrentFilings_PublicationFragmentFragment
		}
	}
	filings?: Array<
		{ __typename: "Filing" } & {
			" $fragmentRefs"?: {
				ViewCurrentFilings_FilingFragmentFragment: ViewCurrentFilings_FilingFragmentFragment
			}
		}
	>
} & {
	" $fragmentRefs"?: {
		PublicationArchives_QueryFragmentFragment: PublicationArchives_QueryFragmentFragment
	}
}

export type SendPasswordResetMutationVariables = Exact<{
	email: Scalars["String"]["input"]
}>

export type SendPasswordResetMutation = {
	__typename: "Mutation"
	sendPasswordResetLink: boolean
}

export type LoginMutationVariables = Exact<{
	email: Scalars["String"]["input"]
	password: Scalars["String"]["input"]
}>

export type LoginMutation = {
	__typename: "Mutation"
	login: {
		__typename: "User"
		id: string
		name: string
		email: string
		isAdmin: boolean
		isActive: boolean
		agencies: Array<{
			__typename: "Agency"
			id: string
			title: string
			controlNumber: string
		}>
	}
}

export type PipelineLandingQueryVariables = Exact<{
	status?: InputMaybe<Array<FilingStatus> | FilingStatus>
	agencyID?: InputMaybe<Scalars["ID"]["input"]>
	showEffectiveDate: Scalars["Boolean"]["input"]
}>

export type PipelineLandingQuery = {
	__typename: "Query"
	upcomingSubmission: {
		__typename: "Publication"
		publicationDate: Date
		submissionDeadline: Date
	}
	upcomingPublication: { __typename: "Publication"; publicationDate: Date }
	filingTypes: Array<{ __typename: "FilingType"; key: string; name: string }>
	filings: Array<
		{
			__typename: "Filing"
			id: string
			filingTypeKey: string
			intendedAction?: IntendedAction | null
			currentCodeNumber?: string | null
			agency: {
				__typename: "Agency"
				controlNumber: string
				title: string
				sortTitle?: string | null
			}
			currentSubmission: {
				__typename: "Submission"
				nextPublication: { __typename: "Publication"; id: string; publicationDate: Date }
				draft?:
					| { __typename: "ChapterDraft"; idText?: string | null }
					| { __typename: "RuleDraft"; idText?: string | null }
					| null
			}
		} & { " $fragmentRefs"?: { FilingRow_FilingFragment: FilingRow_FilingFragment } }
	>
} & {
	" $fragmentRefs"?: {
		NewDraftButton_QueryFragmentFragment: NewDraftButton_QueryFragmentFragment
	}
}

export type AgencyHome_AgencyFragmentFragment = {
	__typename: "Agency"
	controlNumber: string
	title: string
	chapters: Array<
		{ __typename: "Chapter"; idText: string; title: string } & {
			" $fragmentRefs"?: {
				AgencyHome_ChapterFragmentFragment: AgencyHome_ChapterFragmentFragment
			}
		}
	>
} & { " $fragmentName"?: "AgencyHome_AgencyFragmentFragment" }

export type AgencyHome_ChapterFragmentFragment = {
	__typename: "Chapter"
	rules: Array<{ __typename: "Rule"; idText: string; title: string }>
} & { " $fragmentName"?: "AgencyHome_ChapterFragmentFragment" }

export type ChapterHome_ChapterFragmentFragment = {
	__typename: "Chapter"
	id: string
	idText: string
	title: string
	fileUrl: string
	parent: { __typename: "Agency"; controlNumber: string; title: string }
	rules: Array<
		{ __typename: "Rule"; idText: string; title: string } & {
			" $fragmentRefs"?: {
				ChapterHome_RuleFragmentFragment: ChapterHome_RuleFragmentFragment
			}
		}
	>
	filings: Array<
		{ __typename: "Filing" } & {
			" $fragmentRefs"?: {
				FilingHistory_FilingsFragmentFragment: FilingHistory_FilingsFragmentFragment
			}
		}
	>
} & { " $fragmentName"?: "ChapterHome_ChapterFragmentFragment" }

export type ChapterHome_RuleFragmentFragment = {
	__typename: "Rule"
	description?: string | null
	author?: string | null
	authority?: string | null
	history?: string | null
	penalty?: string | null
	editorsNote?: string | null
} & { " $fragmentName"?: "ChapterHome_RuleFragmentFragment" }

export type PublicCodeQueryVariables = Exact<{
	controlNumberOrIdText: Scalars["String"]["input"]
}>

export type PublicCodeQuery = {
	__typename: "Query"
	document:
		| ({ __typename: "Agency" } & {
				" $fragmentRefs"?: {
					AgencyHome_AgencyFragmentFragment: AgencyHome_AgencyFragmentFragment
				}
		  })
		| ({ __typename: "Chapter" } & {
				" $fragmentRefs"?: {
					ChapterHome_ChapterFragmentFragment: ChapterHome_ChapterFragmentFragment
				}
		  })
		| ({ __typename: "Rule" } & {
				" $fragmentRefs"?: {
					RuleHome_RuleFragmentFragment: RuleHome_RuleFragmentFragment
				}
		  })
}

export type RuleHome_RuleFragmentFragment = {
	__typename: "Rule"
	idText: string
	title: string
	description?: string | null
	fileUrl: string
	author?: string | null
	authority?: string | null
	history?: string | null
	penalty?: string | null
	editorsNote?: string | null
	parent: {
		__typename: "Chapter"
		idText: string
		title: string
		parent: { __typename: "Agency"; controlNumber: string; title: string }
	}
	filings: Array<
		{ __typename: "Filing" } & {
			" $fragmentRefs"?: {
				FilingHistory_FilingsFragmentFragment: FilingHistory_FilingsFragmentFragment
			}
		}
	>
} & { " $fragmentName"?: "RuleHome_RuleFragmentFragment" }

export type RequestAccountQueryQueryVariables = Exact<{ [key: string]: never }>

export type RequestAccountQueryQuery = {
	__typename: "Query"
	agencies: Array<{ __typename: "Agency"; controlNumber: string; title: string }>
}

export type RequestAccountMutationVariables = Exact<{
	name: Scalars["String"]["input"]
	email: Scalars["String"]["input"]
	password: Scalars["String"]["input"]
	controlNumbers: Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
}>

export type RequestAccountMutation = {
	__typename: "Mutation"
	requestAccount: { __typename: "User" }
}

export type UserEmailForTokenQueryVariables = Exact<{
	token: Scalars["String"]["input"]
}>

export type UserEmailForTokenQuery = {
	__typename: "Query"
	userEmailForToken?: string | null
}

export type ResetPasswordMutationVariables = Exact<{
	password: Scalars["String"]["input"]
	token: Scalars["String"]["input"]
}>

export type ResetPasswordMutation = { __typename: "Mutation"; resetPassword: boolean }

export type ResourcesAndAnnouncementsQueryQueryVariables = Exact<{ [key: string]: never }>

export type ResourcesAndAnnouncementsQueryQuery = { __typename: "Query" } & {
	" $fragmentRefs"?: {
		ResourceList_QueryFragmentFragment: ResourceList_QueryFragmentFragment
		AnnouncementList_QueryFragmentFragment: AnnouncementList_QueryFragmentFragment
	}
}

export type AllAgenciesQueryQueryVariables = Exact<{ [key: string]: never }>

export type AllAgenciesQueryQuery = { __typename: "Query" } & {
	" $fragmentRefs"?: {
		AdminCodeSearch_QueryFragmentFragment: AdminCodeSearch_QueryFragmentFragment
		PublicationArchiveSearch_QueryFragmentFragment: PublicationArchiveSearch_QueryFragmentFragment
	}
}

export type StrikethroughQueryVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type StrikethroughQuery = {
	__typename: "Query"
	filing: {
		__typename: "Filing"
		name?: string | null
		status: FilingStatus
		intendedAction?: IntendedAction | null
		scope?: FilingScope | null
		agencyID: string
		currentSubmission: {
			__typename: "Submission"
			strikethroughUrl?: string | null
			strikethroughViewed: boolean
			currentCode?:
				| { __typename: "Chapter"; idText: string }
				| { __typename: "ChapterSnapshot"; idText: string }
				| { __typename: "Rule"; idText: string }
				| { __typename: "RuleSnapshot"; idText: string }
				| null
		}
	}
}

export type UpdateStrikethroughMutationVariables = Exact<{
	filingID: Scalars["ID"]["input"]
}>

export type UpdateStrikethroughMutation = {
	__typename: "Mutation"
	approveStrikethrough: { __typename: "Filing" }
}

export type UpcomingPublicationQueryVariables = Exact<{ [key: string]: never }>

export type UpcomingPublicationQuery = {
	__typename: "Query"
	upcomingSubmission: {
		__typename: "Publication"
		publicationDate: Date
		submissionDeadline: Date
	}
	upcomingPublication: {
		__typename: "Publication"
		id: string
		publicationDate: Date
		approved: boolean
		editorsNote?: string | null
		aamFilings: Array<
			{ __typename: "Filing" } & {
				" $fragmentRefs"?: {
					ViewCurrentFilings_FilingFragmentFragment: ViewCurrentFilings_FilingFragmentFragment
				}
			}
		>
		proposals: Array<
			{ __typename: "Filing" } & {
				" $fragmentRefs"?: {
					ViewCurrentFilings_FilingFragmentFragment: ViewCurrentFilings_FilingFragmentFragment
				}
			}
		>
	} & {
		" $fragmentRefs"?: {
			ViewCurrentFilings_PublicationFragmentFragment: ViewCurrentFilings_PublicationFragmentFragment
		}
	}
}

export type SetPublicationApprovedMutationVariables = Exact<{
	id: Scalars["String"]["input"]
	approved: Scalars["Boolean"]["input"]
}>

export type SetPublicationApprovedMutation = {
	__typename: "Mutation"
	setPublicationApproved: { __typename: "Publication"; approved: boolean }
}

export type UpdatePublicationEditorsNoteMutationVariables = Exact<{
	id: Scalars["String"]["input"]
	editorsNote: Scalars["String"]["input"]
}>

export type UpdatePublicationEditorsNoteMutation = {
	__typename: "Mutation"
	updatePublicationEditorsNote: { __typename: "Publication"; editorsNote?: string | null }
}

export type UserProfileQueryVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type UserProfileQuery = {
	__typename: "Query"
	user: {
		__typename: "User"
		name: string
		email: string
		isAdmin: boolean
		isActive: boolean
		subscribedToEmails: boolean
		agencies: Array<{
			__typename: "Agency"
			id: string
			controlNumber: string
			title: string
		}>
		pendingAgencies: Array<{
			__typename: "Agency"
			id: string
			controlNumber: string
			title: string
		}>
	}
} & {
	" $fragmentRefs"?: {
		UserProfile_QueryFragmentFragment: UserProfile_QueryFragmentFragment
	}
}

export type UserProfile_QueryFragmentFragment = {
	__typename: "Query"
	agencies: Array<{
		__typename: "Agency"
		id: string
		controlNumber: string
		title: string
	}>
} & { " $fragmentName"?: "UserProfile_QueryFragmentFragment" }

export type UpdateUserMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
	email?: InputMaybe<Scalars["String"]["input"]>
	isAdmin?: InputMaybe<Scalars["Boolean"]["input"]>
	isActive?: InputMaybe<Scalars["Boolean"]["input"]>
	name?: InputMaybe<Scalars["String"]["input"]>
	pendingAgencyIDs?: InputMaybe<
		Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
	>
	subscribedToEmails?: InputMaybe<Scalars["Boolean"]["input"]>
}>

export type UpdateUserMutation = {
	__typename: "Mutation"
	updateUser: {
		__typename: "User"
		id: string
		name: string
		email: string
		isAdmin: boolean
		isActive: boolean
		agencies: Array<{
			__typename: "Agency"
			id: string
			title: string
			controlNumber: string
		}>
	}
}

export type ApproveUserMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
	agencyIDs: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
}>

export type ApproveUserMutation = {
	__typename: "Mutation"
	approveUser: { __typename: "User" }
}

export type DisapproveUserMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
	agencyIDs: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
}>

export type DisapproveUserMutation = {
	__typename: "Mutation"
	disapproveUser: { __typename: "User" }
}

export type RequestAgencyMutationVariables = Exact<{
	agency: Scalars["ID"]["input"]
}>

export type RequestAgencyMutation = {
	__typename: "Mutation"
	requestAgency: { __typename: "User" }
}

export type CancelAgencyRequestMutationVariables = Exact<{
	agency: Scalars["ID"]["input"]
}>

export type CancelAgencyRequestMutation = {
	__typename: "Mutation"
	cancelAgencyRequest: { __typename: "User" }
}

export type DeleteUserMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type DeleteUserMutation = {
	__typename: "Mutation"
	permanentlyDeleteUser: { __typename: "User" }
}

export type ViewPublicationQueryVariables = Exact<{
	id: Scalars["ID"]["input"]
	type: PublicationType
}>

export type ViewPublicationQuery = {
	__typename: "Query"
	publicationOrHistoricalPublication?:
		| {
				__typename: "HistoricalPublication"
				id: string
				contents?: { __typename: "HistoricalPublicationContent"; content: string } | null
		  }
		| ({
				__typename: "Publication"
				id: string
				filings: Array<
					{ __typename: "Filing" } & {
						" $fragmentRefs"?: {
							ViewCurrentFilings_FilingFragmentFragment: ViewCurrentFilings_FilingFragmentFragment
						}
					}
				>
		  } & {
				" $fragmentRefs"?: {
					ViewCurrentFilings_PublicationFragmentFragment: ViewCurrentFilings_PublicationFragmentFragment
				}
		  })
		| null
}

export type AnnouncementsMetaAndResourcesQueryVariables = Exact<{ [key: string]: never }>

export type AnnouncementsMetaAndResourcesQuery = {
	__typename: "Query"
	announcements: Array<{ __typename: "Announcement"; id: string; title: string }>
	resources: Array<{
		__typename: "Resource"
		id: string
		title: string
		fileUrl: string
		file?: { __typename: "FileMetadata"; filename: string } | null
	}>
}

export type AnnouncementsContentsQueryVariables = Exact<{ [key: string]: never }>

export type AnnouncementsContentsQuery = {
	__typename: "Query"
	announcements: Array<{
		__typename: "Announcement"
		id: string
		description: string
		content: string
		updatedAt: Date
	}>
}

export type SortAnnouncementsMutationVariables = Exact<{
	announcements: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
}>

export type SortAnnouncementsMutation = {
	__typename: "Mutation"
	sortAnnouncements: boolean
}

export type SortResourcesMutationVariables = Exact<{
	resources: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
}>

export type SortResourcesMutation = { __typename: "Mutation"; sortResources: boolean }

export type DeleteAnnouncementMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type DeleteAnnouncementMutation = {
	__typename: "Mutation"
	permanentlyDeleteAnnouncement: { __typename: "Announcement" }
}

export type DeleteResourceMutationVariables = Exact<{
	id: Scalars["ID"]["input"]
}>

export type DeleteResourceMutation = {
	__typename: "Mutation"
	permanentlyDeleteResource: { __typename: "Resource" }
}

export type ProcessForms_FilingFragmentFragment = {
	__typename: "Filing"
	status: FilingStatus
	intendedAction?: IntendedAction | null
	currentSubmission: {
		__typename: "Submission"
		strikethroughViewed: boolean
		draft?:
			| { __typename: "ChapterDraft"; isValid: boolean }
			| { __typename: "RuleDraft"; isValid: boolean }
			| null
		forms: Array<{
			__typename: "Form"
			key: string
			dependsOnStrikethrough: boolean
			dependsOnStrikethroughCondition?: string | null
			signatureRequired: boolean
			fields: Array<{
				__typename: "Field"
				key: string
				condition?: string | null
				type: FieldType
				optional?: boolean | null
				dateSpecs?: {
					__typename: "DateSpecs"
					max?: number | null
					min?: number | null
					from?: DateReference | null
				} | null
				fileSpecs?: { __typename: "FileSpecs"; type?: string | null } | null
			}>
		}>
		formEntries: Array<{
			__typename: "FormEntry"
			formKey: string
			signatureID?: string | null
			values: Array<{
				__typename: "FormEntryValue"
				key: string
				num?: number | null
				text?: string | null
				date?: Date | null
				file?: { __typename: "FileMetadata" } | null
			}>
		}>
		nextPublication: { __typename: "Publication"; publicationDate: Date }
	}
} & { " $fragmentName"?: "ProcessForms_FilingFragmentFragment" }

export const AdminCodeSearch_QueryFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "adminCodeSearch_QueryFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Query" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "agencies" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "onlyShown" },
								value: { kind: "BooleanValue", value: true }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{ kind: "Field", name: { kind: "Name", value: "sortTitle" } },
								{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "chapters" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "__typename" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "__typename" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AdminCodeSearch_QueryFragmentFragment, unknown>
export const AnnouncementList_QueryFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "announcementList_QueryFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Query" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "announcements" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{ kind: "Field", name: { kind: "Name", value: "updatedAt" } },
								{ kind: "Field", name: { kind: "Name", value: "description" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AnnouncementList_QueryFragmentFragment, unknown>
export const ProcessForms_FilingFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "processForms_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "status" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "isValid" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "strikethroughViewed" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "forms" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "key" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "dependsOnStrikethrough" }
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "dependsOnStrikethroughCondition" }
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "signatureRequired" }
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "fields" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "key" } },
														{ kind: "Field", name: { kind: "Name", value: "condition" } },
														{ kind: "Field", name: { kind: "Name", value: "type" } },
														{ kind: "Field", name: { kind: "Name", value: "optional" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "dateSpecs" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	},
																	{ kind: "Field", name: { kind: "Name", value: "max" } },
																	{ kind: "Field", name: { kind: "Name", value: "min" } },
																	{ kind: "Field", name: { kind: "Name", value: "from" } }
																]
															}
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "fileSpecs" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	},
																	{ kind: "Field", name: { kind: "Name", value: "type" } }
																]
															}
														}
													]
												}
											}
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "formEntries" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "formKey" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "values" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "key" } },
														{ kind: "Field", name: { kind: "Name", value: "num" } },
														{ kind: "Field", name: { kind: "Name", value: "text" } },
														{ kind: "Field", name: { kind: "Name", value: "date" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "file" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	}
																]
															}
														}
													]
												}
											},
											{ kind: "Field", name: { kind: "Name", value: "signatureID" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "nextPublication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ProcessForms_FilingFragmentFragment, unknown>
export const FilingRow_FilingFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "filingRow_Filing" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{ kind: "Field", name: { kind: "Name", value: "scope" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } }
										]
									}
								}
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "agency" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "filingType" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } }
							]
						}
					},
					{ kind: "Field", name: { kind: "Name", value: "filingTypeKey" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "processForms_FilingFragment" }
					},
					{ kind: "Field", name: { kind: "Name", value: "status" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "proposal" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "latestCertification" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{ kind: "Field", name: { kind: "Name", value: "submissionDate" } },
								{ kind: "Field", name: { kind: "Name", value: "resubmissionDate" } },
								{ kind: "Field", name: { kind: "Name", value: "isProposal" } },
								{ kind: "Field", name: { kind: "Name", value: "strikethroughUrl" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "rejectionComments" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "nextPublication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "submissionDeadline" }
											}
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "formEntries" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "values" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "date" } }
													]
												}
											}
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "ChapterDraft" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "ruleDrafts" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	},
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "useManualHistoryUpdate"
																		}
																	}
																]
															}
														}
													]
												}
											},
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "RuleDraft" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "useManualHistoryUpdate" }
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "filingType" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "hasProposal" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "canBeWithdrawnOrDisapproved" }
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "requiresScopeAndIntendedAction" }
								}
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "activityLog" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "effectiveDate" },
						directives: [
							{
								kind: "Directive",
								name: { kind: "Name", value: "include" },
								arguments: [
									{
										kind: "Argument",
										name: { kind: "Name", value: "if" },
										value: {
											kind: "Variable",
											name: { kind: "Name", value: "showEffectiveDate" }
										}
									}
								]
							}
						]
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "processForms_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "status" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "isValid" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "strikethroughViewed" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "forms" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "key" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "dependsOnStrikethrough" }
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "dependsOnStrikethroughCondition" }
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "signatureRequired" }
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "fields" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "key" } },
														{ kind: "Field", name: { kind: "Name", value: "condition" } },
														{ kind: "Field", name: { kind: "Name", value: "type" } },
														{ kind: "Field", name: { kind: "Name", value: "optional" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "dateSpecs" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	},
																	{ kind: "Field", name: { kind: "Name", value: "max" } },
																	{ kind: "Field", name: { kind: "Name", value: "min" } },
																	{ kind: "Field", name: { kind: "Name", value: "from" } }
																]
															}
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "fileSpecs" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	},
																	{ kind: "Field", name: { kind: "Name", value: "type" } }
																]
															}
														}
													]
												}
											}
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "formEntries" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "formKey" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "values" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "key" } },
														{ kind: "Field", name: { kind: "Name", value: "num" } },
														{ kind: "Field", name: { kind: "Name", value: "text" } },
														{ kind: "Field", name: { kind: "Name", value: "date" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "file" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	}
																]
															}
														}
													]
												}
											},
											{ kind: "Field", name: { kind: "Name", value: "signatureID" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "nextPublication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<FilingRow_FilingFragment, unknown>
export const NewDraftButton_QueryFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "newDraftButton_QueryFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Query" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "filingTypes" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "key" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "requiresScopeAndIntendedAction" }
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<NewDraftButton_QueryFragmentFragment, unknown>
export const PublicationArchives_QueryFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "publicationArchives_QueryFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Query" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "publicationArchives" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "type" },
								value: { kind: "Variable", name: { kind: "Name", value: "type" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<PublicationArchives_QueryFragmentFragment, unknown>
export const PublicationArchiveSearch_QueryFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "publicationArchiveSearch_QueryFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Query" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "agencies" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "onlyShown" },
								value: { kind: "BooleanValue", value: true }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{ kind: "Field", name: { kind: "Name", value: "sortTitle" } },
								{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
								{ kind: "Field", name: { kind: "Name", value: "__typename" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<PublicationArchiveSearch_QueryFragmentFragment, unknown>
export const ResourceList_QueryFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "resourceList_QueryFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Query" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resources" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ResourceList_QueryFragmentFragment, unknown>
export const ViewCurrentFilings_PublicationFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewCurrentFilings_PublicationFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Publication" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "publicationDate" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsNote" } }
				]
			}
		}
	]
} as unknown as DocumentNode<ViewCurrentFilings_PublicationFragmentFragment, unknown>
export const ViewCurrentFilings_FilingFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewCurrentFilings_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "filingType" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "key" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "hasProposal" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "shouldMergeIntoCurrentCode" }
								}
							]
						}
					},
					{ kind: "Field", name: { kind: "Name", value: "scope" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "agency" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{ kind: "Field", name: { kind: "Name", value: "sortTitle" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "headingForNonstandardSectionInAam" }
								}
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "proposal" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "publication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "isProposal" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "certification" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "publication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "submissionDate" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "withdrawOrDisapprovePublicationID" }
								},
								{ kind: "Field", name: { kind: "Name", value: "withdrawDate" } },
								{ kind: "Field", name: { kind: "Name", value: "disapproveDate" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "disapproveWithSuggestionsDate" }
								},
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{
									kind: "Field",
									alias: { kind: "Name", value: "certifyingWithChanges" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "apa-3", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: {
												kind: "StringValue",
												value: "certifying-with-changes",
												block: false
											}
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								},
								{
									kind: "Field",
									alias: { kind: "Name", value: "titleFromFormEntry" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "misc-notice", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: { kind: "StringValue", value: "title", block: false }
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "isProposal" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "recertifications" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "publication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "submissionDate" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "withdrawOrDisapprovePublicationID" }
								},
								{ kind: "Field", name: { kind: "Name", value: "withdrawDate" } },
								{ kind: "Field", name: { kind: "Name", value: "disapproveDate" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "disapproveWithSuggestionsDate" }
								},
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{
									kind: "Field",
									alias: { kind: "Name", value: "certifyingWithChanges" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "apa-3", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: {
												kind: "StringValue",
												value: "certifying-with-changes",
												block: false
											}
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "isProposal" } }
							]
						}
					},
					{ kind: "Field", name: { kind: "Name", value: "effectiveDate" } },
					{ kind: "Field", name: { kind: "Name", value: "isEffective" } }
				]
			}
		}
	]
} as unknown as DocumentNode<ViewCurrentFilings_FilingFragmentFragment, unknown>
export const RuleDraftEditor_FilingFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ruleDraftEditor_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "status" } },
					{ kind: "Field", name: { kind: "Name", value: "scope" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "ChapterOrSnapshot" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "idText" } }
													]
												}
											}
										]
									}
								},
								{
									kind: "Field",
									alias: { kind: "Name", value: "certifyingWithChanges" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "apa-3", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: {
												kind: "StringValue",
												value: "certifying-with-changes",
												block: false
											}
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<RuleDraftEditor_FilingFragmentFragment, unknown>
export const StrikethroughPreview_FilingFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "strikethroughPreview_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "strikethroughUrl" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<StrikethroughPreview_FilingFragmentFragment, unknown>
export const ViewRuleDraftButton_RuleDraftFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewRuleDraftButton_RuleDraftFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "RuleDraft" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "author" } },
					{ kind: "Field", name: { kind: "Name", value: "authority" } },
					{ kind: "Field", name: { kind: "Name", value: "history" } },
					{ kind: "Field", name: { kind: "Name", value: "penalty" } }
				]
			}
		}
	]
} as unknown as DocumentNode<ViewRuleDraftButton_RuleDraftFragmentFragment, unknown>
export const RepealRuleDraftButton_RuleDraftFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "repealRuleDraftButton_RuleDraftFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "RuleDraft" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } }
				]
			}
		}
	]
} as unknown as DocumentNode<RepealRuleDraftButton_RuleDraftFragmentFragment, unknown>
export const RuleDraftEditor_RuleDraftFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ruleDraftEditor_RuleDraftFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "RuleDraft" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "repealRuleDraftButton_RuleDraftFragment" }
					},
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "author" } },
					{ kind: "Field", name: { kind: "Name", value: "authority" } },
					{ kind: "Field", name: { kind: "Name", value: "penalty" } },
					{ kind: "Field", name: { kind: "Name", value: "manualHistoryUpdate" } },
					{ kind: "Field", name: { kind: "Name", value: "useManualHistoryUpdate" } },
					{ kind: "Field", name: { kind: "Name", value: "generatedHistoryUpdate" } },
					{ kind: "Field", name: { kind: "Name", value: "pageBreakBefore" } },
					{ kind: "Field", name: { kind: "Name", value: "proposalCertification" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "parent" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "idText" } }
							]
						}
					},
					{ kind: "Field", name: { kind: "Name", value: "newRule" } },
					{ kind: "Field", name: { kind: "Name", value: "existingHistory" } }
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "repealRuleDraftButton_RuleDraftFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "RuleDraft" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } }
				]
			}
		}
	]
} as unknown as DocumentNode<RuleDraftEditor_RuleDraftFragmentFragment, unknown>
export const ChapterDraftEditor_FilingFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "chapterDraftEditor_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "ruleDraftEditor_FilingFragment" }
					},
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "strikethroughPreview_FilingFragment" }
					},
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "status" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "agency" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "chapters" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								}
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "ChapterDraft" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "id" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "agencyTitle" }
														},
														{ kind: "Field", name: { kind: "Name", value: "idText" } },
														{ kind: "Field", name: { kind: "Name", value: "title" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "ruleDrafts" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	},
																	{
																		kind: "FragmentSpread",
																		name: {
																			kind: "Name",
																			value: "viewRuleDraftButton_RuleDraftFragment"
																		}
																	},
																	{
																		kind: "FragmentSpread",
																		name: {
																			kind: "Name",
																			value: "ruleDraftEditor_RuleDraftFragment"
																		}
																	},
																	{
																		kind: "FragmentSpread",
																		name: {
																			kind: "Name",
																			value: "repealRuleDraftButton_RuleDraftFragment"
																		}
																	},
																	{ kind: "Field", name: { kind: "Name", value: "id" } },
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "idText" }
																	},
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "title" }
																	},
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "isValid" }
																	},
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "proposalCertification" }
																	}
																]
															}
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "overridePlacement" }
														},
														{ kind: "Field", name: { kind: "Name", value: "placeAt" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "placeAfterID" }
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "proposalCertification" }
														}
													]
												}
											}
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "Chapter" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "id" } },
														{ kind: "Field", name: { kind: "Name", value: "idText" } }
													]
												}
											}
										]
									}
								},
								{
									kind: "Field",
									alias: { kind: "Name", value: "certifyingWithChanges" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "apa-3", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: {
												kind: "StringValue",
												value: "certifying-with-changes",
												block: false
											}
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "repealRuleDraftButton_RuleDraftFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "RuleDraft" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } }
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ruleDraftEditor_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "status" } },
					{ kind: "Field", name: { kind: "Name", value: "scope" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "ChapterOrSnapshot" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "idText" } }
													]
												}
											}
										]
									}
								},
								{
									kind: "Field",
									alias: { kind: "Name", value: "certifyingWithChanges" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "apa-3", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: {
												kind: "StringValue",
												value: "certifying-with-changes",
												block: false
											}
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "strikethroughPreview_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "strikethroughUrl" } }
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewRuleDraftButton_RuleDraftFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "RuleDraft" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "author" } },
					{ kind: "Field", name: { kind: "Name", value: "authority" } },
					{ kind: "Field", name: { kind: "Name", value: "history" } },
					{ kind: "Field", name: { kind: "Name", value: "penalty" } }
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ruleDraftEditor_RuleDraftFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "RuleDraft" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "repealRuleDraftButton_RuleDraftFragment" }
					},
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "author" } },
					{ kind: "Field", name: { kind: "Name", value: "authority" } },
					{ kind: "Field", name: { kind: "Name", value: "penalty" } },
					{ kind: "Field", name: { kind: "Name", value: "manualHistoryUpdate" } },
					{ kind: "Field", name: { kind: "Name", value: "useManualHistoryUpdate" } },
					{ kind: "Field", name: { kind: "Name", value: "generatedHistoryUpdate" } },
					{ kind: "Field", name: { kind: "Name", value: "pageBreakBefore" } },
					{ kind: "Field", name: { kind: "Name", value: "proposalCertification" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "parent" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "idText" } }
							]
						}
					},
					{ kind: "Field", name: { kind: "Name", value: "newRule" } },
					{ kind: "Field", name: { kind: "Name", value: "existingHistory" } }
				]
			}
		}
	]
} as unknown as DocumentNode<ChapterDraftEditor_FilingFragmentFragment, unknown>
export const StandaloneRuleDraftEditor_FilingFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "standaloneRuleDraftEditor_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "strikethroughPreview_FilingFragment" }
					},
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "status" } },
					{ kind: "Field", name: { kind: "Name", value: "scope" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "RuleDraft" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{
															kind: "FragmentSpread",
															name: {
																kind: "Name",
																value: "ruleDraftEditor_RuleDraftFragment"
															}
														}
													]
												}
											}
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "ChapterOrSnapshot" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "idText" } }
													]
												}
											}
										]
									}
								},
								{
									kind: "Field",
									alias: { kind: "Name", value: "certifyingWithChanges" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "apa-3", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: {
												kind: "StringValue",
												value: "certifying-with-changes",
												block: false
											}
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "repealRuleDraftButton_RuleDraftFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "RuleDraft" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } }
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "strikethroughPreview_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "strikethroughUrl" } }
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ruleDraftEditor_RuleDraftFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "RuleDraft" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "repealRuleDraftButton_RuleDraftFragment" }
					},
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "author" } },
					{ kind: "Field", name: { kind: "Name", value: "authority" } },
					{ kind: "Field", name: { kind: "Name", value: "penalty" } },
					{ kind: "Field", name: { kind: "Name", value: "manualHistoryUpdate" } },
					{ kind: "Field", name: { kind: "Name", value: "useManualHistoryUpdate" } },
					{ kind: "Field", name: { kind: "Name", value: "generatedHistoryUpdate" } },
					{ kind: "Field", name: { kind: "Name", value: "pageBreakBefore" } },
					{ kind: "Field", name: { kind: "Name", value: "proposalCertification" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "parent" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "idText" } }
							]
						}
					},
					{ kind: "Field", name: { kind: "Name", value: "newRule" } },
					{ kind: "Field", name: { kind: "Name", value: "existingHistory" } }
				]
			}
		}
	]
} as unknown as DocumentNode<StandaloneRuleDraftEditor_FilingFragmentFragment, unknown>
export const ViewChapterButton_ChapterFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewChapterButton_ChapterFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Chapter" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
					{ kind: "Field", name: { kind: "Name", value: "showToPublic" } }
				]
			}
		}
	]
} as unknown as DocumentNode<ViewChapterButton_ChapterFragmentFragment, unknown>
export const AgencyEditor_AgencyFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "agencyEditor_AgencyFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Agency" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "sortTitle" } },
					{ kind: "Field", name: { kind: "Name", value: "shown" } },
					{ kind: "Field", name: { kind: "Name", value: "showToPublic" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "chapters" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "viewChapterButton_ChapterFragment" }
								},
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "idText" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{ kind: "Field", name: { kind: "Name", value: "shown" } }
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewChapterButton_ChapterFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Chapter" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
					{ kind: "Field", name: { kind: "Name", value: "showToPublic" } }
				]
			}
		}
	]
} as unknown as DocumentNode<AgencyEditor_AgencyFragmentFragment, unknown>
export const AgencyEditor_QueryFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "agencyEditor_QueryFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Query" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "agencies" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AgencyEditor_QueryFragmentFragment, unknown>
export const ViewRuleButton_DeferredRuleFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewRuleButton_DeferredRuleFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rule" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "author" } },
					{ kind: "Field", name: { kind: "Name", value: "authority" } },
					{ kind: "Field", name: { kind: "Name", value: "history" } },
					{ kind: "Field", name: { kind: "Name", value: "penalty" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsNote" } }
				]
			}
		}
	]
} as unknown as DocumentNode<ViewRuleButton_DeferredRuleFragmentFragment, unknown>
export const ViewRuleButton_RuleFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewRuleButton_RuleFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rule" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "viewRuleButton_DeferredRuleFragment" }
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewRuleButton_DeferredRuleFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rule" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "author" } },
					{ kind: "Field", name: { kind: "Name", value: "authority" } },
					{ kind: "Field", name: { kind: "Name", value: "history" } },
					{ kind: "Field", name: { kind: "Name", value: "penalty" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsNote" } }
				]
			}
		}
	]
} as unknown as DocumentNode<ViewRuleButton_RuleFragmentFragment, unknown>
export const RuleEditor_RuleFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ruleEditor_RuleFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rule" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "viewRuleButton_RuleFragment" }
					},
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "parent" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "idText" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "parent" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								}
							]
						}
					},
					{ kind: "Field", name: { kind: "Name", value: "author" } },
					{ kind: "Field", name: { kind: "Name", value: "authority" } },
					{ kind: "Field", name: { kind: "Name", value: "history" } },
					{ kind: "Field", name: { kind: "Name", value: "penalty" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsNote" } },
					{ kind: "Field", name: { kind: "Name", value: "shown" } },
					{ kind: "Field", name: { kind: "Name", value: "pageBreakBefore" } }
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewRuleButton_DeferredRuleFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rule" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "author" } },
					{ kind: "Field", name: { kind: "Name", value: "authority" } },
					{ kind: "Field", name: { kind: "Name", value: "history" } },
					{ kind: "Field", name: { kind: "Name", value: "penalty" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsNote" } }
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewRuleButton_RuleFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rule" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "viewRuleButton_DeferredRuleFragment" }
					}
				]
			}
		}
	]
} as unknown as DocumentNode<RuleEditor_RuleFragmentFragment, unknown>
export const ChapterEditor_ChapterFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "chapterEditor_ChapterFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Chapter" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "viewChapterButton_ChapterFragment" }
					},
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "parent" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } }
							]
						}
					},
					{ kind: "Field", name: { kind: "Name", value: "agencyTitle" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsNote" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsEndnote" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "header" } },
					{ kind: "Field", name: { kind: "Name", value: "footer" } },
					{ kind: "Field", name: { kind: "Name", value: "shown" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "rules" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "viewRuleButton_RuleFragment" }
								},
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "ruleEditor_RuleFragment" }
								},
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "idText" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } }
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewRuleButton_DeferredRuleFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rule" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "author" } },
					{ kind: "Field", name: { kind: "Name", value: "authority" } },
					{ kind: "Field", name: { kind: "Name", value: "history" } },
					{ kind: "Field", name: { kind: "Name", value: "penalty" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsNote" } }
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewRuleButton_RuleFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rule" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "viewRuleButton_DeferredRuleFragment" }
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewChapterButton_ChapterFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Chapter" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
					{ kind: "Field", name: { kind: "Name", value: "showToPublic" } }
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ruleEditor_RuleFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rule" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "viewRuleButton_RuleFragment" }
					},
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "parent" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "idText" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "parent" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								}
							]
						}
					},
					{ kind: "Field", name: { kind: "Name", value: "author" } },
					{ kind: "Field", name: { kind: "Name", value: "authority" } },
					{ kind: "Field", name: { kind: "Name", value: "history" } },
					{ kind: "Field", name: { kind: "Name", value: "penalty" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsNote" } },
					{ kind: "Field", name: { kind: "Name", value: "shown" } },
					{ kind: "Field", name: { kind: "Name", value: "pageBreakBefore" } }
				]
			}
		}
	]
} as unknown as DocumentNode<ChapterEditor_ChapterFragmentFragment, unknown>
export const DraftRow_FilingFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "draftRow_Filing" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "scope" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{ kind: "Field", name: { kind: "Name", value: "status" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "isValid" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "strikethroughViewed" } },
								{
									kind: "Field",
									alias: { kind: "Name", value: "certifyingWithChanges" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "apa-3", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: {
												kind: "StringValue",
												value: "certifying-with-changes",
												block: false
											}
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<DraftRow_FilingFragment, unknown>
export const FormRow_FilingFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "formRow_Filing" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "processForms_FilingFragment" }
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "nextPublication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "processForms_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "status" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "isValid" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "strikethroughViewed" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "forms" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "key" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "dependsOnStrikethrough" }
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "dependsOnStrikethroughCondition" }
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "signatureRequired" }
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "fields" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "key" } },
														{ kind: "Field", name: { kind: "Name", value: "condition" } },
														{ kind: "Field", name: { kind: "Name", value: "type" } },
														{ kind: "Field", name: { kind: "Name", value: "optional" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "dateSpecs" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	},
																	{ kind: "Field", name: { kind: "Name", value: "max" } },
																	{ kind: "Field", name: { kind: "Name", value: "min" } },
																	{ kind: "Field", name: { kind: "Name", value: "from" } }
																]
															}
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "fileSpecs" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	},
																	{ kind: "Field", name: { kind: "Name", value: "type" } }
																]
															}
														}
													]
												}
											}
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "formEntries" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "formKey" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "values" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "key" } },
														{ kind: "Field", name: { kind: "Name", value: "num" } },
														{ kind: "Field", name: { kind: "Name", value: "text" } },
														{ kind: "Field", name: { kind: "Name", value: "date" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "file" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	}
																]
															}
														}
													]
												}
											},
											{ kind: "Field", name: { kind: "Name", value: "signatureID" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "nextPublication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<FormRow_FilingFragment, unknown>
export const FormRow_FormFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "formRow_Form" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Form" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "key" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "fields" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "key" } },
								{ kind: "Field", name: { kind: "Name", value: "text" } },
								{ kind: "Field", name: { kind: "Name", value: "helperText" } },
								{ kind: "Field", name: { kind: "Name", value: "type" } },
								{ kind: "Field", name: { kind: "Name", value: "condition" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "options" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } },
											{ kind: "Field", name: { kind: "Name", value: "value" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "dateSpecs" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "default" } },
											{ kind: "Field", name: { kind: "Name", value: "from" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "textSpecs" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "default" } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<FormRow_FormFragment, unknown>
export const FormRow_FormEntryFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "formRow_FormEntry" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "FormEntry" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "values" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "key" } },
								{ kind: "Field", name: { kind: "Name", value: "num" } },
								{ kind: "Field", name: { kind: "Name", value: "text" } },
								{ kind: "Field", name: { kind: "Name", value: "date" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "file" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "filename" } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<FormRow_FormEntryFragment, unknown>
export const AgencyHome_ChapterFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "agencyHome_ChapterFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Chapter" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "rules" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "onlyShown" },
								value: { kind: "BooleanValue", value: true }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "idText" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AgencyHome_ChapterFragmentFragment, unknown>
export const AgencyHome_AgencyFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "agencyHome_AgencyFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Agency" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "chapters" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "onlyShown" },
								value: { kind: "BooleanValue", value: true }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "idText" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "agencyHome_ChapterFragment" }
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "agencyHome_ChapterFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Chapter" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "rules" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "onlyShown" },
								value: { kind: "BooleanValue", value: true }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "idText" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AgencyHome_AgencyFragmentFragment, unknown>
export const ChapterHome_RuleFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "chapterHome_RuleFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rule" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "author" } },
					{ kind: "Field", name: { kind: "Name", value: "authority" } },
					{ kind: "Field", name: { kind: "Name", value: "history" } },
					{ kind: "Field", name: { kind: "Name", value: "penalty" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsNote" } }
				]
			}
		}
	]
} as unknown as DocumentNode<ChapterHome_RuleFragmentFragment, unknown>
export const FilingHistory_FilingsFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "filingHistory_FilingsFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "scope" } },
					{ kind: "Field", name: { kind: "Name", value: "filingTypeKey" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{ kind: "Field", name: { kind: "Name", value: "currentCodeNumber" } },
					{ kind: "Field", name: { kind: "Name", value: "effectiveDate" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "proposal" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "latestCertification" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<FilingHistory_FilingsFragmentFragment, unknown>
export const ChapterHome_ChapterFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "chapterHome_ChapterFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Chapter" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "parent" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "rules" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "onlyShown" },
								value: { kind: "BooleanValue", value: true }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "idText" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "chapterHome_RuleFragment" }
								}
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "filings" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "filingHistory_FilingsFragment" }
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "chapterHome_RuleFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rule" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "author" } },
					{ kind: "Field", name: { kind: "Name", value: "authority" } },
					{ kind: "Field", name: { kind: "Name", value: "history" } },
					{ kind: "Field", name: { kind: "Name", value: "penalty" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsNote" } }
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "filingHistory_FilingsFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "scope" } },
					{ kind: "Field", name: { kind: "Name", value: "filingTypeKey" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{ kind: "Field", name: { kind: "Name", value: "currentCodeNumber" } },
					{ kind: "Field", name: { kind: "Name", value: "effectiveDate" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "proposal" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "latestCertification" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ChapterHome_ChapterFragmentFragment, unknown>
export const RuleHome_RuleFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ruleHome_RuleFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rule" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
					{ kind: "Field", name: { kind: "Name", value: "author" } },
					{ kind: "Field", name: { kind: "Name", value: "authority" } },
					{ kind: "Field", name: { kind: "Name", value: "history" } },
					{ kind: "Field", name: { kind: "Name", value: "penalty" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsNote" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "parent" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "idText" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "parent" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								}
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "filings" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "filingHistory_FilingsFragment" }
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "filingHistory_FilingsFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "scope" } },
					{ kind: "Field", name: { kind: "Name", value: "filingTypeKey" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{ kind: "Field", name: { kind: "Name", value: "currentCodeNumber" } },
					{ kind: "Field", name: { kind: "Name", value: "effectiveDate" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "proposal" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "latestCertification" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<RuleHome_RuleFragmentFragment, unknown>
export const UserProfile_QueryFragmentFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "userProfile_QueryFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Query" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "agencies" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<UserProfile_QueryFragmentFragment, unknown>
export const SearchDocument = {
	__meta__: { hash: "f4780b4c69bdcd6223eabd470839c75cb1df1ddf" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "search" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "query" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "agencyControlNumbers" }
					},
					type: {
						kind: "ListType",
						type: {
							kind: "NonNullType",
							type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
						}
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "chapterIdTexts" } },
					type: {
						kind: "ListType",
						type: {
							kind: "NonNullType",
							type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
						}
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "skip" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "search" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "query" },
								value: { kind: "Variable", name: { kind: "Name", value: "query" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "agencyControlNumbers" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "agencyControlNumbers" }
								}
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "chapterIdTexts" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "chapterIdTexts" }
								}
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "skip" },
								value: { kind: "Variable", name: { kind: "Name", value: "skip" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "limit" },
								value: { kind: "Variable", name: { kind: "Name", value: "limit" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "items" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "ChapterResultItem" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "idText" } },
														{ kind: "Field", name: { kind: "Name", value: "parentID" } }
													]
												}
											},
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "RuleResultItem" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "idText" } },
														{ kind: "Field", name: { kind: "Name", value: "parentID" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "description" }
														},
														{ kind: "Field", name: { kind: "Name", value: "author" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "editorsNote" }
														}
													]
												}
											},
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "AgencyResultItem" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "controlNumber" }
														}
													]
												}
											},
											{ kind: "Field", name: { kind: "Name", value: "__typename" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "totalCount" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<SearchQuery, SearchQueryVariables>
export const DeleteFilingDocument = {
	__meta__: { hash: "2702ae4344bf6ac54f2afc44829a4caa6e64364f" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "deleteFiling" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "permanentlyDeleteFiling" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<DeleteFilingMutation, DeleteFilingMutationVariables>
export const SubmitFilingDocument = {
	__meta__: { hash: "07db2f892417ce02fad7e84914a8b307c3bc532a" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "submitFiling" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "submitFiling" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "status" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<SubmitFilingMutation, SubmitFilingMutationVariables>
export const RecallFilingDocument = {
	__meta__: { hash: "60536237a0edb1140256be04d18f925dc90b4001" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "recallFiling" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "recallFiling" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "status" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<RecallFilingMutation, RecallFilingMutationVariables>
export const ApproveFilingDocument = {
	__meta__: { hash: "49424f084d98b24cd85ad3b7700a8345e04fc0b6" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "approveFiling" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "approveFiling" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ApproveFilingMutation, ApproveFilingMutationVariables>
export const WithdrawOrDisapproveFilingDocument = {
	__meta__: { hash: "c0cfd14c18fab6e4b171288c09c8aea2a86e51de" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "withdrawOrDisapproveFiling" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "action" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "FilingUserAction" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "withdrawOrDisapproveFiling" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "action" },
								value: { kind: "Variable", name: { kind: "Name", value: "action" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "status" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<
	WithdrawOrDisapproveFilingMutation,
	WithdrawOrDisapproveFilingMutationVariables
>
export const RejectFilingDocument = {
	__meta__: { hash: "fbd7f33101e8ea98f664567757376c668cb8b5b6" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "rejectFiling" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "comment" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "rejectFiling" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "comment" },
								value: { kind: "Variable", name: { kind: "Name", value: "comment" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<RejectFilingMutation, RejectFilingMutationVariables>
export const LogoutDocument = {
	__meta__: { hash: "3f77f2a2b29ed2f88142963fceece94b4cd7c59d" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "logout" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "logout" } }
				]
			}
		}
	]
} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>
export const AddAgencyDocument = {
	__meta__: { hash: "1b209aa87f295b9770c22b27ebaf9cef50f52b08" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "addAgency" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "controlNumber" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "title" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "addAgency" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "controlNumber" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "controlNumber" }
								}
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "title" },
								value: { kind: "Variable", name: { kind: "Name", value: "title" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AddAgencyMutation, AddAgencyMutationVariables>
export const AddAnnouncementDocument = {
	__meta__: { hash: "fd03f73e361d4f4ecc3ad2a4f57496858b886e5b" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "addAnnouncement" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "title" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "description" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "content" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "addAnnouncement" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "title" },
								value: { kind: "Variable", name: { kind: "Name", value: "title" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "description" },
								value: { kind: "Variable", name: { kind: "Name", value: "description" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "content" },
								value: { kind: "Variable", name: { kind: "Name", value: "content" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AddAnnouncementMutation, AddAnnouncementMutationVariables>
export const AddChapterDocument = {
	__meta__: { hash: "f06d3da367ece46026124577db35467d7d4b26e7" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "addChapter" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "parentID" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "idText" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "title" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "addChapter" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "parentID" },
								value: { kind: "Variable", name: { kind: "Name", value: "parentID" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "idText" },
								value: { kind: "Variable", name: { kind: "Name", value: "idText" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "title" },
								value: { kind: "Variable", name: { kind: "Name", value: "title" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AddChapterMutation, AddChapterMutationVariables>
export const AddDraftFilingDocument = {
	__meta__: { hash: "3e531d344d2a8fba2e3e3d9039de960020089b0b" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "addDraftFiling" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "agencyID" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "filingTypeKey" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "scope" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "FilingScope" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "intendedAction" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "IntendedAction" } }
				},
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "currentCodeNumber" }
					},
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "addFiling" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "agencyID" },
								value: { kind: "Variable", name: { kind: "Name", value: "agencyID" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "filingTypeKey" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "filingTypeKey" }
								}
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "intendedAction" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "intendedAction" }
								}
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "name" },
								value: { kind: "Variable", name: { kind: "Name", value: "name" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "scope" },
								value: { kind: "Variable", name: { kind: "Name", value: "scope" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "currentCodeNumber" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "currentCodeNumber" }
								}
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AddDraftFilingMutation, AddDraftFilingMutationVariables>
export const NewDraftButtonDocument = {
	__meta__: { hash: "91fbd0ece4c06c5ed2c1c5e39fe7899b4267caec" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "newDraftButton" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "agencyID" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "needsChapter" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "needsRules" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "agency" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "agencyID" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "chapters" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "Chapter" }
												},
												directives: [
													{
														kind: "Directive",
														name: { kind: "Name", value: "include" },
														arguments: [
															{
																kind: "Argument",
																name: { kind: "Name", value: "if" },
																value: {
																	kind: "Variable",
																	name: { kind: "Name", value: "needsChapter" }
																}
															}
														]
													}
												],
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "id" } },
														{ kind: "Field", name: { kind: "Name", value: "idText" } },
														{ kind: "Field", name: { kind: "Name", value: "title" } }
													]
												}
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "rules" },
												directives: [
													{
														kind: "Directive",
														name: { kind: "Name", value: "include" },
														arguments: [
															{
																kind: "Argument",
																name: { kind: "Name", value: "if" },
																value: {
																	kind: "Variable",
																	name: { kind: "Name", value: "needsRules" }
																}
															}
														]
													}
												],
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "id" } },
														{ kind: "Field", name: { kind: "Name", value: "idText" } },
														{ kind: "Field", name: { kind: "Name", value: "title" } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<NewDraftButtonQuery, NewDraftButtonQueryVariables>
export const AddResourceDocument = {
	__meta__: { hash: "c145f21848cac5e3c8d5242c15ee3a087e266cee" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "addResource" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "title" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "file" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "File" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "addResource" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "title" },
								value: { kind: "Variable", name: { kind: "Name", value: "title" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "file" },
								value: { kind: "Variable", name: { kind: "Name", value: "file" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AddResourceMutation, AddResourceMutationVariables>
export const AddRuleDocument = {
	__meta__: { hash: "f63f4a34732ede08a0067d939591a082373068dd" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "addRule" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "parentID" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "idText" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "title" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "addRule" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "parentID" },
								value: { kind: "Variable", name: { kind: "Name", value: "parentID" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "idText" },
								value: { kind: "Variable", name: { kind: "Name", value: "idText" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "title" },
								value: { kind: "Variable", name: { kind: "Name", value: "title" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AddRuleMutation, AddRuleMutationVariables>
export const AddRuleDraftDocument = {
	__meta__: { hash: "c363c6312eb9ea8646077aba57cb6b9d92101ba7" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "addRuleDraft" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "parentID" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "idText" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "title" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "addRuleDraft" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "parentID" },
								value: { kind: "Variable", name: { kind: "Name", value: "parentID" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "idText" },
								value: { kind: "Variable", name: { kind: "Name", value: "idText" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "title" },
								value: { kind: "Variable", name: { kind: "Name", value: "title" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AddRuleDraftMutation, AddRuleDraftMutationVariables>
export const SearchFilingsDocument = {
	__meta__: { hash: "c6eda95ec226b9f2070e913aefdd57b934aea5e5" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "searchFilings" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "query" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "controlNumbers" } },
					type: {
						kind: "ListType",
						type: {
							kind: "NonNullType",
							type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
						}
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "years" } },
					type: {
						kind: "ListType",
						type: {
							kind: "NonNullType",
							type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
						}
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "skip" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "Int" } }
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "searchFilings" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "query" },
								value: { kind: "Variable", name: { kind: "Name", value: "query" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "controlNumbers" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "controlNumbers" }
								}
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "years" },
								value: { kind: "Variable", name: { kind: "Name", value: "years" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "skip" },
								value: { kind: "Variable", name: { kind: "Name", value: "skip" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "limit" },
								value: { kind: "Variable", name: { kind: "Name", value: "limit" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "items" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "content" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } },
											{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "HistoricalFilingResultItem" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "publicationType" }
														}
													]
												}
											},
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "FilingResultItem" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "isProposal" }
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "filingType" }
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "withdrawDate" }
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "disapproveDate" }
														},
														{
															kind: "Field",
															name: {
																kind: "Name",
																value: "disapproveWithSuggestionsDate"
															}
														}
													]
												}
											}
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "totalCount" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<SearchFilingsQuery, SearchFilingsQueryVariables>
export const RepealRuleDraftDocument = {
	__meta__: { hash: "4dfd64d79e866588d4f398e95735ea0605533cb5" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "repealRuleDraft" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "title" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "description" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "updateRuleDraft" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "title" },
								value: { kind: "Variable", name: { kind: "Name", value: "title" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "description" },
								value: { kind: "Variable", name: { kind: "Name", value: "description" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "useManualHistoryUpdate" },
								value: { kind: "BooleanValue", value: false }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<RepealRuleDraftMutation, RepealRuleDraftMutationVariables>
export const SignFormEntryDocument = {
	__meta__: { hash: "5fe1bc13d936895415d9a9f20ac8177a04633de0" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "signFormEntry" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "filingID" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "formKey" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "value" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "screenshot" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "File" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "signFormEntry" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "filingID" },
								value: { kind: "Variable", name: { kind: "Name", value: "filingID" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "formKey" },
								value: { kind: "Variable", name: { kind: "Name", value: "formKey" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "value" },
								value: { kind: "Variable", name: { kind: "Name", value: "value" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "screenshot" },
								value: { kind: "Variable", name: { kind: "Name", value: "screenshot" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<SignFormEntryMutation, SignFormEntryMutationVariables>
export const UpdateAnnouncementDocument = {
	__meta__: { hash: "9970c5302f8716fdd1e75ab30c52775d8ea873c8" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "updateAnnouncement" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "title" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "description" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "content" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "updateAnnouncement" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "title" },
								value: { kind: "Variable", name: { kind: "Name", value: "title" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "description" },
								value: { kind: "Variable", name: { kind: "Name", value: "description" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "content" },
								value: { kind: "Variable", name: { kind: "Name", value: "content" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<
	UpdateAnnouncementMutation,
	UpdateAnnouncementMutationVariables
>
export const UpdateResourceDocument = {
	__meta__: { hash: "5e44c060ac86ede2b538e39ff54036a465c23a9e" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "updateResource" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "title" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "file" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "File" } }
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "updateResource" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "title" },
								value: { kind: "Variable", name: { kind: "Name", value: "title" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "file" },
								value: { kind: "Variable", name: { kind: "Name", value: "file" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<UpdateResourceMutation, UpdateResourceMutationVariables>
export const UsersDocument = {
	__meta__: { hash: "63227044da9df03cd236700c0b69515b50196c96" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "users" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "users" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "email" } },
								{ kind: "Field", name: { kind: "Name", value: "isAdmin" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "pendingAgencies" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<UsersQuery, UsersQueryVariables>
export const UserDocument = {
	__meta__: { hash: "560700c00aa57d59d539f1f30db1a8e16502c338" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "user" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "user" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "email" } },
								{ kind: "Field", name: { kind: "Name", value: "isAdmin" } },
								{ kind: "Field", name: { kind: "Name", value: "isActive" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "agencies" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "controlNumber" } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<UserQuery, UserQueryVariables>
export const AgencySortTitlesDocument = {
	__meta__: { hash: "5b007459a4ae47d83594920d3ce815b6ec1d32a3" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "agencySortTitles" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "agencies" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "onlyShown" },
								value: { kind: "BooleanValue", value: true }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "sortTitle" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
								{ kind: "Field", name: { kind: "Name", value: "shown" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AgencySortTitlesQuery, AgencySortTitlesQueryVariables>
export const AnnouncementByTitleDocument = {
	__meta__: { hash: "95fb4eb02bb2fc9b116f007c4596d6908d4d0723" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "announcementByTitle" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "title" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "announcement" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "title" },
								value: { kind: "Variable", name: { kind: "Name", value: "title" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "description" } },
								{ kind: "Field", name: { kind: "Name", value: "content" } },
								{ kind: "Field", name: { kind: "Name", value: "updatedAt" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AnnouncementByTitleQuery, AnnouncementByTitleQueryVariables>
export const UpdateChapterDraftDocument = {
	__meta__: { hash: "14ce10ff87bc0bc9f9afb4d5fabdf81e5e07c10f" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "updateChapterDraft" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "agencyTitle" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "idText" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "title" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "ruleDrafts" } },
					type: {
						kind: "ListType",
						type: {
							kind: "NonNullType",
							type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
						}
					}
				},
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "ruleDraftsToSave" }
					},
					type: {
						kind: "ListType",
						type: {
							kind: "NonNullType",
							type: {
								kind: "NamedType",
								name: { kind: "Name", value: "RuleDraftToSave" }
							}
						}
					}
				},
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "overridePlacement" }
					},
					type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "placeAt" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "Placement" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "placeAfterID" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "filingID" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "updateChapterDraft" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "agencyTitle" },
								value: { kind: "Variable", name: { kind: "Name", value: "agencyTitle" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "idText" },
								value: { kind: "Variable", name: { kind: "Name", value: "idText" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "title" },
								value: { kind: "Variable", name: { kind: "Name", value: "title" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "ruleDrafts" },
								value: { kind: "Variable", name: { kind: "Name", value: "ruleDrafts" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "ruleDraftsToSave" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "ruleDraftsToSave" }
								}
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "overridePlacement" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "overridePlacement" }
								}
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "placeAt" },
								value: { kind: "Variable", name: { kind: "Name", value: "placeAt" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "placeAfterID" },
								value: { kind: "Variable", name: { kind: "Name", value: "placeAfterID" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "approveStrikethrough" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "filingID" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "viewed" },
								value: { kind: "BooleanValue", value: false }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<
	UpdateChapterDraftMutation,
	UpdateChapterDraftMutationVariables
>
export const DeleteRuleDraftDocument = {
	__meta__: { hash: "405d5d6137731593420d6eb4b4d0bef095549424" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "deleteRuleDraft" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "permanentlyDeleteRuleDraft" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<DeleteRuleDraftMutation, DeleteRuleDraftMutationVariables>
export const ResetChapterDraftDocument = {
	__meta__: { hash: "174d100d13eb8de1433a0a2bb46a7c46c2419462" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "resetChapterDraft" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resetChapterDraft" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<
	ResetChapterDraftMutation,
	ResetChapterDraftMutationVariables
>
export const DraftForEditorDocument = {
	__meta__: { hash: "1b8769f8bbbd33b4d9182c951a664f00780dfc01" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "draftForEditor" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "filing" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "chapterDraftEditor_FilingFragment" }
								},
								{
									kind: "FragmentSpread",
									name: {
										kind: "Name",
										value: "standaloneRuleDraftEditor_FilingFragment"
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
								{ kind: "Field", name: { kind: "Name", value: "scope" } },
								{ kind: "Field", name: { kind: "Name", value: "agencyID" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "currentSubmission" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "draft" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{ kind: "Field", name: { kind: "Name", value: "__typename" } }
													]
												}
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "currentCode" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "idText" } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ruleDraftEditor_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "status" } },
					{ kind: "Field", name: { kind: "Name", value: "scope" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "ChapterOrSnapshot" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "idText" } }
													]
												}
											}
										]
									}
								},
								{
									kind: "Field",
									alias: { kind: "Name", value: "certifyingWithChanges" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "apa-3", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: {
												kind: "StringValue",
												value: "certifying-with-changes",
												block: false
											}
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "strikethroughPreview_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "strikethroughUrl" } }
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewRuleDraftButton_RuleDraftFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "RuleDraft" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "author" } },
					{ kind: "Field", name: { kind: "Name", value: "authority" } },
					{ kind: "Field", name: { kind: "Name", value: "history" } },
					{ kind: "Field", name: { kind: "Name", value: "penalty" } }
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "repealRuleDraftButton_RuleDraftFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "RuleDraft" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } }
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ruleDraftEditor_RuleDraftFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "RuleDraft" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "repealRuleDraftButton_RuleDraftFragment" }
					},
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "author" } },
					{ kind: "Field", name: { kind: "Name", value: "authority" } },
					{ kind: "Field", name: { kind: "Name", value: "penalty" } },
					{ kind: "Field", name: { kind: "Name", value: "manualHistoryUpdate" } },
					{ kind: "Field", name: { kind: "Name", value: "useManualHistoryUpdate" } },
					{ kind: "Field", name: { kind: "Name", value: "generatedHistoryUpdate" } },
					{ kind: "Field", name: { kind: "Name", value: "pageBreakBefore" } },
					{ kind: "Field", name: { kind: "Name", value: "proposalCertification" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "parent" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "idText" } }
							]
						}
					},
					{ kind: "Field", name: { kind: "Name", value: "newRule" } },
					{ kind: "Field", name: { kind: "Name", value: "existingHistory" } }
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "chapterDraftEditor_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "ruleDraftEditor_FilingFragment" }
					},
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "strikethroughPreview_FilingFragment" }
					},
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "status" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "agency" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "chapters" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								}
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "ChapterDraft" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "id" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "agencyTitle" }
														},
														{ kind: "Field", name: { kind: "Name", value: "idText" } },
														{ kind: "Field", name: { kind: "Name", value: "title" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "ruleDrafts" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	},
																	{
																		kind: "FragmentSpread",
																		name: {
																			kind: "Name",
																			value: "viewRuleDraftButton_RuleDraftFragment"
																		}
																	},
																	{
																		kind: "FragmentSpread",
																		name: {
																			kind: "Name",
																			value: "ruleDraftEditor_RuleDraftFragment"
																		}
																	},
																	{
																		kind: "FragmentSpread",
																		name: {
																			kind: "Name",
																			value: "repealRuleDraftButton_RuleDraftFragment"
																		}
																	},
																	{ kind: "Field", name: { kind: "Name", value: "id" } },
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "idText" }
																	},
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "title" }
																	},
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "isValid" }
																	},
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "proposalCertification" }
																	}
																]
															}
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "overridePlacement" }
														},
														{ kind: "Field", name: { kind: "Name", value: "placeAt" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "placeAfterID" }
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "proposalCertification" }
														}
													]
												}
											}
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "Chapter" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "id" } },
														{ kind: "Field", name: { kind: "Name", value: "idText" } }
													]
												}
											}
										]
									}
								},
								{
									kind: "Field",
									alias: { kind: "Name", value: "certifyingWithChanges" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "apa-3", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: {
												kind: "StringValue",
												value: "certifying-with-changes",
												block: false
											}
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "standaloneRuleDraftEditor_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "strikethroughPreview_FilingFragment" }
					},
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "status" } },
					{ kind: "Field", name: { kind: "Name", value: "scope" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "RuleDraft" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{
															kind: "FragmentSpread",
															name: {
																kind: "Name",
																value: "ruleDraftEditor_RuleDraftFragment"
															}
														}
													]
												}
											}
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "ChapterOrSnapshot" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "idText" } }
													]
												}
											}
										]
									}
								},
								{
									kind: "Field",
									alias: { kind: "Name", value: "certifyingWithChanges" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "apa-3", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: {
												kind: "StringValue",
												value: "certifying-with-changes",
												block: false
											}
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<DraftForEditorQuery, DraftForEditorQueryVariables>
export const UpdateRuleDraftDocument = {
	__meta__: { hash: "dccafbede67eab51d34df02dde9556aa78ada343" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "updateRuleDraft" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "idText" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "title" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "description" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "author" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "authority" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "manualHistoryUpdate" }
					},
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "useManualHistoryUpdate" }
					},
					type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "penalty" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "pageBreakBefore" }
					},
					type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "filingID" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "updateRuleDraft" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "idText" },
								value: { kind: "Variable", name: { kind: "Name", value: "idText" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "title" },
								value: { kind: "Variable", name: { kind: "Name", value: "title" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "description" },
								value: { kind: "Variable", name: { kind: "Name", value: "description" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "author" },
								value: { kind: "Variable", name: { kind: "Name", value: "author" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "authority" },
								value: { kind: "Variable", name: { kind: "Name", value: "authority" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "manualHistoryUpdate" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "manualHistoryUpdate" }
								}
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "useManualHistoryUpdate" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "useManualHistoryUpdate" }
								}
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "penalty" },
								value: { kind: "Variable", name: { kind: "Name", value: "penalty" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "pageBreakBefore" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "pageBreakBefore" }
								}
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "approveStrikethrough" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "filingID" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "viewed" },
								value: { kind: "BooleanValue", value: false }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<UpdateRuleDraftMutation, UpdateRuleDraftMutationVariables>
export const ResetRuleDraftDocument = {
	__meta__: { hash: "2d048601c3e765c370c456d50c776e1d020168e5" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "resetRuleDraft" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resetRuleDraft" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ResetRuleDraftMutation, ResetRuleDraftMutationVariables>
export const ESignerDocument = {
	__meta__: { hash: "7aab833883b2ea3007396d0889ad074d2a50d415" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "eSigner" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "filingID" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "filing" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "filingID" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "processForms_FilingFragment" }
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "agency" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "scope" } },
								{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
								{ kind: "Field", name: { kind: "Name", value: "scope" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "currentSubmission" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "currentCode" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "idText" } }
													]
												}
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "forms" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "key" } },
														{ kind: "Field", name: { kind: "Name", value: "name" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "signatureRequired" }
														}
													]
												}
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "formEntries" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "formKey" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "signatureID" }
														},
														{ kind: "Field", name: { kind: "Name", value: "fileUrl" } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "processForms_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "status" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "isValid" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "strikethroughViewed" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "forms" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "key" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "dependsOnStrikethrough" }
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "dependsOnStrikethroughCondition" }
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "signatureRequired" }
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "fields" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "key" } },
														{ kind: "Field", name: { kind: "Name", value: "condition" } },
														{ kind: "Field", name: { kind: "Name", value: "type" } },
														{ kind: "Field", name: { kind: "Name", value: "optional" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "dateSpecs" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	},
																	{ kind: "Field", name: { kind: "Name", value: "max" } },
																	{ kind: "Field", name: { kind: "Name", value: "min" } },
																	{ kind: "Field", name: { kind: "Name", value: "from" } }
																]
															}
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "fileSpecs" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	},
																	{ kind: "Field", name: { kind: "Name", value: "type" } }
																]
															}
														}
													]
												}
											}
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "formEntries" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "formKey" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "values" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "key" } },
														{ kind: "Field", name: { kind: "Name", value: "num" } },
														{ kind: "Field", name: { kind: "Name", value: "text" } },
														{ kind: "Field", name: { kind: "Name", value: "date" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "file" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	}
																]
															}
														}
													]
												}
											},
											{ kind: "Field", name: { kind: "Name", value: "signatureID" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "nextPublication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ESignerQuery, ESignerQueryVariables>
export const UpdateAgencyDocument = {
	__meta__: { hash: "6c9a04d882481ddfe5e0f283454110aa92784490" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "updateAgency" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "title" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "shown" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "chapters" } },
					type: {
						kind: "ListType",
						type: {
							kind: "NonNullType",
							type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
						}
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "sortTitle" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "updateAgency" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "title" },
								value: { kind: "Variable", name: { kind: "Name", value: "title" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "shown" },
								value: { kind: "Variable", name: { kind: "Name", value: "shown" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "chapters" },
								value: { kind: "Variable", name: { kind: "Name", value: "chapters" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "sortTitle" },
								value: { kind: "Variable", name: { kind: "Name", value: "sortTitle" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<UpdateAgencyMutation, UpdateAgencyMutationVariables>
export const DeleteChapterDocument = {
	__meta__: { hash: "1dd59e6ac1bb5931362b25e9f5c7f4301658b38f" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "deleteChapter" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "permanentlyDeleteChapter" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<DeleteChapterMutation, DeleteChapterMutationVariables>
export const UpdateChapterDocument = {
	__meta__: { hash: "68b83e3239f926a7e8eb9c9cd69a26677ca9d49f" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "updateChapter" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "agencyTitle" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "editorsNote" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "editorsEndnote" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "idText" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "title" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "header" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "footer" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "shown" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "rules" } },
					type: {
						kind: "ListType",
						type: {
							kind: "NonNullType",
							type: {
								kind: "NamedType",
								name: { kind: "Name", value: "ChapterEditorRule" }
							}
						}
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "updateChapter" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "agencyTitle" },
								value: { kind: "Variable", name: { kind: "Name", value: "agencyTitle" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "editorsNote" },
								value: { kind: "Variable", name: { kind: "Name", value: "editorsNote" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "editorsEndnote" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "editorsEndnote" }
								}
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "idText" },
								value: { kind: "Variable", name: { kind: "Name", value: "idText" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "title" },
								value: { kind: "Variable", name: { kind: "Name", value: "title" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "header" },
								value: { kind: "Variable", name: { kind: "Name", value: "header" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "footer" },
								value: { kind: "Variable", name: { kind: "Name", value: "footer" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "shown" },
								value: { kind: "Variable", name: { kind: "Name", value: "shown" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "rules" },
								value: { kind: "Variable", name: { kind: "Name", value: "rules" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<UpdateChapterMutation, UpdateChapterMutationVariables>
export const DeleteRuleDocument = {
	__meta__: { hash: "d5b4bc648ccd2bb6bf9c850eea71bf69532ca86e" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "deleteRule" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "permanentlyDeleteRule" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<DeleteRuleMutation, DeleteRuleMutationVariables>
export const EditorSelector_QueryDocument = {
	__meta__: { hash: "3d2ab29bd876d34c1ffe35c435e8c56f9f6fce91" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "editorSelector_Query" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "controlNumberOrIdText" }
					},
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "skipDocument" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "agencyEditor_QueryFragment" }
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "document" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "controlNumberOrIdText" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "controlNumberOrIdText" }
								}
							}
						],
						directives: [
							{
								kind: "Directive",
								name: { kind: "Name", value: "skip" },
								arguments: [
									{
										kind: "Argument",
										name: { kind: "Name", value: "if" },
										value: {
											kind: "Variable",
											name: { kind: "Name", value: "skipDocument" }
										}
									}
								]
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "InlineFragment",
									typeCondition: {
										kind: "NamedType",
										name: { kind: "Name", value: "Agency" }
									},
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "agencyEditor_AgencyFragment" }
											}
										]
									}
								},
								{
									kind: "InlineFragment",
									typeCondition: {
										kind: "NamedType",
										name: { kind: "Name", value: "Chapter" }
									},
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "chapterEditor_ChapterFragment" }
											}
										]
									}
								},
								{
									kind: "InlineFragment",
									typeCondition: {
										kind: "NamedType",
										name: { kind: "Name", value: "Rule" }
									},
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "ruleEditor_RuleFragment" }
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewChapterButton_ChapterFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Chapter" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
					{ kind: "Field", name: { kind: "Name", value: "showToPublic" } }
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewRuleButton_DeferredRuleFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rule" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "author" } },
					{ kind: "Field", name: { kind: "Name", value: "authority" } },
					{ kind: "Field", name: { kind: "Name", value: "history" } },
					{ kind: "Field", name: { kind: "Name", value: "penalty" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsNote" } }
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewRuleButton_RuleFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rule" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "viewRuleButton_DeferredRuleFragment" }
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ruleEditor_RuleFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rule" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "viewRuleButton_RuleFragment" }
					},
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "parent" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "idText" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "parent" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								}
							]
						}
					},
					{ kind: "Field", name: { kind: "Name", value: "author" } },
					{ kind: "Field", name: { kind: "Name", value: "authority" } },
					{ kind: "Field", name: { kind: "Name", value: "history" } },
					{ kind: "Field", name: { kind: "Name", value: "penalty" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsNote" } },
					{ kind: "Field", name: { kind: "Name", value: "shown" } },
					{ kind: "Field", name: { kind: "Name", value: "pageBreakBefore" } }
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "agencyEditor_QueryFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Query" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "agencies" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } }
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "agencyEditor_AgencyFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Agency" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "sortTitle" } },
					{ kind: "Field", name: { kind: "Name", value: "shown" } },
					{ kind: "Field", name: { kind: "Name", value: "showToPublic" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "chapters" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "viewChapterButton_ChapterFragment" }
								},
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "idText" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{ kind: "Field", name: { kind: "Name", value: "shown" } }
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "chapterEditor_ChapterFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Chapter" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "viewChapterButton_ChapterFragment" }
					},
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "parent" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } }
							]
						}
					},
					{ kind: "Field", name: { kind: "Name", value: "agencyTitle" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsNote" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsEndnote" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "header" } },
					{ kind: "Field", name: { kind: "Name", value: "footer" } },
					{ kind: "Field", name: { kind: "Name", value: "shown" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "rules" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "viewRuleButton_RuleFragment" }
								},
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "ruleEditor_RuleFragment" }
								},
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "idText" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<
	EditorSelector_QueryQuery,
	EditorSelector_QueryQueryVariables
>
export const UpdateRuleDocument = {
	__meta__: { hash: "74693c272205fca19ac8cb4ebe5a3961938c6fed" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "updateRule" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "idText" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "title" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "description" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "author" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "authority" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "history" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "penalty" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "editorsNote" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "shown" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
				},
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "pageBreakBefore" }
					},
					type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "updateRule" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "idText" },
								value: { kind: "Variable", name: { kind: "Name", value: "idText" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "title" },
								value: { kind: "Variable", name: { kind: "Name", value: "title" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "description" },
								value: { kind: "Variable", name: { kind: "Name", value: "description" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "author" },
								value: { kind: "Variable", name: { kind: "Name", value: "author" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "authority" },
								value: { kind: "Variable", name: { kind: "Name", value: "authority" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "history" },
								value: { kind: "Variable", name: { kind: "Name", value: "history" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "penalty" },
								value: { kind: "Variable", name: { kind: "Name", value: "penalty" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "editorsNote" },
								value: { kind: "Variable", name: { kind: "Name", value: "editorsNote" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "shown" },
								value: { kind: "Variable", name: { kind: "Name", value: "shown" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "pageBreakBefore" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "pageBreakBefore" }
								}
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<UpdateRuleMutation, UpdateRuleMutationVariables>
export const ActivityLogDocument = {
	__meta__: { hash: "95b19ce0c9ffd8eec7ef9acbb85de59540245021" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "activityLog" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "filing" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "status" } },
								{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
								{ kind: "Field", name: { kind: "Name", value: "scope" } },
								{ kind: "Field", name: { kind: "Name", value: "agencyID" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "activityLog" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "date" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "user" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "name" } },
														{ kind: "Field", name: { kind: "Name", value: "email" } }
													]
												}
											},
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "WithDiffLogEntry" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "wasReset" } }
													]
												}
											},
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "ChapterDraftUpdatedLogEntry" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "chapterUpdated" }
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "updatedRules" }
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "reorderedRules" }
														},
														{ kind: "Field", name: { kind: "Name", value: "addedRule" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "deletedRule" }
														}
													]
												}
											},
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "WithFileLogEntry" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "fileUrl" } }
													]
												}
											},
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "FormChangedLogEntry" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "form" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	},
																	{ kind: "Field", name: { kind: "Name", value: "name" } }
																]
															}
														}
													]
												}
											},
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "FormUpdatedLogEntry" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "wasNew" } }
													]
												}
											}
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "currentSubmission" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "currentCode" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "idText" } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ActivityLogQuery, ActivityLogQueryVariables>
export const FilingActivityLogDetailsModalDocument = {
	__meta__: { hash: "cf598e3721f825a578cb08ea7a8a0b431ab85736" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "FilingActivityLogDetailsModal" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "filingActivityLogEntry" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "InlineFragment",
									typeCondition: {
										kind: "NamedType",
										name: { kind: "Name", value: "WithDiffLogEntry" }
									},
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "diff" } }
										]
									}
								},
								{
									kind: "InlineFragment",
									typeCondition: {
										kind: "NamedType",
										name: { kind: "Name", value: "WithFileLogEntry" }
									},
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "fileUrl" } }
										]
									}
								},
								{
									kind: "InlineFragment",
									typeCondition: {
										kind: "NamedType",
										name: { kind: "Name", value: "FormChangedLogEntry" }
									},
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "form" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "name" } }
													]
												}
											}
										]
									}
								},
								{
									kind: "InlineFragment",
									typeCondition: {
										kind: "NamedType",
										name: { kind: "Name", value: "FilingRejectedLogEntry" }
									},
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "comments" } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<
	FilingActivityLogDetailsModalQuery,
	FilingActivityLogDetailsModalQueryVariables
>
export const FilingChecklistDocument = {
	__meta__: { hash: "bfe6ec21effc19fc6b4d5b05cfb2fadefe14248b" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "filingChecklist" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "filing" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "formRow_Filing" }
								},
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "draftRow_Filing" }
								},
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "processForms_FilingFragment" }
								},
								{ kind: "Field", name: { kind: "Name", value: "status" } },
								{ kind: "Field", name: { kind: "Name", value: "agencyID" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
								{ kind: "Field", name: { kind: "Name", value: "scope" } },
								{ kind: "Field", name: { kind: "Name", value: "status" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "filingType" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "hasProposal" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "currentSubmission" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "isProposal" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "strikethroughUrl" }
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "currentCode" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "idText" } }
													]
												}
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "forms" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "key" } },
														{ kind: "Field", name: { kind: "Name", value: "condition" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "signatureRequired" }
														},
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "formRow_Form" }
														}
													]
												}
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "formEntries" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "formKey" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "signatureID" }
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "values" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	},
																	{ kind: "Field", name: { kind: "Name", value: "key" } },
																	{ kind: "Field", name: { kind: "Name", value: "text" } }
																]
															}
														},
														{
															kind: "FragmentSpread",
															name: { kind: "Name", value: "formRow_FormEntry" }
														}
													]
												}
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "rejectionComments" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "comment" } },
														{ kind: "Field", name: { kind: "Name", value: "createdAt" } }
													]
												}
											},
											{ kind: "Field", name: { kind: "Name", value: "fileUrl" } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "processForms_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "status" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "isValid" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "strikethroughViewed" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "forms" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "key" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "dependsOnStrikethrough" }
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "dependsOnStrikethroughCondition" }
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "signatureRequired" }
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "fields" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "key" } },
														{ kind: "Field", name: { kind: "Name", value: "condition" } },
														{ kind: "Field", name: { kind: "Name", value: "type" } },
														{ kind: "Field", name: { kind: "Name", value: "optional" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "dateSpecs" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	},
																	{ kind: "Field", name: { kind: "Name", value: "max" } },
																	{ kind: "Field", name: { kind: "Name", value: "min" } },
																	{ kind: "Field", name: { kind: "Name", value: "from" } }
																]
															}
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "fileSpecs" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	},
																	{ kind: "Field", name: { kind: "Name", value: "type" } }
																]
															}
														}
													]
												}
											}
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "formEntries" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "formKey" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "values" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "key" } },
														{ kind: "Field", name: { kind: "Name", value: "num" } },
														{ kind: "Field", name: { kind: "Name", value: "text" } },
														{ kind: "Field", name: { kind: "Name", value: "date" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "file" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	}
																]
															}
														}
													]
												}
											},
											{ kind: "Field", name: { kind: "Name", value: "signatureID" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "nextPublication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "formRow_Filing" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "processForms_FilingFragment" }
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "nextPublication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "draftRow_Filing" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "scope" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{ kind: "Field", name: { kind: "Name", value: "status" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "isValid" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "strikethroughViewed" } },
								{
									kind: "Field",
									alias: { kind: "Name", value: "certifyingWithChanges" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "apa-3", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: {
												kind: "StringValue",
												value: "certifying-with-changes",
												block: false
											}
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "formRow_Form" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Form" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "key" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "fields" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "key" } },
								{ kind: "Field", name: { kind: "Name", value: "text" } },
								{ kind: "Field", name: { kind: "Name", value: "helperText" } },
								{ kind: "Field", name: { kind: "Name", value: "type" } },
								{ kind: "Field", name: { kind: "Name", value: "condition" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "options" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } },
											{ kind: "Field", name: { kind: "Name", value: "value" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "dateSpecs" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "default" } },
											{ kind: "Field", name: { kind: "Name", value: "from" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "textSpecs" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "default" } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "formRow_FormEntry" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "FormEntry" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "values" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "key" } },
								{ kind: "Field", name: { kind: "Name", value: "num" } },
								{ kind: "Field", name: { kind: "Name", value: "text" } },
								{ kind: "Field", name: { kind: "Name", value: "date" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "file" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "filename" } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<FilingChecklistQuery, FilingChecklistQueryVariables>
export const UpsertFormEntryDocument = {
	__meta__: { hash: "9c28eb38801a21723f0dc78ffba6f502559ca83e" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "upsertFormEntry" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "formKey" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "filingID" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "values" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "ListType",
							type: {
								kind: "NonNullType",
								type: {
									kind: "NamedType",
									name: { kind: "Name", value: "FormEntryValueInput" }
								}
							}
						}
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "upsertFormEntry" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "formKey" },
								value: { kind: "Variable", name: { kind: "Name", value: "formKey" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "filingID" },
								value: { kind: "Variable", name: { kind: "Name", value: "filingID" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "values" },
								value: { kind: "Variable", name: { kind: "Name", value: "values" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<UpsertFormEntryMutation, UpsertFormEntryMutationVariables>
export const FilingsViewerDocument = {
	__meta__: { hash: "fb8a3923e12604c7f9bc3bed7af39876acf045d4" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "filingsViewer" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "PublicationType" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "filingTypeKey" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "fromPublication" }
					},
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "publication" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "currentPublication" },
								value: { kind: "BooleanValue", value: true }
							}
						],
						directives: [
							{
								kind: "Directive",
								name: { kind: "Name", value: "include" },
								arguments: [
									{
										kind: "Argument",
										name: { kind: "Name", value: "if" },
										value: {
											kind: "Variable",
											name: { kind: "Name", value: "fromPublication" }
										}
									}
								]
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "viewCurrentFilings_PublicationFragment" }
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "filings" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "type" },
											value: { kind: "Variable", name: { kind: "Name", value: "type" } }
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "viewCurrentFilings_FilingFragment" }
											}
										]
									}
								}
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "filings" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "filingTypeKey" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "filingTypeKey" }
								}
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "status" },
								value: {
									kind: "ListValue",
									values: [
										{ kind: "EnumValue", value: "certificationEffectiveNotPublished" },
										{ kind: "EnumValue", value: "certificationPublishedAndEffective" }
									]
								}
							}
						],
						directives: [
							{
								kind: "Directive",
								name: { kind: "Name", value: "skip" },
								arguments: [
									{
										kind: "Argument",
										name: { kind: "Name", value: "if" },
										value: {
											kind: "Variable",
											name: { kind: "Name", value: "fromPublication" }
										}
									}
								]
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "viewCurrentFilings_FilingFragment" }
								}
							]
						}
					},
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "publicationArchives_QueryFragment" }
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewCurrentFilings_PublicationFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Publication" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "publicationDate" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsNote" } }
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewCurrentFilings_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "filingType" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "key" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "hasProposal" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "shouldMergeIntoCurrentCode" }
								}
							]
						}
					},
					{ kind: "Field", name: { kind: "Name", value: "scope" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "agency" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{ kind: "Field", name: { kind: "Name", value: "sortTitle" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "headingForNonstandardSectionInAam" }
								}
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "proposal" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "publication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "isProposal" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "certification" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "publication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "submissionDate" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "withdrawOrDisapprovePublicationID" }
								},
								{ kind: "Field", name: { kind: "Name", value: "withdrawDate" } },
								{ kind: "Field", name: { kind: "Name", value: "disapproveDate" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "disapproveWithSuggestionsDate" }
								},
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{
									kind: "Field",
									alias: { kind: "Name", value: "certifyingWithChanges" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "apa-3", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: {
												kind: "StringValue",
												value: "certifying-with-changes",
												block: false
											}
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								},
								{
									kind: "Field",
									alias: { kind: "Name", value: "titleFromFormEntry" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "misc-notice", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: { kind: "StringValue", value: "title", block: false }
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "isProposal" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "recertifications" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "publication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "submissionDate" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "withdrawOrDisapprovePublicationID" }
								},
								{ kind: "Field", name: { kind: "Name", value: "withdrawDate" } },
								{ kind: "Field", name: { kind: "Name", value: "disapproveDate" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "disapproveWithSuggestionsDate" }
								},
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{
									kind: "Field",
									alias: { kind: "Name", value: "certifyingWithChanges" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "apa-3", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: {
												kind: "StringValue",
												value: "certifying-with-changes",
												block: false
											}
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "isProposal" } }
							]
						}
					},
					{ kind: "Field", name: { kind: "Name", value: "effectiveDate" } },
					{ kind: "Field", name: { kind: "Name", value: "isEffective" } }
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "publicationArchives_QueryFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Query" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "publicationArchives" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "type" },
								value: { kind: "Variable", name: { kind: "Name", value: "type" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<FilingsViewerQuery, FilingsViewerQueryVariables>
export const SendPasswordResetDocument = {
	__meta__: { hash: "301a4f82690ec6438a1edbdea04329017c5a302f" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "sendPasswordReset" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "sendPasswordResetLink" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "email" },
								value: { kind: "Variable", name: { kind: "Name", value: "email" } }
							}
						]
					}
				]
			}
		}
	]
} as unknown as DocumentNode<
	SendPasswordResetMutation,
	SendPasswordResetMutationVariables
>
export const LoginDocument = {
	__meta__: { hash: "0ffc54a84d2cc7c7e4f537714816275d8571e72f" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "login" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "password" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "login" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "email" },
								value: { kind: "Variable", name: { kind: "Name", value: "email" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "password" },
								value: { kind: "Variable", name: { kind: "Name", value: "password" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "email" } },
								{ kind: "Field", name: { kind: "Name", value: "isAdmin" } },
								{ kind: "Field", name: { kind: "Name", value: "isActive" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "agencies" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "controlNumber" } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>
export const PipelineLandingDocument = {
	__meta__: { hash: "691b50f701c4a3376834aa859e74ef55892f4abc" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "pipelineLanding" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "status" } },
					type: {
						kind: "ListType",
						type: {
							kind: "NonNullType",
							type: { kind: "NamedType", name: { kind: "Name", value: "FilingStatus" } }
						}
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "agencyID" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
				},
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "showEffectiveDate" }
					},
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "newDraftButton_QueryFragment" }
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "upcomingSubmission" },
						name: { kind: "Name", value: "publication" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "upcomingSubmission" },
								value: { kind: "BooleanValue", value: true }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "publicationDate" } },
								{ kind: "Field", name: { kind: "Name", value: "submissionDeadline" } }
							]
						}
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "upcomingPublication" },
						name: { kind: "Name", value: "publication" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "upcomingPublication" },
								value: { kind: "BooleanValue", value: true }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "filingTypes" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "key" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "filings" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "agencyID" },
								value: { kind: "Variable", name: { kind: "Name", value: "agencyID" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "status" },
								value: { kind: "Variable", name: { kind: "Name", value: "status" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "filingRow_Filing" }
								},
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "filingTypeKey" } },
								{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "agency" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "sortTitle" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "currentCodeNumber" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "currentSubmission" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "nextPublication" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "id" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "publicationDate" }
														}
													]
												}
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "draft" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "idText" } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "processForms_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "status" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "isValid" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "strikethroughViewed" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "forms" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "key" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "dependsOnStrikethrough" }
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "dependsOnStrikethroughCondition" }
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "signatureRequired" }
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "fields" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "key" } },
														{ kind: "Field", name: { kind: "Name", value: "condition" } },
														{ kind: "Field", name: { kind: "Name", value: "type" } },
														{ kind: "Field", name: { kind: "Name", value: "optional" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "dateSpecs" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	},
																	{ kind: "Field", name: { kind: "Name", value: "max" } },
																	{ kind: "Field", name: { kind: "Name", value: "min" } },
																	{ kind: "Field", name: { kind: "Name", value: "from" } }
																]
															}
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "fileSpecs" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	},
																	{ kind: "Field", name: { kind: "Name", value: "type" } }
																]
															}
														}
													]
												}
											}
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "formEntries" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "formKey" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "values" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "key" } },
														{ kind: "Field", name: { kind: "Name", value: "num" } },
														{ kind: "Field", name: { kind: "Name", value: "text" } },
														{ kind: "Field", name: { kind: "Name", value: "date" } },
														{
															kind: "Field",
															name: { kind: "Name", value: "file" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	}
																]
															}
														}
													]
												}
											},
											{ kind: "Field", name: { kind: "Name", value: "signatureID" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "nextPublication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "newDraftButton_QueryFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Query" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "filingTypes" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "key" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "requiresScopeAndIntendedAction" }
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "filingRow_Filing" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{ kind: "Field", name: { kind: "Name", value: "scope" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } }
										]
									}
								}
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "agency" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "filingType" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } }
							]
						}
					},
					{ kind: "Field", name: { kind: "Name", value: "filingTypeKey" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "processForms_FilingFragment" }
					},
					{ kind: "Field", name: { kind: "Name", value: "status" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "proposal" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "latestCertification" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "currentSubmission" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{ kind: "Field", name: { kind: "Name", value: "submissionDate" } },
								{ kind: "Field", name: { kind: "Name", value: "resubmissionDate" } },
								{ kind: "Field", name: { kind: "Name", value: "isProposal" } },
								{ kind: "Field", name: { kind: "Name", value: "strikethroughUrl" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "rejectionComments" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "nextPublication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "submissionDeadline" }
											}
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "formEntries" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "values" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "date" } }
													]
												}
											}
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "ChapterDraft" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "ruleDrafts" },
															selectionSet: {
																kind: "SelectionSet",
																selections: [
																	{
																		kind: "Field",
																		name: { kind: "Name", value: "__typename" }
																	},
																	{
																		kind: "Field",
																		name: {
																			kind: "Name",
																			value: "useManualHistoryUpdate"
																		}
																	}
																]
															}
														}
													]
												}
											},
											{
												kind: "InlineFragment",
												typeCondition: {
													kind: "NamedType",
													name: { kind: "Name", value: "RuleDraft" }
												},
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "useManualHistoryUpdate" }
														}
													]
												}
											}
										]
									}
								}
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "filingType" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "hasProposal" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "canBeWithdrawnOrDisapproved" }
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "requiresScopeAndIntendedAction" }
								}
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "activityLog" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "effectiveDate" },
						directives: [
							{
								kind: "Directive",
								name: { kind: "Name", value: "include" },
								arguments: [
									{
										kind: "Argument",
										name: { kind: "Name", value: "if" },
										value: {
											kind: "Variable",
											name: { kind: "Name", value: "showEffectiveDate" }
										}
									}
								]
							}
						]
					}
				]
			}
		}
	]
} as unknown as DocumentNode<PipelineLandingQuery, PipelineLandingQueryVariables>
export const PublicCodeDocument = {
	__meta__: { hash: "78b4f6af31b6ff7993922092d29e5b2697b5c67b" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "publicCode" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "controlNumberOrIdText" }
					},
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "document" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "controlNumberOrIdText" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "controlNumberOrIdText" }
								}
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "onlyShown" },
								value: { kind: "BooleanValue", value: true }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "InlineFragment",
									typeCondition: {
										kind: "NamedType",
										name: { kind: "Name", value: "Agency" }
									},
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "agencyHome_AgencyFragment" }
											}
										]
									}
								},
								{
									kind: "InlineFragment",
									typeCondition: {
										kind: "NamedType",
										name: { kind: "Name", value: "Chapter" }
									},
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "chapterHome_ChapterFragment" }
											}
										]
									}
								},
								{
									kind: "InlineFragment",
									typeCondition: {
										kind: "NamedType",
										name: { kind: "Name", value: "Rule" }
									},
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "ruleHome_RuleFragment" }
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "agencyHome_ChapterFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Chapter" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "rules" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "onlyShown" },
								value: { kind: "BooleanValue", value: true }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "idText" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } }
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "chapterHome_RuleFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rule" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "author" } },
					{ kind: "Field", name: { kind: "Name", value: "authority" } },
					{ kind: "Field", name: { kind: "Name", value: "history" } },
					{ kind: "Field", name: { kind: "Name", value: "penalty" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsNote" } }
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "filingHistory_FilingsFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "scope" } },
					{ kind: "Field", name: { kind: "Name", value: "filingTypeKey" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{ kind: "Field", name: { kind: "Name", value: "currentCodeNumber" } },
					{ kind: "Field", name: { kind: "Name", value: "effectiveDate" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "proposal" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "latestCertification" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } }
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "agencyHome_AgencyFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Agency" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "chapters" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "onlyShown" },
								value: { kind: "BooleanValue", value: true }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "idText" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "agencyHome_ChapterFragment" }
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "chapterHome_ChapterFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Chapter" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "parent" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "rules" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "onlyShown" },
								value: { kind: "BooleanValue", value: true }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "idText" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "chapterHome_RuleFragment" }
								}
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "filings" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "filingHistory_FilingsFragment" }
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "ruleHome_RuleFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rule" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "idText" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
					{ kind: "Field", name: { kind: "Name", value: "author" } },
					{ kind: "Field", name: { kind: "Name", value: "authority" } },
					{ kind: "Field", name: { kind: "Name", value: "history" } },
					{ kind: "Field", name: { kind: "Name", value: "penalty" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsNote" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "parent" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "idText" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "parent" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								}
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "filings" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "filingHistory_FilingsFragment" }
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<PublicCodeQuery, PublicCodeQueryVariables>
export const RequestAccountQueryDocument = {
	__meta__: { hash: "18bcda3d670943144a8b1cbaa2129f963eecd1a5" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "requestAccountQuery" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "agencies" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<RequestAccountQueryQuery, RequestAccountQueryQueryVariables>
export const RequestAccountDocument = {
	__meta__: { hash: "60c5fd6bdcc73a31ba8e3c9d559102454fed1855" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "requestAccount" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "password" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "controlNumbers" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "ListType",
							type: {
								kind: "NonNullType",
								type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
							}
						}
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "requestAccount" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "name" },
								value: { kind: "Variable", name: { kind: "Name", value: "name" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "email" },
								value: { kind: "Variable", name: { kind: "Name", value: "email" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "password" },
								value: { kind: "Variable", name: { kind: "Name", value: "password" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "agencyControlNumbers" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "controlNumbers" }
								}
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<RequestAccountMutation, RequestAccountMutationVariables>
export const UserEmailForTokenDocument = {
	__meta__: { hash: "1370f9a47981b7afb000145e716fea9cc655f67c" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "userEmailForToken" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "token" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "userEmailForToken" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "token" },
								value: { kind: "Variable", name: { kind: "Name", value: "token" } }
							}
						]
					}
				]
			}
		}
	]
} as unknown as DocumentNode<UserEmailForTokenQuery, UserEmailForTokenQueryVariables>
export const ResetPasswordDocument = {
	__meta__: { hash: "a02843da9589989fd3efcd9e57013232e9ef13a1" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "resetPassword" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "password" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "token" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resetPassword" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "password" },
								value: { kind: "Variable", name: { kind: "Name", value: "password" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "token" },
								value: { kind: "Variable", name: { kind: "Name", value: "token" } }
							}
						]
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ResetPasswordMutation, ResetPasswordMutationVariables>
export const ResourcesAndAnnouncementsQueryDocument = {
	__meta__: { hash: "431b96f65a74a377c201c7abcb2bd3586edfe463" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "resourcesAndAnnouncementsQuery" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "resourceList_QueryFragment" }
					},
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "announcementList_QueryFragment" }
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "resourceList_QueryFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Query" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "resources" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } }
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "announcementList_QueryFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Query" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "announcements" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{ kind: "Field", name: { kind: "Name", value: "updatedAt" } },
								{ kind: "Field", name: { kind: "Name", value: "description" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<
	ResourcesAndAnnouncementsQueryQuery,
	ResourcesAndAnnouncementsQueryQueryVariables
>
export const AllAgenciesQueryDocument = {
	__meta__: { hash: "0fd0f38195398f5b2551398f50244c0db1141397" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "allAgenciesQuery" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "adminCodeSearch_QueryFragment" }
					},
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "publicationArchiveSearch_QueryFragment" }
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "adminCodeSearch_QueryFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Query" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "agencies" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "onlyShown" },
								value: { kind: "BooleanValue", value: true }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{ kind: "Field", name: { kind: "Name", value: "sortTitle" } },
								{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "chapters" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "__typename" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "__typename" } }
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "publicationArchiveSearch_QueryFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Query" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "agencies" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "onlyShown" },
								value: { kind: "BooleanValue", value: true }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{ kind: "Field", name: { kind: "Name", value: "sortTitle" } },
								{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
								{ kind: "Field", name: { kind: "Name", value: "__typename" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<AllAgenciesQueryQuery, AllAgenciesQueryQueryVariables>
export const StrikethroughDocument = {
	__meta__: { hash: "8aa8e8037091a966f2c4774b84dc0e3c1b021814" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "strikethrough" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "filing" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "status" } },
								{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
								{ kind: "Field", name: { kind: "Name", value: "scope" } },
								{ kind: "Field", name: { kind: "Name", value: "agencyID" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "currentSubmission" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "currentCode" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "idText" } }
													]
												}
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "strikethroughUrl" }
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "strikethroughViewed" }
											}
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<StrikethroughQuery, StrikethroughQueryVariables>
export const UpdateStrikethroughDocument = {
	__meta__: { hash: "1f5ce8f544b839d5f9fea152b6d1b3520b62279b" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "updateStrikethrough" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "filingID" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "approveStrikethrough" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "filingID" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "viewed" },
								value: { kind: "BooleanValue", value: true }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<
	UpdateStrikethroughMutation,
	UpdateStrikethroughMutationVariables
>
export const UpcomingPublicationDocument = {
	__meta__: { hash: "dd7cf32c9145de2f663608061bba19dd43bd1e48" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "upcomingPublication" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						alias: { kind: "Name", value: "upcomingSubmission" },
						name: { kind: "Name", value: "publication" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "upcomingSubmission" },
								value: { kind: "BooleanValue", value: true }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "publicationDate" } },
								{ kind: "Field", name: { kind: "Name", value: "submissionDeadline" } }
							]
						}
					},
					{
						kind: "Field",
						alias: { kind: "Name", value: "upcomingPublication" },
						name: { kind: "Name", value: "publication" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "upcomingPublication" },
								value: { kind: "BooleanValue", value: true }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "publicationDate" } },
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "viewCurrentFilings_PublicationFragment" }
								},
								{
									kind: "Field",
									alias: { kind: "Name", value: "aamFilings" },
									name: { kind: "Name", value: "filings" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "type" },
											value: { kind: "EnumValue", value: "aam" }
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "viewCurrentFilings_FilingFragment" }
											}
										]
									}
								},
								{
									kind: "Field",
									alias: { kind: "Name", value: "proposals" },
									name: { kind: "Name", value: "filings" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "type" },
											value: { kind: "EnumValue", value: "proposal" }
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "viewCurrentFilings_FilingFragment" }
											}
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "approved" } },
								{ kind: "Field", name: { kind: "Name", value: "editorsNote" } }
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewCurrentFilings_PublicationFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Publication" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "publicationDate" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsNote" } }
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewCurrentFilings_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "filingType" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "key" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "hasProposal" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "shouldMergeIntoCurrentCode" }
								}
							]
						}
					},
					{ kind: "Field", name: { kind: "Name", value: "scope" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "agency" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{ kind: "Field", name: { kind: "Name", value: "sortTitle" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "headingForNonstandardSectionInAam" }
								}
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "proposal" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "publication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "isProposal" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "certification" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "publication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "submissionDate" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "withdrawOrDisapprovePublicationID" }
								},
								{ kind: "Field", name: { kind: "Name", value: "withdrawDate" } },
								{ kind: "Field", name: { kind: "Name", value: "disapproveDate" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "disapproveWithSuggestionsDate" }
								},
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{
									kind: "Field",
									alias: { kind: "Name", value: "certifyingWithChanges" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "apa-3", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: {
												kind: "StringValue",
												value: "certifying-with-changes",
												block: false
											}
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								},
								{
									kind: "Field",
									alias: { kind: "Name", value: "titleFromFormEntry" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "misc-notice", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: { kind: "StringValue", value: "title", block: false }
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "isProposal" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "recertifications" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "publication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "submissionDate" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "withdrawOrDisapprovePublicationID" }
								},
								{ kind: "Field", name: { kind: "Name", value: "withdrawDate" } },
								{ kind: "Field", name: { kind: "Name", value: "disapproveDate" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "disapproveWithSuggestionsDate" }
								},
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{
									kind: "Field",
									alias: { kind: "Name", value: "certifyingWithChanges" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "apa-3", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: {
												kind: "StringValue",
												value: "certifying-with-changes",
												block: false
											}
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "isProposal" } }
							]
						}
					},
					{ kind: "Field", name: { kind: "Name", value: "effectiveDate" } },
					{ kind: "Field", name: { kind: "Name", value: "isEffective" } }
				]
			}
		}
	]
} as unknown as DocumentNode<UpcomingPublicationQuery, UpcomingPublicationQueryVariables>
export const SetPublicationApprovedDocument = {
	__meta__: { hash: "17ebfeb74dddcae8cdff53fceee9b512df25f38f" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "setPublicationApproved" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "approved" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "setPublicationApproved" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "approved" },
								value: { kind: "Variable", name: { kind: "Name", value: "approved" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "approved" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<
	SetPublicationApprovedMutation,
	SetPublicationApprovedMutationVariables
>
export const UpdatePublicationEditorsNoteDocument = {
	__meta__: { hash: "98718fd6fdb0ba59719da1044865006add26fa89" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "updatePublicationEditorsNote" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "editorsNote" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "updatePublicationEditorsNote" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "editorsNote" },
								value: { kind: "Variable", name: { kind: "Name", value: "editorsNote" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "editorsNote" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<
	UpdatePublicationEditorsNoteMutation,
	UpdatePublicationEditorsNoteMutationVariables
>
export const UserProfileDocument = {
	__meta__: { hash: "e0609379f09b2fb6821d27e325922c15197307b3" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "userProfile" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "FragmentSpread",
						name: { kind: "Name", value: "userProfile_QueryFragment" }
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "user" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "email" } },
								{ kind: "Field", name: { kind: "Name", value: "isAdmin" } },
								{ kind: "Field", name: { kind: "Name", value: "isActive" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "agencies" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "pendingAgencies" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "subscribedToEmails" } }
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "userProfile_QueryFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Query" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "agencies" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "controlNumber" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<UserProfileQuery, UserProfileQueryVariables>
export const UpdateUserDocument = {
	__meta__: { hash: "0cc5d8061fa1b23e9b7151c612d96dae18afd6a2" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "updateUser" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "isAdmin" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "isActive" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
				},
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "pendingAgencyIDs" }
					},
					type: {
						kind: "ListType",
						type: {
							kind: "NonNullType",
							type: { kind: "NamedType", name: { kind: "Name", value: "String" } }
						}
					}
				},
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "subscribedToEmails" }
					},
					type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } }
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "updateUser" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "email" },
								value: { kind: "Variable", name: { kind: "Name", value: "email" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "isAdmin" },
								value: { kind: "Variable", name: { kind: "Name", value: "isAdmin" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "isActive" },
								value: { kind: "Variable", name: { kind: "Name", value: "isActive" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "name" },
								value: { kind: "Variable", name: { kind: "Name", value: "name" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "pendingAgencyIDs" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "pendingAgencyIDs" }
								}
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "subscribedToEmails" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "subscribedToEmails" }
								}
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "email" } },
								{ kind: "Field", name: { kind: "Name", value: "isAdmin" } },
								{ kind: "Field", name: { kind: "Name", value: "isActive" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "agencies" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "controlNumber" } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>
export const ApproveUserDocument = {
	__meta__: { hash: "2db14ecf898f36088285cadc1253a2d673faa603" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "approveUser" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "agencyIDs" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "ListType",
							type: {
								kind: "NonNullType",
								type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
							}
						}
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "approveUser" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "agencyIDs" },
								value: { kind: "Variable", name: { kind: "Name", value: "agencyIDs" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<ApproveUserMutation, ApproveUserMutationVariables>
export const DisapproveUserDocument = {
	__meta__: { hash: "642302e9858a3102a448080f0185c6d3f22a18f1" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "disapproveUser" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "agencyIDs" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "ListType",
							type: {
								kind: "NonNullType",
								type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
							}
						}
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "disapproveUser" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "agencyIDs" },
								value: { kind: "Variable", name: { kind: "Name", value: "agencyIDs" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<DisapproveUserMutation, DisapproveUserMutationVariables>
export const RequestAgencyDocument = {
	__meta__: { hash: "cd8bd4d462a0ee1b0b6631aa5f4b738b98beeaa6" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "requestAgency" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "agency" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "requestAgency" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "agency" },
								value: { kind: "Variable", name: { kind: "Name", value: "agency" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<RequestAgencyMutation, RequestAgencyMutationVariables>
export const CancelAgencyRequestDocument = {
	__meta__: { hash: "6adf58870d026c4d8376a88ba637faec9fa81297" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "cancelAgencyRequest" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "agency" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "cancelAgencyRequest" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "agency" },
								value: { kind: "Variable", name: { kind: "Name", value: "agency" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<
	CancelAgencyRequestMutation,
	CancelAgencyRequestMutationVariables
>
export const DeleteUserDocument = {
	__meta__: { hash: "183e23e1a162749bcc95771d87c66410b88011e2" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "deleteUser" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "permanentlyDeleteUser" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>
export const ViewPublicationDocument = {
	__meta__: { hash: "2fe0cf529207a288afb1cbc3a7b34b9ae1f6d9ef" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "viewPublication" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "PublicationType" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "publicationOrHistoricalPublication" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "type" },
								value: { kind: "Variable", name: { kind: "Name", value: "type" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{
									kind: "InlineFragment",
									typeCondition: {
										kind: "NamedType",
										name: { kind: "Name", value: "Publication" }
									},
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "FragmentSpread",
												name: {
													kind: "Name",
													value: "viewCurrentFilings_PublicationFragment"
												}
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "filings" },
												arguments: [
													{
														kind: "Argument",
														name: { kind: "Name", value: "type" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "type" }
														}
													}
												],
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{
															kind: "FragmentSpread",
															name: {
																kind: "Name",
																value: "viewCurrentFilings_FilingFragment"
															}
														}
													]
												}
											}
										]
									}
								},
								{
									kind: "InlineFragment",
									typeCondition: {
										kind: "NamedType",
										name: { kind: "Name", value: "HistoricalPublication" }
									},
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{
												kind: "Field",
												name: { kind: "Name", value: "contents" },
												arguments: [
													{
														kind: "Argument",
														name: { kind: "Name", value: "type" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "type" }
														}
													}
												],
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "__typename" }
														},
														{ kind: "Field", name: { kind: "Name", value: "content" } }
													]
												}
											}
										]
									}
								}
							]
						}
					}
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewCurrentFilings_PublicationFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Publication" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "title" } },
					{ kind: "Field", name: { kind: "Name", value: "publicationDate" } },
					{ kind: "Field", name: { kind: "Name", value: "editorsNote" } }
				]
			}
		},
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "viewCurrentFilings_FilingFragment" },
			typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Filing" } },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "filingType" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "key" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "hasProposal" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "shouldMergeIntoCurrentCode" }
								}
							]
						}
					},
					{ kind: "Field", name: { kind: "Name", value: "scope" } },
					{ kind: "Field", name: { kind: "Name", value: "intendedAction" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "agency" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{ kind: "Field", name: { kind: "Name", value: "sortTitle" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "headingForNonstandardSectionInAam" }
								}
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "proposal" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "publication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "isProposal" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "certification" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "publication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "submissionDate" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "withdrawOrDisapprovePublicationID" }
								},
								{ kind: "Field", name: { kind: "Name", value: "withdrawDate" } },
								{ kind: "Field", name: { kind: "Name", value: "disapproveDate" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "disapproveWithSuggestionsDate" }
								},
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{
									kind: "Field",
									alias: { kind: "Name", value: "certifyingWithChanges" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "apa-3", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: {
												kind: "StringValue",
												value: "certifying-with-changes",
												block: false
											}
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								},
								{
									kind: "Field",
									alias: { kind: "Name", value: "titleFromFormEntry" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "misc-notice", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: { kind: "StringValue", value: "title", block: false }
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "isProposal" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "recertifications" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "draft" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "currentCode" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "idText" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } }
										]
									}
								},
								{
									kind: "Field",
									name: { kind: "Name", value: "publication" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "id" } },
											{ kind: "Field", name: { kind: "Name", value: "title" } },
											{ kind: "Field", name: { kind: "Name", value: "publicationDate" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "submissionDate" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "withdrawOrDisapprovePublicationID" }
								},
								{ kind: "Field", name: { kind: "Name", value: "withdrawDate" } },
								{ kind: "Field", name: { kind: "Name", value: "disapproveDate" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "disapproveWithSuggestionsDate" }
								},
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{
									kind: "Field",
									alias: { kind: "Name", value: "certifyingWithChanges" },
									name: { kind: "Name", value: "formAnswer" },
									arguments: [
										{
											kind: "Argument",
											name: { kind: "Name", value: "formKey" },
											value: { kind: "StringValue", value: "apa-3", block: false }
										},
										{
											kind: "Argument",
											name: { kind: "Name", value: "question" },
											value: {
												kind: "StringValue",
												value: "certifying-with-changes",
												block: false
											}
										}
									],
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "text" } }
										]
									}
								},
								{ kind: "Field", name: { kind: "Name", value: "isProposal" } }
							]
						}
					},
					{ kind: "Field", name: { kind: "Name", value: "effectiveDate" } },
					{ kind: "Field", name: { kind: "Name", value: "isEffective" } }
				]
			}
		}
	]
} as unknown as DocumentNode<ViewPublicationQuery, ViewPublicationQueryVariables>
export const AnnouncementsMetaAndResourcesDocument = {
	__meta__: { hash: "1037d73a112c8a15907e4d73bc47a75b2b42561d" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "announcementsMetaAndResources" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "announcements" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } }
							]
						}
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "resources" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "title" } },
								{ kind: "Field", name: { kind: "Name", value: "fileUrl" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "file" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{ kind: "Field", name: { kind: "Name", value: "__typename" } },
											{ kind: "Field", name: { kind: "Name", value: "filename" } }
										]
									}
								}
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<
	AnnouncementsMetaAndResourcesQuery,
	AnnouncementsMetaAndResourcesQueryVariables
>
export const AnnouncementsContentsDocument = {
	__meta__: { hash: "e0c68a80ece0b75bc3937445dfe347d7f002098b" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "announcementsContents" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "announcements" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "__typename" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "description" } },
								{ kind: "Field", name: { kind: "Name", value: "content" } },
								{ kind: "Field", name: { kind: "Name", value: "updatedAt" } }
							]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<
	AnnouncementsContentsQuery,
	AnnouncementsContentsQueryVariables
>
export const SortAnnouncementsDocument = {
	__meta__: { hash: "d880a4ac8eb14cf701e93c381ef59dc24a6f3258" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "sortAnnouncements" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "announcements" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "ListType",
							type: {
								kind: "NonNullType",
								type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
							}
						}
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "sortAnnouncements" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "announcements" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "announcements" }
								}
							}
						]
					}
				]
			}
		}
	]
} as unknown as DocumentNode<
	SortAnnouncementsMutation,
	SortAnnouncementsMutationVariables
>
export const SortResourcesDocument = {
	__meta__: { hash: "892344428aded7da47d8d73c0b1548148ff675b5" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "sortResources" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "resources" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "ListType",
							type: {
								kind: "NonNullType",
								type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
							}
						}
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "sortResources" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "resources" },
								value: { kind: "Variable", name: { kind: "Name", value: "resources" } }
							}
						]
					}
				]
			}
		}
	]
} as unknown as DocumentNode<SortResourcesMutation, SortResourcesMutationVariables>
export const DeleteAnnouncementDocument = {
	__meta__: { hash: "ebc4b131090fe545f6c2f281111d27d34cb08549" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "deleteAnnouncement" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "permanentlyDeleteAnnouncement" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<
	DeleteAnnouncementMutation,
	DeleteAnnouncementMutationVariables
>
export const DeleteResourceDocument = {
	__meta__: { hash: "de10212439c98a1248b4a18ca56a8595a733d9a3" },
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "deleteResource" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: { kind: "NamedType", name: { kind: "Name", value: "ID" } }
					}
				}
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "__typename" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "permanentlyDeleteResource" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: { kind: "Variable", name: { kind: "Name", value: "id" } }
							}
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [{ kind: "Field", name: { kind: "Name", value: "__typename" } }]
						}
					}
				]
			}
		}
	]
} as unknown as DocumentNode<DeleteResourceMutation, DeleteResourceMutationVariables>
