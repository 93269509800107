import React from "react"

import { persistedExchange } from "@urql/exchange-persisted"
import { retryExchange } from "@urql/exchange-retry"
import filter from "fast-ts-helpers/filter"
import injectDates from "fast-ts-helpers/injectDates"
import ReactDOM from "react-dom/client"
import {
	cacheExchange,
	createClient,
	fetchExchange,
	mapExchange,
	Provider as UrqlProvider
} from "urql"

import App from "./App"
import { AlertProvider } from "./components/Alert"
import { UserProvider } from "./contexts"
import "./index.scss"
import reportWebVitals from "./reportWebVitals"

const client = createClient({
	url: new URL(`${env.REACT_APP_API_URL}/graphql`, document.location.origin).href,
	fetchSubscriptions: true,
	fetchOptions: {
		credentials: "include"
	},
	exchanges: filter([
		mapExchange({
			onResult(result) {
				injectDates(result.data, (key) => {
					return key.endsWith("ID") || key === "id"
				})
			}
		}),
		cacheExchange,
		// On local we do not use the persisted exchange by default, so we can develop
		//   with arbitrary requests. **NOTE:** only local and dev server will accept
		//   arbitrary requests.
		env.REACT_APP_SERVER !== "local" &&
			persistedExchange({
				preferGetForPersistedQueries: "within-url-limit",
				enforcePersistedQueries: true,
				enableForMutation: true,
				generateHash: (_, doc: any) => doc["__meta__"]["hash"]
			}),
		retryExchange({
			retryIf: (error) =>
				error.networkError == null &&
				!error.graphQLErrors.some((e) => /cannot return null/i.test(e.message)) &&
				error.response.status !== 400,
			maxNumberAttempts: env.REACT_APP_SERVER === "local" ? 1 : 4,
			initialDelayMs: 200,
			maxDelayMs: 3000
		}),
		fetchExchange
	])
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
	<React.StrictMode>
		<UrqlProvider value={client}>
			<UserProvider>
				<AlertProvider>
					<App />
				</AlertProvider>
			</UserProvider>
		</UrqlProvider>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
