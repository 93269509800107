import React from "react"

import Button from "../../components/Button"
import PaperPageTemplate from "../../components/PaperPageTemplate"

const NotAuthorized: React.FC = () => {
	return (
		<PaperPageTemplate>
			<h2 className="text-sm font-bold uppercase">Not Authorized</h2>
			<p className="mx-36 mt-7">You’re not authorized to view this page!</p>
			<div className="mt-10 flex flex-wrap gap-4">
				<Button to="/">Take me home</Button>
				<Button to={`/login?return=${encodeURIComponent(location.pathname)}`} ghost>
					Try another account
				</Button>
			</div>
		</PaperPageTemplate>
	)
}

export default NotAuthorized
