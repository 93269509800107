import { useContext, useRef } from "react"

import usePrevious from "fast-ts-helpers/usePrevious"
import { useNavigate } from "react-router-dom"

import { UserContext } from "../../contexts"

const useCheckSelectedAgency = (
	agency: { agencyID: string } | string | null | undefined
) => {
	const { user, activeAgency, setActiveAgency } = useContext(UserContext)
	const navigate = useNavigate()
	const previousActiveID = usePrevious(activeAgency?.id, true)

	const iJustNavigated = useRef(false)
	const agencyID = typeof agency === "string" ? agency : agency?.agencyID

	if (user && activeAgency && agencyID && agencyID !== activeAgency.id) {
		setTimeout(() => {
			if (previousActiveID && previousActiveID !== activeAgency.id) {
				iJustNavigated.current = true

				navigate(
					"/agency/filings/" +
						location.pathname.replace(/\/agency\/filings\/([^/]+).*/, "$1")
				)
			} else {
				if (!iJustNavigated.current) {
					try {
						setActiveAgency(agencyID)
					} catch {
						navigate(
							"/agency/filings/" +
								location.pathname.replace(/\/agency\/filings\/([^/]+).*/, "$1")
						)
					}
				}

				iJustNavigated.current = false
			}
		})
	}
}

export default useCheckSelectedAgency
