import React from "react"

import { twMerge } from "tailwind-merge"
import { ClassNameValue } from "tailwind-merge/dist/lib/tw-join"

export type PaperProps = {
	className?: ClassNameValue
	children?: React.ReactNode
}

const Paper: React.FC<PaperProps> = ({ className, children }) => (
	<div
		data-testid="Paper-Div"
		className={twMerge(
			"relative w-full break-words rounded-[0.625rem] border-2 border-white bg-white p-2.5 shadow-[0px_0px_5px_0px_grey]",
			className
		)}
	>
		{children}
	</div>
)

export default Paper
