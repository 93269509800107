import React from "react"

import Union from "fast-ts-helpers/Union"
import { twMerge } from "tailwind-merge"
import { ClassNameValue } from "tailwind-merge/dist/lib/tw-join"

import { AlertProvider } from "../Alert"
import Footer from "../Footer"
import Header from "../Header"
import Sidebar from "../Sidebar"
import UserSidebar from "../UserSidebar"

export type ContentWrapperProps = {
	/** Any additional classes to pass to the content wrapper. */
	className?: ClassNameValue

	children?: React.ReactNode
} & Union<
	[{ public: boolean }, { admin: boolean; userSidebar?: boolean }, { agency: boolean }]
> &
	Union<[{ noSidebar?: boolean }, { userSidebar: boolean }]>

const ContentWrapper: React.FC<ContentWrapperProps> = ({
	public: isPublic,
	admin,

	className,

	noSidebar,

	userSidebar,

	children
}) => {
	return (
		<AlertProvider offset={79}>
			{!env.REACT_APP_HIDE_BANNER &&
				(env.REACT_APP_SERVER === "local" ||
					env.REACT_APP_SERVER === "dev" ||
					env.REACT_APP_SERVER === "qat") && (
					<div className="bg-gray-5 px-5 py-2 text-white">
						This is not a production environment:{" "}
						{Object.entries(env).map(([k, v]) => (
							<React.Fragment key={k}>
								{k}: {v} <span className="ml-5 " />
							</React.Fragment>
						))}
					</div>
				)}
			<div
				className={twMerge(
					'grid flex-1 grid-cols-[20rem_calc(100vw-20rem)] grid-rows-[5rem_minmax(calc(100vh-6rem),1fr)_auto] [grid-template-areas:"header_header"_"sidebar_content"_"footer_footer"]',
					isPublic
						? 'max-lg:grid-cols-1 max-lg:[grid-template-areas:"header"_"content"_"footer"]'
						: "max-xl:grid-cols-[auto_1fr]",
					noSidebar && 'grid-cols-1 [grid-template-areas:"header"_"content"_"footer"]'
				)}
			>
				<Header
					className="[grid-area:header]"
					{...(isPublic ? { public: true } : admin ? { admin: true } : { agency: true })}
					hideMenu={noSidebar}
				/>
				{!noSidebar &&
					(admin && userSidebar ? (
						<UserSidebar className="[grid-area:sidebar]" />
					) : (
						<Sidebar
							{...(isPublic
								? { public: true }
								: admin
								? { admin: true }
								: { agency: true })}
							className={twMerge("[grid-area:sidebar]", isPublic && "max-lg:hidden")}
						/>
					))}
				<main
					className={twMerge(
						"relative flex flex-1 flex-col gap-3 bg-gray-1 px-10 py-8 [grid-area:content] sm:pl-16 sm:pr-16",
						className
					)}
				>
					{children}
				</main>
				<Footer className="[grid-area:footer]" />
			</div>
		</AlertProvider>
	)
}

export default ContentWrapper
