import React from "react"

import isClassNameValue from "fast-ts-helpers/isClassNameValue"
import { twMerge } from "tailwind-merge"
import { ClassNameValue } from "tailwind-merge/dist/lib/tw-join"

export type FormElementProps = {
	/** If a class name value is passed directly, it will apply to the label. */
	className?:
		| ClassNameValue
		| {
				label?: ClassNameValue
				error?: ClassNameValue
				info?: ClassNameValue
		  }

	/** The label to show for this element. If this is not passed, no `label` element will be rendered. */
	label?: React.ReactNode
	/** Whether this is a required field. Adds an asterisk to the label. Doesn’t affect the actual input element. */
	required?: boolean
	/** An error message to show */
	error?: React.ReactNode | null | undefined
	/** An informative message to show */
	info?: React.ReactNode | null | undefined

	/** The contents of the label. */
	children?: React.ReactNode

	ref?: React.Ref<HTMLLabelElement>
}

const FormElement: React.FC<FormElementProps> = React.forwardRef<
	HTMLLabelElement,
	Omit<FormElementProps, "ref">
>(({ className, label, error, info, required, children }, ref) => {
	if (isClassNameValue(className)) {
		className = {
			label: className
		}
	}

	return (
		<label
			ref={ref}
			className={twMerge("flex flex-col", error ? "mb-1" : "mb-5", className.label)}
		>
			{label && (
				<span className="mb-1 text-xs font-semibold">
					{label} {required && <span className="text-error">*</span>}
				</span>
			)}
			{children}
			{error && (
				<span className={twMerge("text-2xs text-error", className.error)}>{error}</span>
			)}
			{info && (
				<span className={twMerge("text-2xs text-info", className.info)}>{info}</span>
			)}
		</label>
	)
})

export default FormElement
