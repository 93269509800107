import React, { useEffect, useState } from "react"

import isClassNameValue from "fast-ts-helpers/isClassNameValue"
import { twMerge } from "tailwind-merge"
import { ClassNameValue } from "tailwind-merge/dist/lib/tw-join"

import Loader from "../Loader"

export type PdfViewerProps = {
	src?: string | null | undefined
	title?: string | null | undefined

	onLoad?: () => void

	/** Any additional classes to pass to this component. */
	className?:
		| ClassNameValue
		| {
				wrapper?: ClassNameValue
				frame?: ClassNameValue
				loader?: ClassNameValue
		  }
}

/** Display a box and loader while the PDF loads. */
const PdfViewer: React.FC<PdfViewerProps> = ({
	src: inputSrc,
	title,
	className,
	onLoad
}) => {
	if (isClassNameValue(className)) {
		className = { wrapper: className }
	}

	const agent = navigator.userAgent

	const [src, setSrc] = useState(inputSrc ?? "")

	// While changing the src, go to nothing for a second to force an update.
	useEffect(() => {
		if (inputSrc && !src) {
			setSrc(inputSrc)
		} else if (inputSrc && src && inputSrc !== src) {
			setSrc("")
		}
	}, [inputSrc, src])

	return (
		<div
			className={twMerge(
				"relative z-0 flex",
				/Edg/.test(agent)
					? "bg-[#E6E6E6]"
					: /Chrome/.test(agent) || /Chromium/.test(agent)
					? "bg-[#525659]"
					: /Firefox/.test(agent)
					? "bg-[#D4D4D7]"
					: // Safari, and default
					  "bg-[#7C7C7C]",
				className.wrapper
			)}
		>
			<Loader
				center
				className={twMerge(
					"-z-10",
					/firefox/i.test(agent) || /Edg/.test(agent) ? "text-gray-6" : "text-white",
					className.loader
				)}
			/>
			{src ? (
				<iframe
					role="document"
					title={title || "document viewer"}
					className={twMerge("flex-1", className.frame)}
					onLoad={onLoad}
					src={src}
				/>
			) : (
				<div className={twMerge("flex-1", className.frame)} />
			)}
		</div>
	)
}

export default PdfViewer
