import React, { useContext } from "react"

import { toTitleCase } from "@artsy/to-title-case"
import { IconArrowRight, IconEye } from "@tabler/icons-react"
import last from "fast-ts-helpers/last"
import wrap from "fast-ts-helpers/wrap"
import { Link, useParams } from "react-router-dom"
import { useQuery } from "urql"

import Button from "../../components/Button"
import Icon from "../../components/Icon"
import { useMenuItem } from "../../components/Sidebar"
import { UserContext } from "../../contexts"
import { graphql } from "../../graphql"
import { ActivityLogQuery } from "../../graphql/graphql"
import useCheckSelectedAgency from "../../hooks/useCheckSelectedAgency"
import formatDate from "../../utils/formatDate"

import Error404 from "../Error404"
import ServerError from "../ServerError"

import FilingActivityLogDetailsModal from "./FilingActivityLogDetailsModal"

const activityLogQuery = graphql(`
	query activityLog($id: ID!) {
		filing(id: $id) {
			name

			status

			intendedAction
			scope

			agencyID

			activityLog {
				id

				__typename

				date
				user {
					name
					email
				}

				... on WithDiffLogEntry {
					wasReset
				}

				... on ChapterDraftUpdatedLogEntry {
					chapterUpdated
					updatedRules
					reorderedRules
					addedRule
					deletedRule
				}

				... on WithFileLogEntry {
					fileUrl
				}

				... on FormChangedLogEntry {
					form {
						name
					}
				}

				... on FormUpdatedLogEntry {
					wasNew
				}
			}

			currentSubmission {
				currentCode {
					__typename

					idText
				}
			}
		}
	}
`)

const FilingActivityLog: React.FC = () => {
	const { id, pluralFilingStatus } = useParams() as {
		id: string
		pluralFilingStatus: string
	}
	const { activeAgency } = useContext(UserContext)
	const menuItem = useMenuItem(pluralFilingStatus)

	const [{ data, fetching }] = useQuery({
		query: activityLogQuery,
		variables: { id },
		requestPolicy: "cache-and-network"
	})

	const filing = data?.filing
	const currentCode = filing?.currentSubmission.currentCode

	useCheckSelectedAgency(data?.filing.agencyID)

	let breadcrumbTitle = filing?.name ? `${filing.name} - ` : ""

	if (filing?.intendedAction && filing.scope) {
		breadcrumbTitle += `${toTitleCase(
			filing.intendedAction.replaceAll(/([A-Z])/g, " $1").toLowerCase()
		)}`

		if (filing.intendedAction === "new" && filing.scope) {
			breadcrumbTitle += ` ${filing.scope} for`
		}

		if (currentCode) {
			breadcrumbTitle += ` ${currentCode.__typename.replace("Snapshot", "")} ${
				currentCode.idText
			}`
		} else {
			breadcrumbTitle += ` ${activeAgency?.title ?? "Agency"}`
		}
	}

	const [selectedEntry, setSelectedEntry] = React.useState<string>()

	if (!data && fetching) {
		return null
	}

	if (!data) {
		return <ServerError />
	}

	if (!filing) {
		return <Error404 />
	}

	const byDay = Object.entries(
		filing.activityLog.reduce<Record<string, ActivityLogQuery["filing"]["activityLog"]>>(
			(transient, entry) => {
				const day = formatDate(entry.date, {
					month: "long",
					day: "numeric",
					year: "numeric"
				})

				const byDay = (transient[day] ??= [])

				byDay.push(entry)

				return transient
			},
			{}
		)
	)

	const inDraftStatus = menuItem
		? wrap(menuItem.status).some((s) =>
				["draft", "proposalAwaitingComments", "proposalCertificationDraft"].includes(s)
		  ) && menuItem.link.includes("agency")
		: undefined

	return (
		<>
			<nav className="mr-auto flex items-center gap-2 pb-2 text-sm text-gray-5">
				<Link to="./../.." className="underline">
					{menuItem?.label}
				</Link>
				<Icon icon={IconArrowRight} />
				{inDraftStatus != null &&
					(inDraftStatus ? (
						<>
							<Link to="./.." className="underline">
								{breadcrumbTitle}
							</Link>
							<Icon icon={IconArrowRight} />
							<span className="text-gray-7">Activity Log</span>
						</>
					) : (
						<span className="text-gray-7">{breadcrumbTitle} Activity Log</span>
					))}
			</nav>
			<h1 className="text-lg font-bold text-almost-blk">Activity Log</h1>
			{byDay.length === 0 ? (
				<p className="mt-3 text-gray-7">No activity to display.</p>
			) : (
				<ol className="mt-3 flex flex-col gap-6 text-sm text-gray-9">
					{byDay.map(([day, entries]) => (
						<li key={day}>
							<h2 className="mb-3 w-full break-after-avoid border-b border-gray-2 pb-1.5 font-bold">
								{day}
							</h2>
							<table className="w-full overflow-hidden rounded-md bg-white shadow-[0_0_0_1px_theme('colors.gray-2'),0_1px_3px_0_rgba(0,0,0,0.05),0_10px_15px_-5px_rgba(0,0,0,0.05),0_7px_7px_-5px_rgba(0,0,0,0.04)] [&_*]:align-top">
								<colgroup>
									<col className="w-56" />
									<col className="w-auto min-w-44" />
									<col className="w-44" />
								</colgroup>
								<thead>
									<tr className="bg-gray-1">
										<th className="pb-4 pl-10 pt-5.5 font-bold">User</th>
										<th className="pb-4 pt-5.5 font-bold">Action</th>
										<th className="pb-4 pr-10 pt-5.5 font-bold">Details</th>
									</tr>
								</thead>
								<tbody>
									<tr aria-hidden>
										<td className="pb-0.5" />
									</tr>
									{entries.map((l) => (
										<tr
											key={l.id}
											className="relative after:absolute after:inset-x-10 after:bottom-0 after:border-b after:border-gray-2"
										>
											<td className="py-3.5 pl-10">{l.user.name}</td>
											<td className="flex flex-col py-3.5 font-semibold">
												{actionFor(filing, l)}
												<span className="font-normal leading-snug">
													{formatDate(l.date, {
														format: "'at' h:mm a 'on' EEE MMM d"
													}).replace(/\b(AM|PM)\b/, (m) => m.toLowerCase())}
												</span>
											</td>
											<td className="py-3.5 pr-10">{detailsFor(l, setSelectedEntry)}</td>
										</tr>
									))}
									<tr aria-hidden>
										<td className="pb-3" />
									</tr>
								</tbody>
							</table>
						</li>
					))}
				</ol>
			)}
			<Button className="mt-8 self-start px-8" to={inDraftStatus ? "./.." : "./../.."}>
				Exit
			</Button>
			{selectedEntry && (
				<FilingActivityLogDetailsModal
					id={selectedEntry}
					onDidClose={() => setSelectedEntry(undefined)}
				/>
			)}
		</>
	)
}

const actionFor = (
	filing: ActivityLogQuery["filing"],
	entry: ActivityLogQuery["filing"]["activityLog"][number]
) => {
	if (
		entry.__typename === "RuleDraftUpdatedLogEntry" ||
		entry.__typename === "ChapterDraftUpdatedLogEntry"
	) {
		if (entry.wasReset) {
			return `Reset ${filing.scope}`
		}

		if (entry.__typename === "ChapterDraftUpdatedLogEntry") {
			if (entry.addedRule) {
				return `Added Rule ${entry.addedRule}`
			}

			if (entry.deletedRule) {
				return `Deleted Rule ${entry.deletedRule}`
			}

			const actions: string[] = []

			if (entry.chapterUpdated) {
				actions.push(`Updated Chapter`)
			}

			const truncateAfter =
				Math.abs(entry.updatedRules.length - entry.reorderedRules.length) < 2 ? 2 : 4

			if (entry.reorderedRules.length) {
				actions.push(
					`Reordered Rule${entry.reorderedRules.length === 1 ? "" : "s"} ${joinList(
						entry.reorderedRules,
						truncateAfter
					)}`
				)
			}

			if (entry.updatedRules.length) {
				actions.push(
					`Updated Rule${entry.updatedRules.length === 1 ? "" : "s"} ${joinList(
						entry.updatedRules,
						truncateAfter
					)}`
				)
			}

			if (actions.length) {
				return joinList(actions)
			}
		}

		return `Updated ${filing.scope}`
	}

	if (entry.__typename === "FormUpdatedLogEntry") {
		return `${entry.wasNew ? "Created" : "Updated"} ${entry.form.name}`
	}

	if (entry.__typename === "FormSignedLogEntry") {
		return `Signed ${entry.form.name}`
	}

	return {
		StrikethroughApprovedLogEntry: "Approved Strikethrough",
		FilingCreatedLogEntry: "Created Filing",
		FilingApprovedLogEntry: "Approved Filing",
		FilingDisapprovedLogEntry: "Disapproved Filing",
		FilingSubmittedLogEntry: "Submitted Filing for Admin Review",
		FilingWithdrawnLogEntry: "Withdrew Filing",
		FilingDisapprovedWithSuggestionsLogEntry:
			"Disapproved Filing with Suggested Amendments",
		FilingRecalledLogEntry: "Recalled Filing",
		FilingRejectedLogEntry: "Rejected Filing"
	}[entry.__typename]
}

const detailsFor = (
	entry: ActivityLogQuery["filing"]["activityLog"][number],
	setSelectedEntry: (id: string) => void
) => {
	if ("fileUrl" in entry) {
		return (
			<Button plain onClick={() => setSelectedEntry(entry.id)} aria-label="View File">
				<Icon icon={IconEye} />
			</Button>
		)
	}

	if (
		entry.__typename === "ChapterDraftUpdatedLogEntry" ||
		entry.__typename === "RuleDraftUpdatedLogEntry"
	) {
		return (
			<Button
				plain
				onClick={() => setSelectedEntry(entry.id)}
				aria-label="View Diff with Code at Time of Change"
			>
				<Icon icon={IconEye} />
			</Button>
		)
	}

	return null
}

export default FilingActivityLog

const joinList = (list: string[], truncateAfter = Infinity) => {
	if (list.length > 1 && truncateAfter === 1) {
		return `${list[0]} and others`
	}

	if (list.length > 2) {
		return `${list.slice(0, Math.min(list.length - 1, truncateAfter)).join(
			// If any of the list items include commas, we need to use semicolons
			list.some((i) => i.includes(",")) ? "; " : ", "
		)}, and ${list.length > truncateAfter ? "others" : last(list)}`
	}

	if (list.length > 1) {
		return list.join(" and ")
	}

	return list[0] || ""
}
