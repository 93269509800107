/* istanbul ignore file */
import React, { useEffect, useRef } from "react"

import { Dialog, Transition } from "@headlessui/react"
import { IconX } from "@tabler/icons-react"
import isClassNameValue from "fast-ts-helpers/isClassNameValue"
import { twMerge } from "tailwind-merge"
import { ClassNameValue } from "tailwind-merge/dist/lib/tw-join"

import getNodeText from "../../utils/getNodeText"

import Icon from "../Icon"

export type ModalProps = {
	/** If a class name value is passed directly, it will apply to the content. */
	className?:
		| ClassNameValue
		| {
				backdrop?: ClassNameValue
				content?: ClassNameValue
				panel?: ClassNameValue
				closeButton?: ClassNameValue
				title?: ClassNameValue
		  }
	isOpen?: boolean
	onRequestClose?: (ref: HTMLDivElement | null) => void
	onDidClose?: (ref: HTMLDivElement | null) => void
	onDidOpen?: (ref: HTMLDivElement | null) => void
	title?: React.ReactNode
	"aria-label"?: string
	children?: React.ReactNode
	/** Defaults to `"medium"` */
	size?: "small" | "medium" | "large"
}

const Modal: React.FC<ModalProps> = ({
	className,
	isOpen = false,
	onRequestClose = () => {},
	onDidClose,
	onDidOpen,
	title,
	children,
	"aria-label": ariaLabel = getNodeText(title),
	size = "medium"
}) => {
	if (isClassNameValue(className)) {
		className = {
			content: className
		}
	}

	const ref = useRef<HTMLDivElement>(null)

	// This provides a custom event that allows other elements to handle quirks of the
	//   headlessui dialog when it gets unmounted.
	useEffect(() => {
		return () => {
			window.dispatchEvent(new CustomEvent("ModalUnmounted"))
		}
	}, [])

	return (
		<Transition
			show={isOpen}
			as={React.Fragment}
			afterEnter={() => onDidOpen?.(ref.current)}
			afterLeave={() => onDidClose?.(ref.current)}
		>
			<Dialog
				as="div"
				ref={ref}
				onClose={() => onRequestClose?.(ref.current)}
				aria-label={ariaLabel}
				className="fixed inset-0 z-50"
			>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-200"
					leave="ease-out duration-100"
					enterFrom="opacity-0"
					leaveTo="opacity-0"
				>
					<div
						className={twMerge(
							"fixed inset-0 cursor-pointer bg-black/50 backdrop-blur-[0.046875rem]",
							className.backdrop
						)}
						aria-hidden="true"
					/>
				</Transition.Child>

				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-200"
					leave="ease-out duration-100"
					enterFrom="opacity-0"
					leaveTo="opacity-0"
				>
					<Dialog.Panel
						className={twMerge(
							"absolute left-1/2 top-1/2 flex max-h-[80vh] max-w-[90vw] -translate-x-1/2 -translate-y-1/2 flex-col break-words rounded-md bg-white shadow-[0px_0px_10px_0px_grey] overflow-hidden",
							{
								small: "w-[20rem]",
								medium: "w-[30rem] min-[540px]:w-5/12 min-[540px]:min-w-[30rem]",
								large: "w-[30rem] min-[540px]:w-3/4 min-[540px]:min-w-[30rem]"
							}[size],
							className.panel
						)}
					>
						{title && (
							<Dialog.Title
								className={twMerge(
									"relative items-center justify-start rounded-t-md bg-gray-1 pb-4.5 pl-9 pr-14 pt-5 text-xs font-bold",
									className.title
								)}
							>
								{title}
							</Dialog.Title>
						)}
						<div
							className={twMerge(
								"flex-grow flex flex-col overflow-y-auto px-9 py-8",
								className.content
							)}
						>
							{children}
						</div>
						<button
							onClick={() => onRequestClose?.(ref.current)}
							className={twMerge(
								"absolute right-6 top-3 p-2 text-lg text-gray-4",
								className.closeButton
							)}
							aria-label={`Close${title ? ` ${title} dialog` : " dialog"}`}
						>
							<Icon icon={IconX} />
						</button>
					</Dialog.Panel>
				</Transition.Child>
			</Dialog>
		</Transition>
	)
}

export default Modal
