import React, { Fragment, useEffect, useRef, useState } from "react"

import { Combobox as OriginalCombobox, Transition } from "@headlessui/react"
import { ByComparator } from "@headlessui/react/dist/types"
import { IconChevronDown } from "@tabler/icons-react"
import isClassNameValue from "fast-ts-helpers/isClassNameValue"
import Union from "fast-ts-helpers/Union"
import { twJoin, twMerge } from "tailwind-merge"
import { ClassNameValue } from "tailwind-merge/dist/lib/tw-join"

import ngramSearch from "../../utils/ngramSearch"

import FormElement from "../FormElement"
import Icon from "../Icon"

export type ComboboxProps<TOption> = {
	options?: TOption[]
	label?: string
	error?: string

	className?:
		| ClassNameValue
		| {
				label?: ClassNameValue
				error?: ClassNameValue

				inner?: ClassNameValue
				input?: ClassNameValue
				button?: ClassNameValue
				menu?: ClassNameValue
		  }
	/** Any additional children to render in the same container as this combobox. Useful for a remove button or something. */
	children?: React.ReactNode
	filter?: (query: string) => TOption[]
	display?: (option: TOption) => string
	keyFor?: (option: TOption, index: number) => React.Key

	disabled?: boolean
	placeholder?: string
	autoComplete?: string

	onFocus?: React.FocusEventHandler
} & Union<[{ required?: boolean }, { visuallyRequired?: boolean }]> &
	Union<
		[
			{
				nullable?: false
				value?: TOption | undefined
				onChange?: (newValue: TOption) => void
				by?: keyof TOption | ((a: TOption, b: TOption) => boolean)
			},
			{
				nullable: true
				value?: TOption | null | undefined
				onChange?: (newValue: TOption | null) => void
				by?: keyof TOption | ((a: TOption | null, b: TOption | null) => boolean)
			}
		]
	>

const compare = <TOption extends any>(
	by: ComboboxProps<TOption>["by"],
	l: TOption | null | undefined,
	r: TOption | null | undefined
) => {
	if (l == null && r == null) {
		return true
	}

	if (l == null || r == null) {
		return false
	}

	if (typeof by === "function") {
		return by(l, r)
	} else if (by != null) {
		return l?.[by] === r?.[by]
	}

	return l === r
}

const Combobox = <TOption extends any>({
	options,
	value,
	onChange,
	className,
	children,
	nullable,
	label,
	error,
	display = (o) => o?.toString() ?? "",
	keyFor = (_, i) => i,
	filter = (q) =>
		ngramSearch(
			q,
			options?.map((o) => [o, display(o)])
		),
	by,

	disabled,
	required,
	visuallyRequired,
	placeholder,
	autoComplete = "off",

	onFocus
}: ComboboxProps<TOption>) => {
	if (isClassNameValue(className)) {
		className = {
			label: className
		}
	}

	const [query, setQuery] = useState("")

	const filteredOptions = filter(query)

	const closing = useRef(false)
	const showingMenu = useRef(false)
	const justFocused = useRef(false)
	const optionsBox = useRef<HTMLUListElement>(null)
	const comboboxButton = useRef<HTMLButtonElement>(null)

	/**
	 * We don't want to randomly receive focus after a modal unmounts. This is a guard
	 *   against that. We listen for our custom event, and set a flag for 100 millis
	 *   (since the last modal was unmounted). We don't open or receive focus during that
	 *   window.
	 */
	const modalJustUnmounted = useRef(0)

	useEffect(() => {
		const listener = () => {
			clearTimeout(modalJustUnmounted.current)
			modalJustUnmounted.current = window.setTimeout(() => {
				modalJustUnmounted.current = 0
			}, 100)
		}

		window.addEventListener("ModalUnmounted", listener)

		return () => {
			window.removeEventListener("ModalUnmounted", listener)
		}
	}, [])

	useEffect(() => {
		if (
			options &&
			value != null &&
			!options.some((option) => compare(by, value, option))
		) {
			onChange?.(null as TOption)
		}
	}, [by, onChange, options, value])

	return (
		<FormElement
			label={label}
			error={error}
			className={{
				...className,
				label: [!disabled && "cursor-pointer", className.label]
			}}
			required={required || visuallyRequired}
		>
			{!disabled ? (
				<OriginalCombobox
					value={value}
					onChange={(v) => {
						closing.current = true
						onChange?.(v as TOption)
					}}
					nullable={nullable as any}
					by={by as ByComparator<TOption | null>}
					disabled={disabled}
				>
					<div className={twMerge("relative", className.inner)}>
						<div className="relative w-full cursor-default overflow-hidden rounded border border-gray-3 bg-white text-left focus-within:border-info">
							<OriginalCombobox.Input
								autoComplete={autoComplete}
								placeholder={placeholder}
								className={twMerge(
									"text-gray-900 w-full border-none py-[0.28125rem] pl-[0.5625rem] pr-[1.9375rem] text-xs outline-none",
									className.input
								)}
								onChange={(event) => {
									if (!closing.current) {
										setQuery(event.target.value)
									}

									optionsBox.current?.scrollTo({ top: 0 })
								}}
								onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
									if (modalJustUnmounted.current) {
										// We don't accept focus after a modal just
										//   unmounts. This fixes a weird bug where the
										//   combobox opens by itself after navigation
										//   or some other actions after unmounting a
										//   modal.
										e.preventDefault()
										e.stopPropagation()
										e.target.blur()

										return
									}

									onFocus?.(e)

									// Open on Focus: If the menu is not shown, click the button to open it
									if (!showingMenu.current && !closing.current) {
										e.target.parentElement?.querySelector("button")?.click()

										// We need to do this in case the user is dragging
										//   to select a range, we don't want to clobber
										//   that range.
										justFocused.current = true

										setTimeout(() => {
											justFocused.current = false
										}, 200)

										e.target.select()
									} else if (closing.current) {
										// When the user selects an item, let’s ensure that this loses focus
										e.target.blur()
									}
								}}
								onMouseMoveCapture={(e) => {
									// Allow the user to still select a specific range by
									//   dragging the cursor over the text
									if (justFocused.current) {
										e.preventDefault()
										e.stopPropagation()
									}
								}}
								displayValue={(item) => (item != null ? display(item as TOption) : "")}
								required={required}
							/>
							<OriginalCombobox.Button
								ref={comboboxButton}
								className={twMerge(
									"absolute inset-y-0 right-0 flex items-center px-2",
									className.button
								)}
								onClick={() => {
									if (showingMenu.current) {
										closing.current = true
									} else {
										// Focus the text
									}

									// Open on Focus: Mark that the menu is now toggled
									showingMenu.current = !showingMenu.current
								}}
								aria-label="toggle combobox options menu"
							>
								<Icon
									icon={IconChevronDown}
									className="text-base text-gray-5"
									aria-hidden="true"
								/>
							</OriginalCombobox.Button>
						</div>
						<Transition
							as={Fragment}
							enter="transition ease-in duration-50"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition ease-in duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
							beforeEnter={() => {
								// Open on Focus: Mark that the menu is about to show
								showingMenu.current = true
							}}
							beforeLeave={() => {
								// Open on Focus: Mark that the menu is about to hide
								showingMenu.current = false
							}}
							afterLeave={() => {
								closing.current = false
								setQuery("")
							}}
						>
							<OriginalCombobox.Options
								className={twMerge(
									"absolute z-10 flex max-h-60 w-full flex-col gap-1 overflow-auto rounded-md bg-white p-1 shadow-[0px_1px_3px_0px_#0000000D,0px_10px_15px_-5px_#0000000D,0px_7px_7px_-5px_#0000000A]",
									className.menu
								)}
								ref={optionsBox}
							>
								{filteredOptions.length === 0 && query !== "" ? (
									<div className="relative select-none px-2.5 py-[0.416875rem] text-xs">
										No matches…
									</div>
								) : (
									filteredOptions.map((option, index) =>
										compare(by, value, option) ? (
											<button
												key={keyFor(option, index)}
												className="relative select-none bg-red/7 px-2.5 py-[0.416875rem] text-left text-xs text-red"
												tabIndex={-1}
												onClick={() => {
													onChange?.(option)
													comboboxButton.current?.click()
												}}
												role="option"
												aria-selected="false"
											>
												{display(option)}
											</button>
										) : (
											<OriginalCombobox.Option
												key={keyFor(option, index)}
												className={({ active }) =>
													twJoin(
														"relative cursor-pointer select-none px-2.5 py-[0.416875rem] text-xs",
														active && "bg-gray-1"
													)
												}
												value={option}
											>
												{display(option)}
											</OriginalCombobox.Option>
										)
									)
								)}
							</OriginalCombobox.Options>
						</Transition>
						{children}
					</div>
				</OriginalCombobox>
			) : (
				<div className={twMerge("relative", className.inner)}>
					<div className="relative w-full cursor-default overflow-hidden rounded border border-gray-3 bg-white text-left focus-within:border-info">
						<input
							autoComplete="off"
							placeholder={placeholder}
							className={twMerge(
								"text-gray-900 w-full border-none py-[0.28125rem] pl-[0.5625rem] pr-[1.9375rem] text-xs outline-none",
								className.input
							)}
							value={value != null ? display(value) : ""}
							readOnly
							disabled={disabled}
						/>
						<button
							className={twMerge(
								"absolute inset-y-0 right-0 flex items-center px-2",
								className.button
							)}
							aria-label="toggle combobox options menu"
							disabled={disabled}
						>
							<Icon
								icon={IconChevronDown}
								className="text-base text-gray-4"
								aria-hidden="true"
							/>
						</button>
					</div>
					{children}
				</div>
			)}
		</FormElement>
	)
}

export default Combobox
