import React from "react"

import PaperPageTemplate from "../../components/PaperPageTemplate"

const ServerError: React.FC = () => {
	return (
		<PaperPageTemplate>
			<h2 className="text-sm font-bold uppercase">Server Error</h2>
			<p className="mx-36 mt-7">
				We’re having trouble connecting to our servers at the moment 😢. Refresh your page
				or try again later!
			</p>
		</PaperPageTemplate>
	)
}

export default ServerError
