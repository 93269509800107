import React, { useContext } from "react"

import { IconLogin, IconLogout, IconUser } from "@tabler/icons-react"
import Union from "fast-ts-helpers/Union"
import { NavLink, useLocation } from "react-router-dom"
import { twMerge } from "tailwind-merge"
import { ClassNameValue } from "tailwind-merge/dist/lib/tw-join"
import { useMutation } from "urql"

import {
	addressLine1,
	addressLine2,
	businessName,
	phoneNumber
} from "../../constants/contactInfo"
import { UserContext } from "../../contexts"
import { graphql } from "../../graphql"
import console from "../../utils/console"

import Button from "../Button"
import Combobox from "../Combobox"
import Icon from "../Icon"
import NavElement from "../NavElement"

import CollapsibleMenu from "./CollapsibleMenu"
import logo from "./logo.jpg"

export type HeaderProps = {
	className?: ClassNameValue

	hideMenu?: boolean
} & Union<[{ public: boolean }, { admin: boolean }, { agency: boolean }]>

const logoutMutation = graphql(`
	mutation logout {
		logout
	}
`)

const Header: React.FC<HeaderProps> = ({
	className,

	hideMenu,
	public: isPublic,
	admin,
	agency
}) => {
	const { user, setUser, activeAgency, setActiveAgency } = useContext(UserContext)

	const [, logout] = useMutation(logoutMutation)

	const location = useLocation()

	return (
		<header
			className={twMerge(
				"sticky top-0 z-20 flex flex-none items-center justify-between gap-x-4 border-b border-gray-2 bg-white px-5 md:px-10",
				className
			)}
		>
			<NavLink
				to="/"
				className="mr-auto flex items-center gap-2 font-semibold uppercase tracking-wider"
			>
				{/* Decorative images should not have an alt */}
				<img className="aspect-[51/55] w-8 md:w-9" src={logo} alt="" />
				<h1 className="hidden text-red md:inline">
					<span className="text-gray-7">Alabama</span> Legislative Services Agency /{" "}
					<span className="font-light">Administrative Code</span>
				</h1>
				<h1 className="text-[.6rem] text-gray-7 sm:text-xs md:hidden">
					Alabama Legislative <span className="inline-block">Services Agency</span>
					<span className="block text-2xs font-semibold text-red sm:text-sm">
						Administrative Code
					</span>
				</h1>
			</NavLink>
			{isPublic && (
				<>
					<div
						className={twMerge(
							"flex items-center justify-end gap-x-2 whitespace-nowrap text-center font-light text-gray-5 max-[1071px]:hidden lg:text-xs",
							hideMenu && "max-lg:hidden"
						)}
					>
						<span className="max-xl:hidden">{businessName}</span>
						<span className="max-xl:hidden">•</span>
						<span className="max-[1071px]:hidden">{addressLine1}</span>
						<span className="max-[1071px]:hidden">•</span>
						<span className="max-[1071px]:hidden">{addressLine2}</span>
						<span className="max-2xl:hidden">|</span>
						<span className="max-2xl:hidden">{phoneNumber}</span>
					</div>
					{!hideMenu && <CollapsibleMenu />}
				</>
			)}
			{admin && (
				<nav className="flex gap-6 self-stretch font-semibold">
					<NavElement
						label="Admin Code"
						to="/admin/code-manager"
						active={/^\/admin\/code-manager\/.*/}
					/>
					<NavElement label="Website Management" to="/admin/website-manager" />
					<NavElement label="User Management" to="/admin/user-manager" />
				</nav>
			)}
			{agency && (
				<>
					<Combobox
						className={{
							label: "flex w-96 items-center justify-center pb-0 pt-5",
							inner: "w-96",
							menu: "w-full"
						}}
						options={user?.agencies}
						value={activeAgency}
						onChange={setActiveAgency}
						display={(a) => (a ? `${a.controlNumber} – ${a.title}` : "")}
						keyFor={(a, i) => a?.id ?? i}
						by={(lhs, rhs) => lhs?.id === rhs?.id}
						required
						aria-label={
							activeAgency ? `active agency: ${activeAgency.title}` : "select an agency"
						}
					/>
					<NavLink
						className="flex items-center p-2"
						to="/agency/profile"
						aria-label="profile"
					>
						<Icon
							icon={IconUser}
							className="rounded-full border-2 border-gray-5 text-2xl text-gray-7"
						/>
					</NavLink>
				</>
			)}
			{location.pathname !== "/login" && (
				<Button
					className={twMerge(
						"flex h-[1em] w-[1em] items-center text-2xl text-gray-7",
						!user && "rotate-180",
						isPublic && !hideMenu && "hidden lg:block"
					)}
					onClick={
						user
							? async () => {
									try {
										const { data, error } = await logout({})

										if (error || !data) {
											throw error
										}
									} catch (err) {
										console.error(err || new Error("Unknown error"))
									} finally {
										// Delete the cached user and navigate to
										//   the login page every log out,
										//   regardless of whether the BE call
										//   succeeded.
										setUser(undefined)
										setActiveAgency(undefined)
									}
							  }
							: undefined
					}
					to="/login"
					aria-label={user ? "Logout" : "Sign In"}
					title={user ? "Logout" : "Sign In"}
					plain
				>
					<Icon icon={user ? IconLogout : IconLogin} />
				</Button>
			)}
		</header>
	)
}

export default Header
