import React from "react"

import Union from "fast-ts-helpers/Union"
import { Link } from "react-router-dom"
import { twMerge } from "tailwind-merge"
import { ClassNameValue } from "tailwind-merge/dist/lib/tw-join"

import Icon, { TablerIcon } from "../Icon"
import Loader from "../Loader"

export type ButtonProps = {
	className?: ClassNameValue
	children?: React.ReactNode
	onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
	disabled?: boolean
	/** Whether to render a div instead of a button when disabled */
	divWhenDisabled?: boolean
	loading?: boolean
	icon?: TablerIcon
	"data-testid"?: string
	"aria-label"?: string
	"aria-selected"?: boolean
	title?: string
	role?: React.AriaRole
} & Union<
	[
		{
			filled?: true
		},
		{
			outline: true
		},
		{
			subtle: true
		},
		{
			ghost: true
		},
		{
			plain: true
		}
	]
> &
	Union<
		[
			{
				/** The type of button element when not using an anchor. */
				type?: "button" | "submit" | "reset"
				form?: string
			},
			{
				/** Use this for local navigation. Will render an anchor. */
				to?: string | null
				noScrollReset?: boolean
			},
			{
				/** Use this for external navigation. Will render an anchor. */
				href?: string | null
				sameTab?: boolean
				download?: boolean | string
			}
		]
	>

const Button = React.forwardRef<
	HTMLAnchorElement | HTMLButtonElement | HTMLDivElement,
	ButtonProps
>(
	(
		{
			className,
			children,
			loading,
			disabled,
			divWhenDisabled,
			filled,
			outline,
			subtle,
			ghost,
			plain,
			icon,
			onClick,
			type = "button",
			form,
			title,

			to,
			noScrollReset,

			href,
			sameTab,
			download,

			"data-testid": testID,
			"aria-label": ariaLabel,
			"aria-selected": ariaSelected,
			role
		},
		ref
	) => {
		if (![filled, outline, subtle, ghost].includes(true)) {
			filled = true
		}

		return disabled && divWhenDisabled ? (
			<div
				ref={ref as any}
				data-testid={testID}
				title={title}
				aria-label={ariaLabel}
				aria-selected={ariaSelected}
				role={role}
				className={twMerge(
					plain
						? "text-left"
						: [
								"flex items-center justify-center gap-2 rounded-sm px-3.5 py-[0.4375rem] text-xs font-semibold transition-colors",
								icon && "pr-3",
								filled &&
									"bg-almost-blk text-white hover:text-gray-3 disabled:bg-gray-3 disabled:text-gray-1",
								outline &&
									"border-[1.5px] border-almost-blk px-[0.78125rem] py-[0.34375rem] text-almost-blk hover:bg-gray-4 disabled:border-gray-3 disabled:bg-transparent disabled:text-gray-3",
								subtle &&
									"text-almost-blk hover:bg-gray-4 disabled:bg-transparent disabled:text-gray-3",
								ghost &&
									"text-almost-blk hover:text-gray-5/80 disabled:text-almost-blk/50",
								loading && "relative cursor-wait"
						  ],
					className
				)}
			>
				{children}
				{icon && <Icon icon={icon} />}
				{loading && <Loader className="text-[length:inherit]" center />}
			</div>
		) : disabled || loading || (to == null && href == null) ? (
			<button
				ref={ref as any}
				data-testid={testID}
				form={form}
				type={type}
				disabled={disabled || loading}
				onClick={onClick}
				title={title}
				aria-label={ariaLabel}
				aria-selected={ariaSelected}
				role={role}
				className={twMerge(
					plain
						? "text-left"
						: [
								"flex items-center justify-center gap-2 rounded-sm px-3.5 py-[0.4375rem] text-xs font-semibold transition-colors",
								icon && "pr-3",
								filled &&
									"bg-almost-blk text-white hover:text-gray-3 disabled:bg-gray-3 disabled:text-gray-1",
								outline &&
									"border-[1.5px] border-almost-blk px-[0.78125rem] py-[0.34375rem] text-almost-blk hover:bg-gray-4 disabled:border-gray-3 disabled:bg-transparent disabled:text-gray-3",
								subtle &&
									"text-almost-blk hover:bg-gray-4 disabled:bg-transparent disabled:text-gray-3",
								ghost &&
									"text-almost-blk hover:text-gray-5/80 disabled:text-almost-blk/50",
								loading && "relative cursor-wait"
						  ],
					className
				)}
			>
				{children}
				{icon && <Icon icon={icon} />}
				{loading && <Loader className="text-[length:inherit]" center />}
			</button>
		) : to != null ? (
			<Link
				ref={ref as any}
				data-testid={testID}
				to={to}
				onClick={(e) => {
					onClick?.(e)

					if (!noScrollReset) {
						document.getElementById("root")?.scrollTo(0, 0)
					}
				}}
				aria-label={ariaLabel}
				title={title}
				aria-selected={ariaSelected}
				className={twMerge(
					!plain && [
						"flex items-center justify-center gap-2 rounded-sm px-3.5 py-[0.4375rem] text-xs font-semibold transition-colors",
						icon && "pr-3",
						filled && "bg-almost-blk text-white hover:text-gray-3",
						outline &&
							"border-[1.5px] border-almost-blk px-[0.78125rem] py-[0.34375rem] text-almost-blk hover:bg-gray-4",
						subtle && "hover:bg-border-4 text-almost-blk",
						ghost && "text-almost-blk hover:text-gray-5/80"
					],
					className
				)}
				role={role}
			>
				{children}
				{icon && <Icon icon={icon} />}
			</Link>
		) : (
			<a
				ref={ref as any}
				data-testid={testID}
				href={href!}
				target={sameTab ? "_self" : "_blank"}
				download={download}
				rel="noreferrer"
				onClick={onClick}
				title={title}
				aria-label={ariaLabel}
				aria-selected={ariaSelected}
				className={twMerge(
					!plain && [
						"flex items-center justify-center gap-2 rounded-sm px-3.5 py-[0.4375rem] text-xs font-semibold transition-colors",
						icon && "pr-3",
						filled && "bg-almost-blk text-white hover:text-gray-3",
						outline &&
							"border-[1.5px] border-almost-blk px-[0.78125rem] py-[0.34375rem] text-almost-blk hover:bg-gray-4",
						subtle && "hover:bg-border-4 text-almost-blk",
						ghost && "text-almost-blk hover:text-gray-5/80"
					],
					className
				)}
				role={role}
			>
				{children}
				{icon && <Icon icon={icon} />}
			</a>
		)
	}
)

export default Button
