import { NavLink, useLocation } from "react-router-dom"
import { twMerge } from "tailwind-merge"
import { ClassNameValue } from "tailwind-merge/dist/lib/tw-join"

type NavElementProps = {
	label: string
	to: string
	active?: RegExp
	className?: ClassNameValue
	end?: boolean
}

const NavElement: React.FC<NavElementProps> = ({ label, to, className, active, end }) => {
	const { pathname } = useLocation()

	return (
		<NavLink
			className={({ isActive }) =>
				twMerge(
					"flex h-full items-center justify-center px-2 font-semibold tracking-[0.02em] text-gray-7 -outline-offset-1",
					(active ? active.test(pathname) : isActive) &&
						"border-b-4 border-b-red pt-1 text-red [-webkit-text-stroke:0.04em]",
					className
				)
			}
			to={to}
			end={end}
		>
			{label}
		</NavLink>
	)
}

export default NavElement
