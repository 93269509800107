import { useState } from "react"

import useOnMount from "fast-ts-helpers/useOnMount"
import { twJoin } from "tailwind-merge"
import { useQuery } from "urql"

import Loader from "../../../components/Loader"
import Modal from "../../../components/Modal"
import PdfViewer from "../../../components/PdfViewer"
import ResizingFrame from "../../../components/ResizingFrame"
import { graphql } from "../../../graphql"

const filingActivityLogDetailsModalQuery = graphql(`
	query FilingActivityLogDetailsModal($id: ID!) {
		filingActivityLogEntry(id: $id) {
			__typename

			... on WithDiffLogEntry {
				diff
			}

			... on WithFileLogEntry {
				fileUrl
			}

			... on FormChangedLogEntry {
				form {
					name
				}
			}

			... on FilingRejectedLogEntry {
				comments
			}
		}
	}
`)

export type FilingActivityLogDetailsModalProps = {
	id: string
	onDidClose?: () => void
}

const FilingActivityLogDetailsModal = ({
	id,
	onDidClose
}: FilingActivityLogDetailsModalProps) => {
	const [{ data }] = useQuery({
		query: filingActivityLogDetailsModalQuery,
		variables: {
			id
		}
	})

	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [frameLoaded, setFrameLoaded] = useState(false)

	useOnMount(() => setModalIsOpen(true))

	const loadFrame = () => {
		setTimeout(() => setFrameLoaded(true), 100)
	}

	const entry = data?.filingActivityLogEntry

	return (
		<Modal
			isOpen={modalIsOpen}
			onRequestClose={() => setModalIsOpen(false)}
			onDidClose={onDidClose}
			title={
				!entry
					? undefined
					: "diff" in entry
					? "View Diff with Code at Time of Change"
					: entry.__typename === "FilingRejectedLogEntry"
					? "View LSA Comments and File"
					: entry.__typename === "StrikethroughApprovedLogEntry"
					? "View Strikethrough"
					: entry.__typename === "FilingApprovedLogEntry"
					? "View Approved Filing"
					: entry.__typename === "FormSignedLogEntry"
					? `View Signed ${entry.form.name}`
					: entry.__typename === "FormUpdatedLogEntry"
					? `View ${entry.form.name}`
					: "View Filing"
			}
			className={{
				panel: !entry || !frameLoaded || "fileUrl" in entry ? "min-h-[80vh]" : undefined,
				content: entry && "fileUrl" in entry ? "p-0" : undefined
			}}
			size="large"
		>
			{!entry ? (
				<Loader center />
			) : "fileUrl" in entry ? (
				<>
					{entry.__typename === "FilingRejectedLogEntry" && (
						<div className="p-4">
							<h3 className="mb-2 text-lg font-bold">LSA Comments:</h3>
							<p className="mb-1 rounded-sm border-l-4 border-almost-blk bg-gray-3 p-4 pr-7">
								{entry.comments}
							</p>
						</div>
					)}
					<PdfViewer className="w-full flex-1" src={entry.fileUrl} onLoad={loadFrame} />
				</>
			) : "diff" in entry ? (
				// We wrap this in a div to work around a scrolling issue
				//   where the frame would just take the size of the parent
				//   and clip content.
				<div className={twJoin(!frameLoaded && "h-full")}>
					<ResizingFrame content={entry.diff} title="diff with live" onLoad={loadFrame} />
				</div>
			) : (
				"Nothing to show"
			)}
		</Modal>
	)
}

export default FilingActivityLogDetailsModal
