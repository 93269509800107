import React from "react"

import { IconLoader } from "@tabler/icons-react"
import { twMerge } from "tailwind-merge"
import { ClassNameValue } from "tailwind-merge/dist/lib/tw-join"

import Icon from "../Icon"

export type LoaderProps = {
	className?: ClassNameValue
	center?: boolean
}

const Loader: React.FC<LoaderProps> = ({ center, className }) => (
	<div
		className={twMerge(
			"text-2xl text-info",
			center && "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
			className
		)}
		role="progressbar"
	>
		<Icon icon={IconLoader} className="animate-spin" />
	</div>
)

export default Loader
