import React, { useContext, useState } from "react"

import { Dialog, Transition } from "@headlessui/react"
import {
	IconFileAlert,
	IconFilePlus,
	IconFileStar,
	IconFileText,
	IconFileTime,
	IconLogin,
	IconLogout,
	IconMenu2,
	IconSearch,
	IconX
} from "@tabler/icons-react"
import isCallable from "fast-ts-helpers/isCallable"
import { NavLink, NavLinkProps } from "react-router-dom"
import { twMerge } from "tailwind-merge"
import { useMutation } from "urql"

import {
	addressLine1,
	addressLine2,
	businessName,
	phoneNumber
} from "../../../constants/contactInfo"
import { UserContext } from "../../../contexts"
import { graphql } from "../../../graphql"
import console from "../../../utils/console"

import Icon, { TablerIcon } from "../../Icon"

const logoutMutation = graphql(`
	mutation logout {
		logout
	}
`)

const CollapsibleMenu: React.FC = () => {
	const { user, setUser, setActiveAgency } = useContext(UserContext)
	const [, logout] = useMutation(logoutMutation)

	const [shown, setShown] = useState(false)

	const [top, setTop] = useState(0)

	return (
		<>
			<button
				className="relative block size-[1em] cursor-pointer text-2xl text-gray-7 md:m-4 lg:hidden"
				aria-label={`${shown ? "close" : "open"} menu`}
				onClick={(e) => {
					const bounds = e.currentTarget.parentElement?.getBoundingClientRect()

					if (bounds) {
						setTop(bounds.bottom)
					}

					setShown((status) => !status)
				}}
			>
				<Transition
					show={!shown}
					as={React.Fragment}
					enterFrom="transform rotate-180 opacity-0"
					enter="duration-300"
					leave="duration-300"
					leaveTo="transform rotate-180 opacity-0"
				>
					<Icon icon={IconMenu2} className="absolute inset-0" />
				</Transition>
				<Transition
					show={shown}
					as={React.Fragment}
					enterFrom="transform -rotate-180 opacity-0"
					enter="duration-300"
					leave="duration-300"
					leaveTo="transform -rotate-180 opacity-0"
				>
					<Icon icon={IconX} className="absolute inset-0" />
				</Transition>
			</button>
			<Transition appear show={shown} as={React.Fragment}>
				<Dialog onClose={() => setShown(false)} as={React.Fragment}>
					<Transition.Child
						enter="ease-out duration-100"
						enterFrom="opacity-0"
						leave="ease-out duration-150"
						leaveTo="opacity-0"
						as={React.Fragment}
					>
						<Dialog.Panel
							className={twMerge(
								"absolute inset-x-0 z-10 flex h-[80vh] w-full flex-col bg-white pb-8 lg:hidden"
							)}
							style={{ top, height: window.innerHeight - top }}
						>
							<ul
								className="z-20 flex flex-1 flex-col overflow-y-auto px-[10vw] pt-8"
								role="navigation"
							>
								{[
									...menuItems,
									{
										children: user ? "Logout" : "Sign In",
										onClick: user
											? async () => {
													try {
														const { data, error } = await logout({})

														if (error || !data) {
															throw error
														}
													} catch (err) {
														console.error(err || new Error("Unknown error"))
													} finally {
														// Delete the cached user and navigate to
														//   the login page every log out,
														//   regardless of whether the BE call
														//   succeeded.
														setUser(undefined)
														setActiveAgency(undefined)
													}
											  }
											: undefined,
										className: user ? undefined : "[&_svg]:rotate-180",
										to: "/login",
										icon: user ? IconLogout : IconLogin
									}
								].map(
									({ onClick, icon, children, className, ...props }, index, array) => (
										<React.Fragment key={index}>
											<NavLink
												className={(attributes) =>
													twMerge(
														"my-1 rounded border border-transparent bg-white text-2xs font-semibold hover:bg-red-100 hover:text-red",
														attributes.isActive && "bg-red-100 text-red",
														isCallable(className) ? className(attributes) : className
													)
												}
												onClick={(e) => {
													onClick?.(e)
													setShown(false)
												}}
												{...props}
											>
												{(attributes) => (
													<div className="my-6 flex items-center justify-center">
														{icon && <Icon icon={icon} className="m-2 text-lg" />}
														<span className="text-base font-normal">
															{isCallable(children) ? children(attributes) : children}
														</span>
													</div>
												)}
											</NavLink>
											{index === array.length - 1 ? null : (
												<hr className="my-2 h-px text-gray-2" />
											)}
										</React.Fragment>
									)
								)}
							</ul>
							<span className="w-full px-4 pt-4 text-center font-light text-gray-5">
								{businessName} • {addressLine1} • {addressLine2} | {phoneNumber}
							</span>
						</Dialog.Panel>
					</Transition.Child>
				</Dialog>
			</Transition>
		</>
	)
}

const menuItems: (NavLinkProps & { icon: TablerIcon })[] = [
	{
		children: "Administrative Code",
		to: "/administrative-code",
		icon: IconFileText
	},
	{
		children: "Agency Proposed Rules",
		to: "/agency-proposed-rules",
		icon: IconFilePlus
	},
	{
		children: "Administrative Monthly",
		to: "/administrative-monthly",
		icon: IconFileTime
	},
	{
		children: "Emergency Rules",
		to: "/emergency-rules",
		icon: IconFileAlert
	},
	{
		children: "Resources & Announcements",
		to: "/resources-and-announcements",
		icon: IconFileStar
	},
	{
		children: "Search",
		to: "/search",
		icon: IconSearch
	}
]

export default CollapsibleMenu
