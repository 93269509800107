import React, { useCallback, useRef, useState } from "react"

import { Transition } from "@headlessui/react"
import { IconAlertCircle, IconAlertTriangle, IconCheck } from "@tabler/icons-react"
import Union from "fast-ts-helpers/Union"
import useStateRef from "fast-ts-helpers/useStateRef"
import ReactDOM from "react-dom"
import { twMerge } from "tailwind-merge"
import { ClassNameValue } from "tailwind-merge/dist/lib/tw-join"

import Icon from "../Icon"

export type AlertProps = {
	show?: boolean
	className?: ClassNameValue
	message?: React.ReactNode | undefined
	offset?: number | { top?: number; right?: number }
} & Union<[{ info?: true }, { success: true }, { error: true }, { warn: true }]>

const Alert: React.FC<AlertProps> = ({
	show,
	className,
	message,
	error = false,
	warn = false,
	success = false,
	info = !success && !error && !warn,
	offset
}) => {
	if (typeof offset === "number") {
		offset = {
			top: offset
		}
	}

	return (
		<Transition
			as={React.Fragment}
			show={Boolean(show)}
			enter="ease-out duration-300"
			enterFrom="translate-x-full opacity-0"
			leave="ease-out duration-100"
			leaveTo="opacity-0"
		>
			<div
				className={twMerge(
					"absolute right-0 top-0 z-50 mr-4 mt-4 w-fit max-w-[16rem] overflow-hidden rounded-sm border border-l-4 p-4 pr-7",
					info && "border-info bg-info-100 text-info",
					success && "border-success bg-success-100 text-success",
					warn && "border-warning bg-warning-100 text-warning",
					error && "border-error bg-error-100 text-error",
					className
				)}
				style={offset}
				role={error ? "alert" : "status"}
			>
				<div className="flex items-center gap-4">
					{info ? (
						<Icon icon={IconAlertCircle} />
					) : success ? (
						<Icon icon={IconCheck} />
					) : error ? (
						<Icon icon={IconAlertTriangle} />
					) : (
						<Icon icon={IconAlertCircle} />
					)}
					<div className="max-h-20 max-w-[25rem] overflow-hidden text-left">
						{message}
					</div>
				</div>
			</div>
		</Transition>
	)
}

export type AlertContextType = {
	success(message?: React.ReactNode): void
	info(message?: React.ReactNode): void
	warn(message?: React.ReactNode): void
	error(message?: React.ReactNode): void
}

export const AlertContext = React.createContext<AlertContextType>({
	success() {},
	info() {},
	warn() {},
	error() {}
})

export type AlertProviderProps = {
	offset?: number | { top: number; right: number }
	children: React.ReactNode
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children, offset }) => {
	const [showRef, setShow, show] = useStateRef(false)
	const [message, setMessage] = useState<React.ReactNode>()
	const [level, setLevel] = useState<"success" | "info" | "warn" | "error">("success")

	const timeout = useRef(0)
	const whenWeHidCurrentToShowNext = useRef(0)

	const messageWithLevel = useCallback(
		(m: typeof message, l: typeof level) => {
			if (
				showRef.current ||
				performance.now() - whenWeHidCurrentToShowNext.current < 150
			) {
				// A message is already being shown. Animate the hiding of it first, then
				//   show the next one.
				whenWeHidCurrentToShowNext.current = performance.now()

				clearTimeout(timeout.current)
				timeout.current = window.setTimeout(
					() => messageWithLevel(m, l),
					150 - performance.now() + whenWeHidCurrentToShowNext.current
				)

				setShow(false)
			} else {
				setMessage(m)
				setLevel(l)

				// Clear any existing timeout
				clearTimeout(timeout.current)
				timeout.current = window.setTimeout(() => {
					setShow(false)
				}, 2500)

				setShow(true)
			}
		},
		[setShow, showRef]
	)

	const success = useCallback(
		(m: React.ReactNode) => messageWithLevel(m, "success"),
		[messageWithLevel]
	)
	const info = useCallback(
		(m: React.ReactNode) => messageWithLevel(m, "info"),
		[messageWithLevel]
	)
	const warn = useCallback(
		(m: React.ReactNode) => messageWithLevel(m, "warn"),
		[messageWithLevel]
	)
	const error = useCallback(
		(m: React.ReactNode) => messageWithLevel(m, "error"),
		[messageWithLevel]
	)

	return (
		<AlertContext.Provider value={{ success, info, warn, error }}>
			{children}
			{ReactDOM.createPortal(
				<Alert
					show={show}
					message={message}
					offset={offset}
					{...({
						success: level === "success" || undefined,
						info: level === "info" || undefined,
						error: level === "error" || undefined,
						warn: level === "warn" || undefined
					} as any)}
				/>,
				document.body
			)}
		</AlertContext.Provider>
	)
}

export default Alert
