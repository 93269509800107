import Union from "fast-ts-helpers/Union"
import { DateTime } from "luxon"

const formatDate = (
	date: Date | null | undefined,
	{
		day,
		format,
		timeZone = "America/Chicago",
		...options
	}: Union<
		[
			{
				weekday?: "long" | "short" | "narrow" | undefined
				era?: "long" | "short" | "narrow" | undefined
				year?: "numeric" | "2-digit" | undefined
				month?: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined
				day?: "ordinal" | "numeric" | "2-digit" | undefined
				hour?: "numeric" | "2-digit" | undefined
				minute?: "numeric" | "2-digit" | undefined
				second?: "numeric" | "2-digit" | undefined
				timeZoneName?:
					| "short"
					| "long"
					| "shortOffset"
					| "longOffset"
					| "shortGeneric"
					| "longGeneric"
					| undefined
				formatMatcher?: "best fit" | "basic" | undefined
				hour12?: boolean | undefined
				timeZone?: string
			},
			{
				dateStyle: "full"
				timeZone?: string
			},
			{
				format: string
				timeZone?: string
			}
		]
	>
) => {
	if (!date) {
		return ""
	}

	if (format != null) {
		return DateTime.fromJSDate(date, { zone: timeZone }).toFormat(format)
	}

	const dateString = new Intl.DateTimeFormat("en-US", {
		...options,
		...(day && day !== "ordinal" ? { day } : {}),
		timeZone
	}).format(date)

	if (day === "ordinal") {
		const day = DateTime.fromJSDate(date, { zone: "America/Chicago" }).day
		const ordinals = ["th", "st", "nd", "rd"]
		const remainder = day % 100
		const ordinal = ordinals[(remainder - 20) % 10] || ordinals[remainder] || ordinals[0]

		return `${dateString} ${day}${ordinal}`
	}

	return dateString
}

export default formatDate
