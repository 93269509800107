import React from "react"

import Button from "../../components/Button"
import PaperPageTemplate from "../../components/PaperPageTemplate"

export type Error404Props = {
	hideHeader?: boolean
	message?: React.ReactNode
}

const Error404: React.FC<Error404Props> = ({
	hideHeader,
	message = "The page you’re looking was either moved or doesn’t exist… Check the link you came from, or try again later."
}) => {
	return (
		<PaperPageTemplate hideHeader={hideHeader}>
			<h2 className="text-sm font-bold uppercase">
				This Is Not The Page You Are Looking For
			</h2>
			<p className="mx-36 mt-7">{message}</p>
			<Button to="/" className="mt-10">
				Take me home
			</Button>
		</PaperPageTemplate>
	)
}

export default Error404
