import memoize from "fast-ts-helpers/memoize"
import tuple from "fast-ts-helpers/tuple"

/**
 * Searches for options that match a given query string, using n-gram similarity. If the query string is shorter than the n-gram size, it will search for the query string as a substring of the options.
 *
 * @param query - The query string to search for.
 * @param options - An array of tuples, where the first element is the option to search and the second element is the string to compare against.
 * @param n - The size of the n-grams to use for similarity comparison. Defaults to 3.
 * @returns An array of options that match the query string, sorted by similarity.
 */
const ngramSearch = memoize(
	<T extends any>(
		query: string | null | undefined,
		options: [T, string][] | null | undefined,
		grams = 3
	): T[] => {
		if (!options) {
			return []
		}

		if (!query) {
			return options.map((o) => o[0])
		}

		if (query.length < grams) {
			return options
				.filter((o) => o[1].toLocaleLowerCase().includes(query.toLocaleLowerCase()))
				.map((o) => o[0])
		}

		const queryGrams = getGrams(query.toLocaleLowerCase(), grams)

		return options
			.map((option) =>
				tuple(
					option[0],
					similarity(queryGrams, getGrams(option[1].toLocaleLowerCase(), grams))
				)
			)
			.filter(([, similarity]) => similarity > 0)
			.sort(([, a], [, b]) => b - a)
			.map((o) => o[0])
	}
)

function getGrams(str: string, n: number) {
	const grams: string[] = []

	for (let i = 0; i < str.length - n + 1; i++) {
		grams.push(str.slice(i, i + n))
	}

	return grams
}

function similarity(a: string[], b: string[]): number {
	const intersection = new Set(a.filter((x) => b.includes(x)))
	const union = new Set([...a, ...b])

	return intersection.size / union.size
}

export default ngramSearch
