const getNodeText = (node: React.ReactNode): string => {
	if (["string", "number"].includes(typeof node)) {
		return `${node}`
	}

	if (node instanceof Array) {
		return node.map(getNodeText).join("")
	}

	if (node && typeof node === "object" && "props" in node) {
		return getNodeText(node.props.children)
	}

	return ""
}

export default getNodeText
