import React, { useCallback, useEffect, useMemo, useState } from "react"
import { SetStateAction } from "react"

import areEqual from "fast-ts-helpers/areEqual"
import useLocalStorageStateRef from "fast-ts-helpers/useLocalStorageStateRef"
import { CombinedError, useQuery } from "urql"

import { graphql } from "../../graphql"
import { UserQuery } from "../../graphql/graphql"

export const userQuery = graphql(`
	query user {
		user {
			id
			name
			email
			isAdmin
			isActive

			agencies {
				id
				title
				controlNumber
			}
		}
	}
`)

export type User = UserQuery["user"] & {}
export type ActiveAgency = User["agencies"][number]

export type UserContextType = {
	fetching: boolean
	error?: CombinedError
	activeAgency?: ActiveAgency | null | undefined
	setActiveAgency: {
		(agencyID: string): void
		(agencyOrDispatch: SetStateAction<ActiveAgency | null | undefined>): void
	}
	user?: User | null | undefined
	setUser: (user: User | null | undefined) => void
}

export const UserContext = React.createContext<UserContextType>({
	fetching: true,
	setActiveAgency: () => {},
	setUser: () => {}
})

export type UserProviderProps = {
	children?: React.ReactNode
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
	const [{ data, fetching: actualFetching, error: actualError }] = useQuery({
		query: userQuery,
		context: useMemo(() => ({ additionalTypenames: ["Agency"] }), [])
	})

	const [fetching, setFetching] = useState(actualFetching)
	const [error, setError] = useState(actualError)
	const [userRef, setUser] = useLocalStorageStateRef<User | null | undefined>(
		"loggedInUser"
	)
	const [activeAgencyRef, _setActiveAgency] =
		useLocalStorageStateRef<ActiveAgency | null>("selectedAgency")

	useEffect(() => {
		if (!actualFetching) {
			setFetching(false)
			setError(actualError)
			setUser((user) => (!areEqual(user, data?.user) ? data?.user : user))
			_setActiveAgency((activeAgency) => {
				const agencyInArray = userRef.current?.agencies.some((inArray) =>
					areEqual(activeAgency, inArray)
				)

				// We found an agency in the array that equals the one currently active
				if (agencyInArray) {
					return activeAgency
				}

				// We didn't find a matching agency in the array. Select the first one.
				return data?.user.agencies[0]
			})
		}
	}, [data?.user, actualError, actualFetching, _setActiveAgency, setUser, userRef])

	const setActiveAgency = useCallback<UserContextType["setActiveAgency"]>(
		(idOrAgency) => {
			if (idOrAgency && !userRef.current?.agencies) {
				throw new Error("User is not loaded yet")
			}

			if (typeof idOrAgency === "string") {
				idOrAgency = userRef.current?.agencies.find(({ id }) => id === idOrAgency)

				if (!idOrAgency) {
					throw new Error("User does not have access to that agency")
				}
			}

			_setActiveAgency(idOrAgency)
		},
		[_setActiveAgency, userRef]
	)

	return (
		<UserContext.Provider
			value={{
				fetching,
				error,
				activeAgency: activeAgencyRef.current,
				setActiveAgency,
				user: userRef.current,
				setUser
			}}
		>
			{children}
		</UserContext.Provider>
	)
}
