import React, { useContext } from "react"

import {
	IconChevronRight,
	IconFileAlert,
	IconFileCertificate,
	IconFileDiff,
	IconFileDots,
	IconFileLike,
	IconFilePencil,
	IconFilePlus,
	IconFiles,
	IconFileSearch,
	IconFileStar,
	IconFileText,
	IconFileTime,
	IconSearch
} from "@tabler/icons-react"
import Union from "fast-ts-helpers/Union"
import { NavLink } from "react-router-dom"
import { twJoin, twMerge } from "tailwind-merge"
import { ClassNameValue } from "tailwind-merge/dist/lib/tw-join"

import { UserContext } from "../../contexts"
import { FilingStatus } from "../../graphql/graphql"

import Icon, { TablerIcon } from "../Icon"

export type SidebarProps = {
	className?: ClassNameValue
} & Union<[{ public: boolean }, { admin: boolean }, { agency: boolean }]>

const Sidebar: React.FC<SidebarProps> = ({ className, public: isPublic, admin }) => {
	return (
		<aside
			className={twMerge(
				"flex-col items-center border-r border-solid border-gray-2 bg-white p-4",
				className
			)}
		>
			<nav className={twMerge(isPublic && "sticky top-24")}>
				{(isPublic ? publicMenu : admin ? adminMenu : agencyMenu).map(
					({ label, link, icon }, index, array) => (
						<React.Fragment key={label}>
							<NavLink
								className={({ isActive }) =>
									twJoin(
										"group relative flex w-full items-center gap-2 rounded px-2 py-4 tracking-[0.015em] text-almost-blk -outline-offset-1 hover:bg-red/7 hover:text-red xl:pr-10",
										isActive && "bg-red/7 text-red [-webkit-text-stroke:0.05em]"
									)
								}
								to={link}
								onClick={(e) => {
									if (!e.currentTarget.getAttribute("aria-current")) {
										document.getElementById("root")?.scrollTo(0, 0)
									}
								}}
								aria-label={label}
							>
								{({ isActive }) => (
									<>
										<Icon
											icon={icon}
											className={twMerge(
												"h-9 w-9 flex-none rounded p-[3.5px] text-[26px]",
												isPublic ? "mr-3" : "xl:mr-3"
											)}
										/>

										<span
											className={twMerge(
												"mr-auto text-sm",
												!isPublic &&
													"max-xl:absolute max-xl:left-[calc(100%+0.75rem)] max-xl:z-10 max-xl:hidden max-xl:w-max max-xl:rounded-md max-xl:bg-[#F7EFEE] max-xl:p-2.5 max-xl:shadow-sm max-xl:after:absolute max-xl:after:right-full max-xl:after:top-1/2 max-xl:after:h-3 max-xl:after:w-3 max-xl:after:-translate-y-1/2 max-xl:after:translate-x-1/2 max-xl:after:rotate-45 max-xl:after:bg-[#F7EFEE] max-xl:group-hover:inline"
											)}
										>
											{label}
										</span>
										{isActive && (
											<Icon
												icon={IconChevronRight}
												className={twMerge(
													"absolute right-2 top-1/2 h-8 w-8 flex-none -translate-y-1/2",
													!isPublic && "max-xl:hidden"
												)}
											/>
										)}
									</>
								)}
							</NavLink>
							{/* Avoids having an hr at the bottom of the list */}
							{index + 1 !== array.length && <hr className="my-2 w-[95%] text-gray-2" />}
						</React.Fragment>
					)
				)}
			</nav>
		</aside>
	)
}

export type MenuItem = {
	label: string
	link: string
	icon: TablerIcon
}

export type MenuItemWithStatus = MenuItem & { status: FilingStatus | FilingStatus[] }

export const adminMenu: Union<[MenuItem, MenuItemWithStatus]>[] = [
	{
		label: "Proposals Pending Approval",
		status: "proposalPendingApproval",
		link: "/admin/code-manager/filings/proposals-pending-approval",
		icon: IconFileSearch
	},
	{
		label: "Proposals Approved",
		status: "proposalApproved",
		link: "/admin/code-manager/filings/proposals-approved",
		icon: IconFileLike
	},
	{
		label: "Proposals Awaiting Comments",
		status: "proposalAwaitingComments",
		link: "/admin/code-manager/filings/proposals-awaiting-comments",
		icon: IconFileDots
	},
	{
		label: "Certifications Pending Approval",
		status: "certificationPendingApproval",
		link: "/admin/code-manager/filings/certifications-pending-approval",
		icon: IconFileSearch
	},
	{
		label: "Certifications Approved",
		status: ["certificationApproved", "certificationEffectiveNotPublished"],
		link: "/admin/code-manager/filings/certifications-approved",
		icon: IconFileCertificate
	},
	{
		label: "Upcoming Publication",
		link: "/admin/code-manager/publications",
		icon: IconFiles
	},
	{
		label: "Certifications Published Eligible for Rejection",
		status: "certificationPublishedNotEffective",
		link: "/admin/code-manager/filings/certifications-published",
		icon: IconFileTime
	},
	{
		label: "Bulk Code Editor",
		link: "/admin/code-manager/editor",
		icon: IconFilePencil
	}
]

export const agencyMenu: MenuItemWithStatus[] = [
	{
		label: "Drafts",
		status: "draft",
		link: "/agency/filings/drafts",
		icon: IconFilePencil
	},
	{
		label: "Proposals Pending Approval",
		status: "proposalPendingApproval",
		link: "/agency/filings/proposals-pending-approval",
		icon: IconFileSearch
	},
	{
		label: "Proposals Approved",
		status: "proposalApproved",
		link: "/agency/filings/proposals-approved",
		icon: IconFileLike
	},
	{
		label: "Proposals Awaiting Comments",
		status: "proposalAwaitingComments",
		link: "/agency/filings/proposals-awaiting-comments",
		icon: IconFileDots
	},
	{
		label: "Proposal Certification Drafts",
		status: "proposalCertificationDraft",
		link: "/agency/filings/proposal-cert-drafts",
		icon: IconFileDiff
	},
	{
		label: "Certifications Pending Approval",
		status: "certificationPendingApproval",
		link: "/agency/filings/certifications-pending-approval",
		icon: IconFileSearch
	},
	{
		label: "Certifications Approved",
		status: ["certificationApproved", "certificationEffectiveNotPublished"],
		link: "/agency/filings/certifications-approved",
		icon: IconFileCertificate
	},
	{
		label: "Certifications Published Eligible for Withdrawal",
		status: "certificationPublishedNotEffective",
		link: "/agency/filings/certifications-published",
		icon: IconFileTime
	}
]

export const publicMenu: MenuItem[] = [
	{
		label: "Administrative Code",
		link: "/administrative-code",
		icon: IconFileText
	},
	{
		label: "Agency Proposed Rules",
		link: "/agency-proposed-rules",
		icon: IconFilePlus
	},
	{
		label: "Administrative Monthly",
		link: "/administrative-monthly",
		icon: IconFileTime
	},
	{
		label: "Emergency Rules",
		link: "/emergency-rules",
		icon: IconFileAlert
	},
	{
		label: "Resources & Announcements",
		link: "/resources-and-announcements",
		icon: IconFileStar
	},
	{
		label: "Search",
		link: "/search",
		icon: IconSearch
	}
]

export const useMenuItem = (forStatus: string) => {
	const { user } = useContext(UserContext)

	const item = (user?.isAdmin ? adminMenu : agencyMenu).find((menuItem) =>
		new RegExp(`/${forStatus}($|/)`, "i").test(menuItem.link)
	)

	return item?.status ? item : undefined
}

export default Sidebar
