import { lazy, Suspense } from "react"

import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom"

import ContentWrapper from "./components/ContentWrapper"
import ProtectRoute from "./components/ProtectRoute"
import FilingActivityLog from "./pages/FilingActivityLog"

const AdminUserProfile = lazy(async () => import("./pages/UserProfile/AdminUserProfile"))
const AgencyList = lazy(async () => import("./pages/AgencyList"))
const AgencyUserProfile = lazy(
	async () => import("./pages/UserProfile/AgencyUserProfile")
)
const AnnouncementView = lazy(async () => import("./pages/AnnouncementView"))
const DraftEditorSelector = lazy(async () => import("./pages/DraftEditorSelector"))
const EditorSelector = lazy(async () => import("./pages/EditorSelector"))
const Error404 = lazy(async () => import("./pages/Error404"))
const ESigner = lazy(async () => import("./pages/ESigner"))
const FilingChecklist = lazy(async () => import("./pages/FilingChecklist"))
const FilingsViewer = lazy(async () => import("./pages/FilingsViewer"))
const ForgotPassword = lazy(async () => import("./pages/ForgotPassword"))
const LandingPage = lazy(async () => import("./pages/LandingPage"))
const Login = lazy(async () => import("./pages/Login"))
const PasswordResetSent = lazy(async () => import("./pages/PasswordResetSent"))
const PipelineLanding = lazy(async () => import("./pages/PipelineLanding"))
const PublicCodeView = lazy(async () => import("./pages/PublicCodeView"))
const RequestAccount = lazy(async () => import("./pages/RequestAccount"))
const RequestAccountSuccess = lazy(async () => import("./pages/RequestAccountSuccess"))
const ResetPassword = lazy(async () => import("./pages/ResetPassword"))
const ResetPasswordSuccess = lazy(async () => import("./pages/ResetPasswordSuccess"))
const ResourcesAndAnnouncements = lazy(
	async () => import("./pages/ResourcesAndAnnouncements")
)
const Search = lazy(async () => import("./pages/Search"))
const StrikethroughReview = lazy(async () => import("./pages/StrikethroughReview"))
const UpcomingPublication = lazy(async () => import("./pages/UpcomingPublication"))
const ViewPublication = lazy(async () => import("./pages/ViewPublication"))
const WebsiteManagement = lazy(async () => import("./pages/WebsiteManagement"))

function App() {
	return (
		<BrowserRouter>
			<Suspense>
				<Routes>
					<Route
						path="/"
						element={
							<ContentWrapper public noSidebar className="!p-0">
								<LandingPage />
							</ContentWrapper>
						}
					/>

					<Route
						path="/"
						element={
							<ContentWrapper public noSidebar className="justify-center">
								<Outlet />
							</ContentWrapper>
						}
					>
						<Route path="login" element={<Login />} />

						<Route path="request-account" element={<RequestAccount />} />
						<Route path="request-account-success" element={<RequestAccountSuccess />} />

						<Route path="forgot-password" element={<ForgotPassword />} />
						<Route path="password-reset-sent" element={<PasswordResetSent />} />

						<Route path="reset-password/:token" element={<ResetPassword />} />
						<Route path="reset-password-success" element={<ResetPasswordSuccess />} />
					</Route>

					<Route
						path="/"
						element={
							<ContentWrapper public>
								<Outlet />
							</ContentWrapper>
						}
					>
						<Route path="administrative-monthly" element={<Outlet />}>
							<Route path="" element={<FilingsViewer type="aam" />} />
							<Route path="archive" element={<FilingsViewer type="aam" archive />} />
							<Route path=":id" element={<ViewPublication type="aam" />} />
						</Route>
						<Route path="agency-proposed-rules" element={<Outlet />}>
							<Route path="" element={<FilingsViewer type="proposal" />} />
							<Route path="archive" element={<FilingsViewer type="proposal" archive />} />
							<Route path=":id" element={<ViewPublication type="proposal" />} />
						</Route>
						<Route path="emergency-rules" element={<Outlet />}>
							<Route path="" element={<FilingsViewer type="emergency" />} />
							<Route
								path="archive"
								element={<FilingsViewer type="emergency" archive />}
							/>
							<Route path=":id" element={<ViewPublication type="emergency" />} />
						</Route>
						<Route path="administrative-code" element={<Outlet />}>
							<Route path="" element={<AgencyList />} />
							<Route path=":controlNumberOrIdText" element={<PublicCodeView />} />
						</Route>
						<Route path="resources-and-announcements" element={<Outlet />}>
							<Route path="" element={<ResourcesAndAnnouncements />} />
							<Route path="announcement/:title" element={<AnnouncementView />} />
						</Route>
						<Route path="search" element={<Outlet />}>
							<Route path="" element={<Search type="code" />} />
							<Route path="publication" element={<Search type="publication" />} />
						</Route>
					</Route>

					<Route
						path="/agency"
						element={
							<ProtectRoute>
								<Outlet />
							</ProtectRoute>
						}
					>
						<Route
							path="filings/*"
							element={
								<ContentWrapper agency>
									<Outlet />
								</ContentWrapper>
							}
						>
							<Route path=":pluralFilingStatus" element={<PipelineLanding />} />
							<Route path=":pluralFilingStatus/:filingID" element={<FilingChecklist />} />
							<Route
								path=":pluralFilingStatus/:id/editor"
								element={<DraftEditorSelector />}
							/>
							<Route
								path=":pluralFilingStatus/:id/strikethrough"
								element={<StrikethroughReview />}
							/>
							<Route
								path=":pluralFilingStatus/:id/activity-log"
								element={<FilingActivityLog />}
							/>
							<Route path=":pluralFilingStatus/:filingID/sign" element={<ESigner />} />
							<Route
								path=":pluralFilingStatus/:filingID/sign/:formKey"
								element={<ESigner />}
							/>
						</Route>

						<Route
							path="*"
							element={
								<ProtectRoute>
									<ContentWrapper agency noSidebar>
										<Outlet />
									</ContentWrapper>
								</ProtectRoute>
							}
						>
							<Route path="profile" element={<AgencyUserProfile />} />

							<Route path="" element={<Navigate to="/agency/filings/drafts" replace />} />

							{/* 404s for any other paths */}
							<Route path="*" element={<Error404 />} />
						</Route>
					</Route>

					<Route
						path="/admin"
						element={
							<ProtectRoute admin>
								<Outlet />
							</ProtectRoute>
						}
					>
						<Route
							path="code-manager/*"
							element={
								<ProtectRoute admin>
									<ContentWrapper admin>
										<Outlet />
									</ContentWrapper>
								</ProtectRoute>
							}
						>
							<Route path="filings" element={<Outlet />}>
								<Route
									index
									element={
										<Navigate
											to="/admin/code-manager/filings/proposals-pending-approval"
											replace
										/>
									}
								/>
								<Route path=":pluralFilingStatus" element={<Outlet />}>
									<Route index element={<PipelineLanding />} />
									<Route path=":id/activity-log" element={<FilingActivityLog />} />
								</Route>
							</Route>
							<Route path="editor/:controlNumberOrIdText?" element={<EditorSelector />} />
							<Route path="publications" element={<UpcomingPublication />} />
							<Route
								path="*"
								element={
									<Navigate
										to="/admin/code-manager/filings/proposals-pending-approval"
										replace
									/>
								}
							/>
						</Route>

						<Route
							path="user-manager/:user?"
							element={
								<ContentWrapper admin userSidebar>
									<AdminUserProfile />
								</ContentWrapper>
							}
						/>

						<Route
							path="*"
							element={
								<ProtectRoute admin>
									<ContentWrapper admin noSidebar>
										<Outlet />
									</ContentWrapper>
								</ProtectRoute>
							}
						>
							<Route path="website-manager" element={<WebsiteManagement />} />

							<Route path="" element={<Navigate to="/admin/code-manager" replace />} />

							{/* 404s for any other paths */}
							<Route path="*" element={<Error404 />} />
						</Route>
					</Route>

					{/* 404s for any other paths */}
					<Route path="*" element={<Error404 />} />
				</Routes>
			</Suspense>
		</BrowserRouter>
	)
}

export default App
