import React, { useContext } from "react"

import { Navigate, useLocation } from "react-router-dom"

import { UserContext } from "../../contexts"
import NotAuthorized from "../../pages/NotAuthorized"
import ServerError from "../../pages/ServerError"

import Loader from "../Loader"

export type ProtectRouteProps = {
	admin?: boolean
	children?: React.ReactNode
}

const ProtectRoute: React.FC<ProtectRouteProps> = ({ admin, children }) => {
	const { fetching, user, error } = useContext(UserContext)
	const location = useLocation()

	return fetching ? (
		<Loader center />
	) : user?.isActive ? (
		admin && !user.isAdmin ? (
			<NotAuthorized />
		) : (
			(children as React.ReactElement)
		)
	) : error?.networkError ? (
		<ServerError />
	) : (
		<Navigate to={`/login?return=${encodeURIComponent(location.pathname)}`} />
	)
}

export default ProtectRoute
