/* eslint-disable ban-package-import/ban-package-import */
import React from "react"

import { Icon as TablerIcon, TablerIconsProps } from "@tabler/icons-react"
import IdType from "fast-ts-helpers/IdType"
import { twJoin } from "tailwind-merge"
import { ClassNameValue } from "tailwind-merge/dist/lib/tw-join"

export type IconProps = IdType<
	{
		ref?: React.Ref<SVGSVGElement>
		className?: ClassNameValue
	} & Omit<
		TablerIconsProps,
		"width" | "height" | "className" | "color" | "size" | "scale" | "ref"
	> & { icon: TablerIcon }
>

export type { TablerIcon }

const Icon: React.FC<IconProps> = React.forwardRef<SVGSVGElement, Omit<IconProps, "ref">>(
	({ icon: TheIcon, className, ...props }, ref) => {
		return (
			<TheIcon
				ref={ref}
				height="1em"
				width="1em"
				aria-hidden
				className={twJoin("flex-none", className)}
				{...props}
			/>
		)
	}
)

export default Icon
