/* eslint-disable no-restricted-globals */
import isCallable from "fast-ts-helpers/isCallable"
import keys from "fast-ts-helpers/keys"

export default keys(console).reduce(
	(trans, key) => {
		if (isCallable(console[key])) {
			// @ts-expect-error Typescript can't understand that we are assigning it to the same key
			trans[key] = (...args: Parameters<(typeof console)[key]>) => {
				if (env.REACT_APP_ENV === "development") {
					const stackTrace = Error().stack?.split("\n    at ").slice(1)

					// @ts-expect-error Typescript can't understand that we are passing the correct values
					console[key](
						...args,
						// We want to know from where this was called
						`: ${stackTrace?.at(-1)}`
					)
				}
			}
		}

		return trans
	},
	{ console } as unknown as typeof console & { console: typeof console }
)
